







































    import { Mixins, Component, Watch } from 'vue-property-decorator';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiLinkInternal from '@/styleguide/elements/UiLinkInternal.vue';
    import UiLiveLabel from '@/styleguide/elements/UiLiveLabel.vue';
    import CompactChannelItem from '@/components/all-channels/CompactChannelItem.vue';
    import SvgImage from '@/components/shared/SvgImage.vue';
    import ChannelCard from '@/components/channels/ChannelCard.vue';
    import Carousel from '@/components/shared/Carousel.vue';
    import channelsMixin from '@/components/mixins/ChannelsMixin';
    import Icon from '@/components/shared/Icon.vue';
    import { Breakpoints } from '@/enums/brakpoints';
    import { Channel } from '@/api/channels-api';
    import { SelectAdditionalChannelEvent } from '@/common/custom-events/select-channel/select-additional-channel-event';
    import { ShowAllClickedEvent } from '@/common/custom-events/show-all-clicked-event';

    @Component({
        components: {
            UiHeading,
            UiLinkInternal,
            UiLiveLabel,
            CompactChannelItem,
            ChannelCard,
            SvgImage,
            Icon,
            Carousel
        }
    })
    export default class ChannelCarousel extends Mixins(channelsMixin) {
        get showDetailed(): boolean {
            return this.$store.getters['clientWidth'] > Breakpoints.Mobile;
        }

        get carouselChannels(): Channel[] {
            return this.$_additionalChannel
                ? [...this.$_primaryChannels, this.$_localChannel, this.$_additionalChannel]
                : [...this.$_primaryChannels, this.$_localChannel];
        }

        get showSelectAdditionalChannelButton(): boolean {
            return !this.$_additionalChannel;
        }

        @Watch('showDetailed')
        private setPolling(): void {
            if (this.showDetailed) {
                this.$_startPollingPrimarySchedules();
            } else {
                this.$_stopPollingSchedules();
            }
        }

        changeAdditionalChannel(): void {
            window.dispatchEvent(new SelectAdditionalChannelEvent('karusell'));
        }

        trackShowAll(): void {
            window.dispatchEvent(new ShowAllClickedEvent('karusell', 'lyssna_direkt_startsida', 'Lyssna direkt'));
        }

        created(): void {
            this.setPolling();
        }

        beforeDestroy(): void {
            this.$_stopPollingSchedules();
        }
    }
