

















    import { Vue, Component } from 'vue-property-decorator';

    import Flex from '@/components/shared/Flex.vue';
    import Slide from '@/components/animations/Slide.vue';
    import MainMenuToggle from '@/components/main-menu/MainMenuToggle.vue';
    import MainMenuExpandedList from '@/components/main-menu/MainMenuExpandedList.vue';
    import MainMenuSearch from '@/components/main-menu/MainMenuSearch.vue';
    import MainMenuLogo from '@/components/main-menu/MainMenuLogo.vue';
    import MainMenuSearchResult from '@/components/main-menu/MainMenuSearchResult.vue';
    import MainMenuSearchToggleButton from '@/components/main-menu/MainMenuSearchToggleButton.vue';
    import { PageChangedEvent } from '@/common/custom-events/page-changed-event';
    import { SearchOpenEvent } from '@/common/custom-events/search/search-open-event';
    import { MenuOpenEvent } from '@/common/custom-events/menu-open-event';

    @Component({
        components: {
            Flex,
            MainMenuToggle,
            MainMenuExpandedList,
            MainMenuSearch,
            MainMenuLogo,
            MainMenuSearchResult,
            Slide,
            MainMenuSearchToggleButton
        }
    })
    export default class MainMenuNarrow extends Vue {
        showSubMenu: boolean = false;
        showSearch: boolean = false;

        $refs!: Vue['$refs'] & { searchIcon: HTMLElement };

        onSearchOpen(): void {
            this.showSearch = true;
        }

        onSearchClose(): void {
            this.showSearch = false;
        }

        onMenuToggle(): void {
            this.showSubMenu = !this.showSubMenu;
            this.showSearch = false;

            if (this.showSubMenu) {
                window.dispatchEvent(new MenuOpenEvent());
            }
        }

        onSearchButton(): void {
            if (this.showSearch === true) {
                return;
            }

            this.showSubMenu = true;
            this.showSearch = true;
            window.dispatchEvent(new SearchOpenEvent());
        }

        onClose(): void {
            this.showSubMenu = false;
            this.showSearch = false;
            window.scrollTo(0, 0);
        }

        onPageNavigation(): void {
            this.showSubMenu = false;
            this.showSearch = false;
        }

        mounted(): void {
            window.addEventListener(PageChangedEvent.EventName, this.onPageNavigation);
        }

        destroyed(): void {
            window.removeEventListener(PageChangedEvent.EventName, this.onPageNavigation);
        }
    }
