















    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class UiButton extends Vue {
        @Prop(Boolean) readonly inverted: boolean | undefined;
        @Prop(Boolean) readonly fullWidth: boolean | undefined;
        @Prop(Boolean) readonly caps: boolean | undefined;
        @Prop(Boolean) readonly alignTextCenter: boolean | undefined;
        @Prop() readonly type: ButtonType;

        handleClick(): void {
            this.$emit('click');
        }
    }

    export type ButtonType = 'secondary' | undefined;
