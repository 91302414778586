import IDomScript from '@/modules/i-dom-script';
export default class SetStarttime implements IDomScript {
    public constructor(el: HTMLElement) {
        const starttime = window.location.hash.replace('#', '');
        const secondsPerDay = 86400;

        if (Number(starttime) && Number(starttime) > 0 && Number(starttime) <= secondsPerDay) {
            el.setAttribute('data-starttime', starttime);
        }
    }

    public execute(): void {}

    public dispose(): void {}
}
