









    import { Vue, Component, Prop } from 'vue-property-decorator';
    import SvgImage from '@/components/shared/SvgImage.vue';
    import ChannelIcon from '@/components/channels/ChannelIcon.vue';
    import SoundBarsAnimation from '@/components/sound-bars/SoundBarsAnimation.vue';
    import { LegacyPlayerAction } from '@/enums/LegacyPlayerAction';
    import { Channel } from '@/api/channels-api';

    @Component({
        components: {
            SvgImage,
            SoundBarsAnimation,
            ChannelIcon
        }
    })
    export default class CompactChannelItem extends Vue {
        @Prop() channel!: Channel;

        get ariaLabel(): string {
            return `Lyssna på ${this.channel.title}`;
        }

        play(): void {
            this.$store.dispatch(LegacyPlayerAction.PlayOrPause, {
                id: this.channel.id,
                type: 'channel'
            });
        }
    }
