import vueSiteInit from './vue-site-init';
import tabNavigationHighlight from '@/common/tab-navigation-highlight';
import embedMessageListener from '@/common/embed-message-listener';

export default {
    init(): void {
        vueSiteInit.init();
        tabNavigationHighlight.init();
        embedMessageListener.init();
    }
};
