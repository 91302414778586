<template>
    <div class="local-traffic">
        <local-kicker :title="channelName" />
        <component-header title="Trafikläget" :screen-reader-title="channelName" />
        <local-module-list>
            <local-module-list-item v-for="item in trafficItems" :key="item.id">
                <local-traffic-item :item="item" />
            </local-module-list-item>
        </local-module-list>
        <UiLinkInternal title="Till trafiken" :url="url" class="link" />
    </div>
</template>

<script>
    import localModuleList from './local-module-list';
    import localModuleListItem from './local-module-list-item';
    import localTrafficItem from './local-traffic-item';
    import componentHeader from '@/components/shared/component-header';
    import UiLinkInternal from '@/styleguide/elements/UiLinkInternal';
    import localKicker from './local-kicker';

    export default {
        props: {
            channelId: String,
            channelName: String
        },
        components: {
            localModuleList,
            localModuleListItem,
            localTrafficItem,
            componentHeader,
            UiLinkInternal,
            localKicker
        },
        computed: {
            trafficItems() {
                return this.$store.getters['traffic/items'];
            },
            url() {
                return this.$store.getters['traffic/url'];
            }
        },
        watch: {
            channelId(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.$store.dispatch('traffic/load', newVal);
                }
            }
        },
        created() {
            if (this.channelId) {
                this.$store.dispatch('traffic/load', this.channelId);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .local-traffic {
        @include padding-top;
    }

    .link {
        @include border-top;
    }
</style>
