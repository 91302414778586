import { AudioKey } from '@/components/models';

export default {
    resetPlayIcons(): void {
        const playIcons = document.querySelectorAll('[is-playing="true"]');

        if (!playIcons || !playIcons.length) {
            return;
        }

        for (const icon of playIcons) {
            icon.removeAttribute('is-playing');
            icon.classList.remove('play-button--is-playing');
        }
    },

    togglePlayPause(audio: AudioKey): void {
        this.resetPlayIcons();
        const audioButtons = document.querySelectorAll('[data-audio-id]');

        if (!audioButtons || !audioButtons.length) {
            return;
        }

        for (const button of audioButtons) {
            const id = button.getAttribute('data-audio-id');
            const type = button.getAttribute('data-audio-type');

            if (Number(id) === Number(audio.id) && type === audio.type) {
                button.setAttribute('is-playing', 'true');
                button.classList.add('play-button--is-playing');
            }
        }
    }
};
