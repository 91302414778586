
















    import { Vue, Component, Prop } from 'vue-property-decorator';
    import UiSectionHeader from '@/styleguide/elements/UiSectionHeader.vue';
    import Grid from '@/components/shared/Grid.vue';
    import GridItem from '@/components/shared/GridItem.vue';
    import Icon from '@/components/shared/Icon.vue';
    import { ChannelOption } from '@/components/select-channel-modal/models';
    import Modal from '@/components/shared/Modal.vue';

    @Component({
        components: {
            UiSectionHeader,
            Grid,
            Icon,
            GridItem,
            Modal
        }
    })
    export default class SelectChannelModal extends Vue {
        @Prop() readonly channelOptions!: ChannelOption[];
        @Prop() readonly selectedChannelId!: number | null;
        @Prop() readonly isOpen!: boolean;
        @Prop() readonly title!: string;

        isSelected(id: number): boolean {
            return this.selectedChannelId == id;
        }

        changeChannel(channel: ChannelOption) {
            this.$emit('changeChannel', channel);
        }

        close(): void {
            this.$emit('close');
        }
    }
