import spaPageRender from '@/spa-navigation/spa-page-render';
import { SpaAttribute, SpaSelector } from '@/spa-navigation/spa-selectors';

function tryGetAjaxTeaserFlowUrl(doc: Document): string | null {
    const loader = doc.querySelector(SpaSelector.ajaxFlowLoader);
    loader?.removeAttribute('data-require');
    return loader?.getAttribute('data-content-url') ?? null;
}

async function appendAjaxTeaserFlowToDom(incomingDocument: Document): Promise<void> {
    spaPageRender.removeNoscript(incomingDocument);

    const teaserFlow = incomingDocument.querySelector(SpaSelector.TeaserFlow);
    const loader = document.querySelector(SpaSelector.ajaxFlowLoader);
    if (teaserFlow) {
        loader?.parentElement?.replaceChild(teaserFlow, loader);
    }
}

function appendStoredTeaserFlowToDom(storedTeaserFlow: string): void {
    const domTeaserFlow = document.querySelector(SpaSelector.TeaserFlow);

    const storedContentDom = new DOMParser().parseFromString(storedTeaserFlow, 'text/html');
    const storedTeasers = storedContentDom.querySelectorAll(SpaSelector.TeaserItem);
    const storedShowMoreButton = storedContentDom.querySelector(SpaSelector.ShowMoreButton);

    if (!domTeaserFlow) {
        throw new Error('Flow is missing in document');
    }

    const fragment = document.createDocumentFragment();

    storedTeasers.forEach(el => {
        const id = el.getAttribute(SpaAttribute.teaserItemId);
        const selector = `[data-spa-item-id="${id}"]`;
        if (!id || !domTeaserFlow?.querySelector(selector)) {
            fragment.appendChild(el);
        }
    });

    if (storedShowMoreButton) {
        fragment.appendChild(storedShowMoreButton);
    }

    const incomingShowMoreButton = document.querySelector(SpaSelector.ShowMoreButton);
    spaPageRender.removeElement(incomingShowMoreButton);

    domTeaserFlow.appendChild(fragment);
}

export default {
    tryGetAjaxTeaserFlowUrl,
    appendAjaxTeaserFlowToDom,
    appendStoredTeaserFlowToDom
};
