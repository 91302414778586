






























































































    import { Vue, Component, Watch } from 'vue-property-decorator';
    import { SearchResponse, SearchResponseItem } from '@/api/search-api';
    import { SearchResultType } from '@/components/models/SearchResultType';
    import MainMenuSearchItem from '@/components/main-menu/MainMenuSearchItem.vue';
    import MainMenuSearchResultMessage from '@/components/main-menu/MainMenuSearchResultMessage.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiContainer from '@/styleguide/elements/UiContainer.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import UiIconButton from '@/styleguide/elements/UiIconButton.vue';
    import Grid from '@/components/shared/Grid.vue';
    import GridItem from '@/components/shared/GridItem.vue';
    import Icon from '@/components/shared/Icon.vue';
    import Flex from '@/components/shared/Flex.vue';
    import LoadingIcon from '@/components/shared/LoadingIcon.vue';
    import Fade from '@/components/animations/Fade.vue';
    import storage from '@/common/storage';
    import { consentService } from '@/common/consent-service';

    @Component({
        components: {
            MainMenuSearchItem,
            MainMenuSearchResultMessage,
            UiHeading,
            UiContainer,
            UiCaption,
            UiIconButton,
            Grid,
            GridItem,
            Icon,
            Flex,
            Fade,
            LoadingIcon
        }
    })
    export default class MainMenuSearchResult extends Vue {
        searchHistory: SearchResponseItem[] = [];

        get term(): string {
            return this.$store.getters['search/term'];
        }

        get hasTerm(): boolean {
            return this.term.length > 1;
        }

        get channels(): SearchResponse {
            return this.$store.getters['search/channels'];
        }

        get programs(): SearchResponse {
            return this.$store.getters['search/programs'];
        }

        get episodes(): SearchResponse {
            return this.$store.getters['search/episodes'];
        }

        get hasChannelResults(): boolean {
            return this.channels ? !!this.channels.hits.length : false;
        }

        get hasProgramResults(): boolean {
            return this.programs ? !!this.programs.hits.length : false;
        }

        get hasEpisodeResults(): boolean {
            return this.episodes ? !!this.episodes.hits.length : false;
        }

        get hasSearchResult(): boolean {
            return this.hasChannelResults || this.hasProgramResults || this.hasEpisodeResults;
        }

        get isLoading(): boolean {
            return this.$store.getters['search/isLoading'];
        }

        get isError(): boolean {
            return this.$store.getters['search/isError'];
        }

        get hasSearchHistory(): boolean {
            return !!this.searchHistory.length;
        }

        get advancedSearchUrl(): string {
            return `/sok?query=${this.term.toLowerCase()}`;
        }

        get showErrorMessage(): boolean {
            return this.isError;
        }

        get showNoSearchResultMessage(): boolean {
            return this.hasTerm && !this.hasSearchResult && !this.isLoading && !this.isError;
        }

        get searchResultType(): typeof SearchResultType {
            return SearchResultType;
        }

        onClose(): void {
            this.$emit('close');
        }

        onClearSearchHistory(): void {
            this.searchHistory = [];
        }

        onTabOut(event: KeyboardEvent): void {
            if (event.shiftKey) {
                return;
            }
            this.$emit('tabOut');
        }

        @Watch('searchHistory')
        onSearchHistoryUpdate(newVal: SearchResponseItem[]): void {
            if (consentService.isSettingsAllowed()) {
                storage.searchHistory = newVal;
            }
        }

        created(): void {
            this.searchHistory = storage.searchHistory || [];
        }
    }
