import IDomScript from '@/modules/i-dom-script';
import domAjax from '@/common/dom-ajax';
import dateFormatter from '@/common/date-formatter';

export default class DirectModule implements IDomScript {
    private readonly el: HTMLElement;
    private episodeTimer?: number;

    public constructor(el: HTMLElement) {
        this.el = el;
    }

    public execute(): void {
        this.episodeTimer = window.setTimeout(this.refresh.bind(this), this.timeOutMilliseconds);
    }

    public dispose(): void {
        if (this.episodeTimer) {
            window.clearTimeout(this.episodeTimer);
        }
    }

    private get timeOutMilliseconds(): number {
        const minimumTimeout = 15000;
        const episodeEndDateString = this.el.getAttribute('data-episode-end-time');
        if (!episodeEndDateString) {
            return minimumTimeout;
        }

        // Cannot use new Date(episodeEndDateString) directly, does not work in ie and safari
        const episodeEndDate = dateFormatter.parseDate(episodeEndDateString);
        const now = new Date().getTime();
        const compareEndTime = episodeEndDate.getTime() + 5000;
        const timeout = compareEndTime - now;

        return Math.max(timeout, minimumTimeout);
    }

    private refresh() {
        const url = this.el.getAttribute('data-refresh-url');
        if (!url) {
            return;
        }

        domAjax.get({
            url: url,
            success: (html, status) => {
                if (status === 200) {
                    this.el.outerHTML = html;
                }
            }
        });
    }
}
