







    import { Vue, Component } from 'vue-property-decorator';
    import { MainMenuLinkItem } from '@/components/main-menu/models';
    import MenuLink from '@/components/main-menu/MainMenuLink.vue';
    import ChangeLocalButton from '@/components/shared/change-local-button.vue';
    import Flex from '@/components/shared/Flex.vue';

    @Component({
        components: {
            MenuLink,
            ChangeLocalButton,
            Flex
        }
    })
    export default class MainMenuLocalChannelLink extends Vue {
        get localChannel(): MainMenuLinkItem {
            return this.$store.getters['channels/selectedLocalChannel'];
        }

        onLinkClick(linkTitle: string): void {
            this.$emit('click', linkTitle);
        }
    }
