









    import Vue from 'vue';
    import '@sverigesradio/stil/components/stil-grid.js';
    import ProgramCategoryButtons from '@/components/poddar-program/ProgramCategoryButtons.vue';
    import PageHeader from '@/components/poddar-program/PageHeader.vue';
    import ProgramList from '@/components/poddar-program/category-page/components/ProgramList.vue';
    import MostStartedProgramsCarousel from '@/components/poddar-program/category-page/components/MostStartedProgramsCarousel.vue';
    import { programCategoryApi } from '@/api/program-category-api';

    export default Vue.extend({
        components: {
            PageHeader,
            MostStartedProgramsCarousel,
            ProgramList,
            ProgramCategoryButtons
        },
        created() {
            this.getPageTitle();
        },
        computed: {
            categoryId(): number {
                return Number(this.$props['dataCategoryId']);
            }
        },
        data() {
            return {
                pageTitle: ''
            };
        },
        props: {
            'data-category-id': String
        },
        methods: {
            async getPageTitle() {
                const titleResponse = await programCategoryApi.getProgramCategoryById(this.categoryId);
                this.pageTitle = titleResponse.data.name;
            }
        }
    });
