







    import { Mixins, Component } from 'vue-property-decorator';
    import UiOverline from '@/styleguide/elements/UiOverline.vue';
    import Icon from '@/components/shared/Icon.vue';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';

    @Component({
        components: {
            UiOverline,
            Icon
        }
    })
    export default class PlayerSeekToLive extends Mixins(PlaybackMixin) {}
