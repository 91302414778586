function FocusTextSelector(element) {
    this.element = element;
}

FocusTextSelector.prototype.init = function () {
    this.addListener(this.element, 'focus', this.listener);

    return this;
}

FocusTextSelector.prototype.addListener = function (element, eventName, fn) {
    var eventListener = fn.bind(this);

    if (element.addEventListener) {
        element.addEventListener(eventName, eventListener, false);
    } else if (element.attachEvent) {
        element.attachEvent('on' + eventName, eventListener);
    } else {
        element['on' + eventName] = eventListener;
    }
}

FocusTextSelector.prototype.listener = function () {
    this.element.select();

    if (!this.isTextArea(this.element)) return;

    this.element.style.height = 'auto';
    this.element.style.height = this.element.scrollHeight + 'px';
}

FocusTextSelector.prototype.isTextArea = function (element) {
    return element.tagName.toLowerCase() === 'textarea';
}

function init() {
    return new FocusTextSelector(this).init();
}

export default {
    init: init
};