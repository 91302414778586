












    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class UiHeading extends Vue {
        @Prop({
            default: 'h2',
            validator: value => {
                return value.match(/(h1|h2|h3|h4|h5|h6)/);
            }
        })
        readonly tag!: string;

        @Prop({
            validator: value => {
                return value.match(/(h1|h2|h3|h4|h5|h6)/);
            }
        })
        readonly appearance!: string;

        @Prop(Boolean) readonly capitalize: boolean | undefined;
        @Prop(Boolean) readonly regular: boolean | undefined;
        @Prop(Boolean) readonly white?: boolean;
        @Prop(Boolean) readonly uppercase: boolean | undefined;
    }
