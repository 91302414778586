




    import { Vue, Component } from 'vue-property-decorator';

    @Component({
        components: {
            SoundBar
        }
    })
    export default class SoundBar extends Vue {
        $refs!: Vue['$refs'] & { soundBar: HTMLElement };

        mounted(): void {
            this.$refs.soundBar.style.animationDuration = `${this.getRandomAnimationDuration()}ms`;
            this.$refs.soundBar.style.animationDelay = `${this.getRandomAnimationDelay()}ms`;
        }

        getRandomAnimationDelay(): number {
            const maxDelay = 100;
            const minDelay = 0;

            return Math.random() * (maxDelay - minDelay) + minDelay;
        }

        getRandomAnimationDuration(): number {
            const maxDuration = 4000;
            const minDuration = 2000;

            return Math.random() * (maxDuration - minDuration) + minDuration;
        }
    }
