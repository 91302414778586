import { render, staticRenderFns } from "./UiIconButton.vue?vue&type=template&id=702de32a&scoped=true&"
import script from "./UiIconButton.vue?vue&type=script&lang=ts&"
export * from "./UiIconButton.vue?vue&type=script&lang=ts&"
import style0 from "./UiIconButton.vue?vue&type=style&index=0&id=702de32a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "702de32a",
  null
  
)

/* custom blocks */
import block0 from "./UiIconButton.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

export default component.exports