










    import '@sverigesradio/stil/components/stil-text.js';
    import Vue from 'vue';
    export default Vue.extend({
        props: {
            characterList: {
                type: [],
                required: true
            }
        },
        methods: {
            onclick(character: string): void {
                const el = document.getElementById(`character${character}`);
                el?.scrollIntoView();
            },
            sectionAriaLabel(character: string): string {
                return `Gå till sektion ${character}`;
            }
        }
    });
