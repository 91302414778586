export enum SpaSelector {
    TeaserFlow = '[data-spa-role="flow"]',
    TeaserItem = '[data-spa-role="item"]',
    ShowMoreButton = '[data-spa-role="show-more-button"]',
    contentWrapper = '[data-spa-role="wrapper"]',
    ajaxFlowLoader = '[data-spa-role="ajax-flow-loader"]',
    noscript = '.noscript',
    metadata = '#ga-metadata',
    gtmMetadata = '#gtm-metadata'
}

export enum SpaAttribute {
    UnitId = 'data-unitid',
    teaserItemId = 'data-spa-item-id'
}

export const BODY_CLASSES = ['js-on', 'js-ready'];

const metaSelectors = [
    'link[rel="canonical"]',
    'meta',
    'title',
    'link[type="application/json+oembed"]',
    'link[rel="apple-touch-icon"]',
    'link[rel="alternate"]'
];

export const META_SELECTOR = metaSelectors.join(', ');
