




    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component({})
    export default class Backdrop extends Vue {
        @Prop(Boolean) readonly transparent!: boolean;
        @Prop(Boolean) readonly light: boolean | undefined;

        onClick() {
            this.$emit('close');
        }
    }
