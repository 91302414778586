import { Vue, Component } from 'vue-property-decorator';

@Component
export default class LocalChannelMixin extends Vue {
    get localChannelId(): number {
        return this.$store.getters['channels/localChannelId'];
    }

    $store: any;
}
