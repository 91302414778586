import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { pageMetadataService } from '@/common/page-metadata-service';

function track(): void {
    trackGoogleTagManager();
}

function trackGoogleTagManager(): void {
    const pageViewMetadata = pageMetadataService.pageView;

    if (pageViewMetadata) {
        googleTagManager.trackPage(pageViewMetadata);
    }
}

export default {
    track
};
