import linkEvaluator from '@/common/link-evaluator';
import IDomScript from '@/modules/i-dom-script';
import { getMetadata } from '@/common/tracking-metadata';
import { GoToPageEvent } from '@/common/custom-events/go-to-page-event';
import { ModuleClickedEvent } from '@/common/custom-events/module-clicked-event';
import { ShowAllClickedEvent } from '@/common/custom-events/show-all-clicked-event';

enum TrackingTypes {
    ShowAll = 'visa_alla',
    ModuleClick = 'klick_objekt'
}

export default class ClickableContent implements IDomScript {
    private el: HTMLElement;

    public constructor(el: HTMLElement) {
        this.el = el;
    }

    private handleClick(event: Event, isCtrlKeyPressed: boolean): void {
        const target = event.target as HTMLElement;

        if (!target) {
            return;
        }

        if (this.isInNoClickable(target)) {
            return;
        }

        const link = this.getTargetLink();
        if (!link) {
            return;
        }

        const url = link.getAttribute('href');
        if (!url) {
            return;
        }

        const tagName = target.tagName.toLowerCase();
        if (tagName === 'a' || tagName === 'button') {
            return;
        }

        const parentLink = target.closest('a');
        if (parentLink) {
            return;
        }

        const parentButton = target.closest('button');
        if (parentButton) {
            return;
        }

        event.stopPropagation();
        this.trackCustomEvent(link);
        this.navigateTo(url, isCtrlKeyPressed);
    }

    private isInNoClickable(target: HTMLElement): boolean {
        const noClickableParent = target.closest('.no-clickable-content');
        if (!noClickableParent) {
            return false;
        }

        const clickableParent = noClickableParent.closest('[data-require="modules/clickable-content"]');
        if (!clickableParent) {
            return false;
        }

        return true;
    }

    private getTargetLink(): Element | null {
        const link = this.el.querySelector('[data-clickable-content="link"]');
        if (link) {
            return link;
        }

        return null;
    }

    private trackCustomEvent(link: Element): void {
        const category = link.getAttribute('data-stat-category');
        const action = link.getAttribute('data-stat-action');
        const label = link.getAttribute('data-stat-label');
        const moduleHeading = link.getAttribute('data-stat-module-heading');

        const metadata = getMetadata(link);

        if (!category || !action || !label) {
            return;
        }

        switch (action) {
            case TrackingTypes.ModuleClick: {
                window.dispatchEvent(
                    new ModuleClickedEvent(
                        category,
                        label,
                        metadata.programName ?? '',
                        metadata.programId ?? '',
                        metadata.category ?? '',
                        ''
                    )
                );
                break;
            }
            case TrackingTypes.ShowAll: {
                window.dispatchEvent(new ShowAllClickedEvent(category, label, moduleHeading ?? ''));
                break;
            }
        }

        if (action === 'klick_objekt') {
            window.dispatchEvent(
                new ModuleClickedEvent(
                    category,
                    label,
                    metadata.programName ?? '',
                    metadata.programId ?? '',
                    metadata.category ?? '',
                    ''
                )
            );
        }
    }

    private navigateTo(url: string, isCtrlKeyDown: boolean): void {
        if (linkEvaluator.isExternal(url) || isCtrlKeyDown) {
            window.open(url);
        } else if (window.history) {
            window.dispatchEvent(new GoToPageEvent(url));
        } else {
            window.location.href = url;
        }
    }

    private clickHandler(event: MouseEvent): void {
        this.handleClick(event, event.ctrlKey);
    }

    private keyHandler(event: KeyboardEvent): void {
        if (event.which === 13 || event.keyCode === 13) {
            this.handleClick(event, event.ctrlKey);
        }
    }

    public execute(): void {
        if (!this.el) {
            return;
        }

        this.el.addEventListener('click', this.clickHandler.bind(this), false);
        this.el.addEventListener('keydown', this.keyHandler.bind(this), false);
    }

    public dispose(): void {
        if (this.el) {
            this.el.removeEventListener('click', this.clickHandler.bind(this), false);
            this.el.removeEventListener('keydown', this.keyHandler.bind(this), false);
        }
    }
}
