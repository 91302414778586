






    import { ChangeLocalChannelEvent } from '@/common/custom-events/select-channel/change-local-channel-event';
    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class ChangeLocalButton extends Vue {
        @Prop(Boolean) readonly showLabel!: boolean;
        @Prop(Boolean) readonly shouldRedirect!: boolean;
        @Prop({ default: 'byt p4' }) readonly trackingAction!: string;
        @Prop(String) readonly trackingLabel!: string;

        showLocalChannelModal(): void {
            window.dispatchEvent(new ChangeLocalChannelEvent('senaste-nyheterna', this.shouldRedirect));
        }
    }
