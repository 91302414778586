












    import Vue from 'vue';
    import '@sverigesradio/stil/components/stil-image.js';
    import '@sverigesradio/stil/components/stil-text.js';
    import '@sverigesradio/stil/components/stil-grid.js';

    export default Vue.extend({
        props: {
            title: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: true
            },
            url: {
                type: String,
                required: true
            },
            imgSrc: {
                type: String,
                required: false
            }
        }
    });
