

































    import Vue from 'vue';
    import Carousel from '@/components/shared/Carousel.vue';
    import SectionHeader from '@/components/shared/SectionHeader.vue';
    import ProgramCard from '@/components/poddar-program/ProgramCard.vue';
    import { Program, programsApi } from '@/api/programs-api';
    import ScrollTracking from '@/modules/scroll-tracking';
    import { SwipedEvent } from '@/common/custom-events/swiped-event';
    import { SwipedToLastEvent } from '@/common/custom-events/swiped-to-last-event';
    import { ModuleClickedEvent } from '@/common/custom-events/module-clicked-event';

    export default Vue.extend({
        components: {
            Carousel,
            SectionHeader,
            ProgramCard
        },
        props: {
            categoryId: {
                type: Number,
                required: true
            }
        },
        async mounted() {
            await this.getMostStartedPrograms();
        },

        computed: {
            showMostStartedCarousel(): boolean {
                return this.mostStartedPrograms.length > 0;
            }
        },

        methods: {
            trackSwipeStart(): void {
                window.dispatchEvent(new SwipedEvent('utvalda_poddar'));
            },
            trackSwipeEnd(): void {
                window.dispatchEvent(new SwipedToLastEvent('utvalda_poddar'));
            },
            onCardClicked(program: Program): void {
                window.dispatchEvent(
                    new ModuleClickedEvent(
                        'karusell',
                        'mest_lyssnat_kategori',
                        program.title,
                        program.id.toString(),
                        program.category,
                        'mest_lyssnat_kategori'
                    )
                );
            },
            initiateScrollTracking(): void {
                this.scrollTrackingInstance = new ScrollTracking(this.$refs.section as HTMLElement);
                this.scrollTrackingInstance.execute();
            },

            async getMostStartedPrograms() {
                const mostStartedProgramsResponse = await programsApi.getMostStartedProgramCollection(this.categoryId, 10);
                this.mostStartedPrograms = mostStartedProgramsResponse.data.items;
            }
        },
        data() {
            return {
                mostStartedPrograms: [] as Program[],
                scrollTrackingInstance: {} as ScrollTracking
            };
        },
        watch: {
            showMostStartedCarousel(newValue: boolean) {
                if (newValue) {
                    this.initiateScrollTracking();
                }
            }
        },
        beforeDestroy() {
            this.scrollTrackingInstance?.dispose();
        }
    });
