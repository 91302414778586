








    import UiContainer from '@/styleguide/elements/UiContainer.vue';
    import { Vue, Component } from 'vue-property-decorator';

    @Component({
        components: {
            UiContainer
        }
    })
    export default class MainMenuSearchResultMessage extends Vue {}
