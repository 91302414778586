











    import { Mixins, Component } from 'vue-property-decorator';
    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import Flex from '@/components/shared/Flex.vue';
    import PlayerProgressMixin from '@/components/mixins/PlayerProgressMixin';

    @Component({
        components: {
            Flex
        }
    })
    export default class QueueProgress extends Mixins(MetadataMixin, PlayerProgressMixin) {
        private get progressPercentage(): number {
            if (this.isLiveMode) {
                return this.catchupProgressPercentage;
            }
            return (this.streamPosition / this.duration) * 100;
        }

        private get queueLength(): number {
            return this.queue.length;
        }

        private get widthCss(): string {
            return `width: ${this.progressPercentage}%`;
        }
    }
