import { AriaLabels } from '@/enums/AriaLabels';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AriaLabelsMixin extends Vue {
    ariaLabels = AriaLabels;

    protected playAriaLabel(programTitle: string | undefined): string {
        if (!programTitle) {
            return '';
        }
        return `${AriaLabels.PlayPartial}${programTitle}`;
    }
}
