






    import { Mixins, Component } from 'vue-property-decorator';
    import breakpoint from '@/common/breakpoint';
    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import { PlayerTabIndexes } from '@/enums/tab-index';
    import { EmitEvents } from '@/enums/EmitEvents';

    @Component
    export default class PlayerBarMeta extends Mixins(MetadataMixin) {
        get metadataComponentTagType(): string {
            return breakpoint.isNarrow() ? 'button' : 'header';
        }

        get metadataAriaLabel(): string | null {
            return breakpoint.isNarrow()
                ? `${this.currentAudio.programName} - ${this.currentAudio.episodeTitle}. Fäll ut spelaren.`
                : null;
        }

        get metadataTabIndex(): number | null {
            return breakpoint.isNarrow() ? PlayerTabIndexes.playerBar : null;
        }

        onExpand(): void {
            this.$emit(EmitEvents.Expand);
        }
    }
