









    import { SlideshowEvent, SlideshowEventPayload } from '@/common/custom-events/slideshow-event';
    import UiOpenSlideshow from '@/styleguide/icons/UiOpenSlideshow.vue';
    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component({
        components: {
            UiOpenSlideshow
        }
    })
    export default class SlideshowButton extends Vue {
        @Prop(String) private readonly dataCount: string | undefined;
        @Prop(String) private readonly dataPublicationId: string | undefined;
        @Prop(String) private readonly dataType: string | undefined;
        @Prop(String) private readonly dataLanguageDirection: string | undefined;
        @Prop(String) private readonly dataJson: string | undefined;

        private get count(): string | undefined {
            return this.dataCount;
        }

        private show(): void {
            const languageDirection = this.dataLanguageDirection || 'ltr';
            const hasId = this.dataPublicationId && this.dataType;
            let payload: SlideshowEventPayload | null = null;

            if (this.dataJson) {
                payload = {
                    jsonData: this.dataJson,
                    languageDirection: languageDirection
                };
            } else if (hasId) {
                payload = {
                    publicationId: this.dataPublicationId,
                    type: this.dataType,
                    languageDirection: languageDirection
                };
            }

            if (payload) {
                window.dispatchEvent(new SlideshowEvent(payload));
            }
        }
    }
