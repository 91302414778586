













    import { Vue, Component, Prop } from 'vue-property-decorator';
    import Icon from '@/components/shared/Icon.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';

    @Component({
        model: {
            prop: 'currentValue',
            event: 'input'
        },
        components: {
            Icon,
            UiCaption
        }
    })
    export default class RadioButton extends Vue {
        @Prop() currentValue!: string | boolean;
        @Prop() value!: string | boolean;
        @Prop({ default: false }) disabled!: boolean;

        get id(): string {
            return `radio__${this.value}`;
        }

        get isSelected(): boolean {
            return this.currentValue === this.value;
        }

        onChange(): void {
            this.$emit('input', this.value);
        }
    }
