const srDomainRegex = /^(https?:)?\/\/(www.)?(sr\.se|sverigesradio\.se)\/?/;

function isExternal(url: string): boolean {
    if (isEmpty(url)) {
        return false;
    }
    return hasHttp(url) && !isSrLink(url);
}

function isSrLink(url: string): boolean {
    return srDomainRegex.test(url);
}

function hasHttp(url: string): boolean {
    return /^https?:\/\//.test(url);
}

// mailto, tel, skype etc
function isApplicationLink(url: string): boolean {
    return !!url.match(/^[a-z]+:[^/].+$/);
}

function isFileDownload(url: string): boolean {
    return (
        url.endsWith('.pdf') ||
        url.endsWith('.doc') ||
        url.endsWith('.docx') ||
        url.endsWith('.xls') ||
        url.endsWith('.ppt') ||
        url.endsWith('.pptx')
    );
}

function isPlaylist(url: string): boolean {
    return url.endsWith('.m3u') || url.endsWith('.pls');
}

function isImage(url: string): boolean {
    return url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') || url.endsWith('.gif');
}

function isTopsyUrl(url: string): boolean {
    return url.includes('/topsy/');
}

function isAnchor(url: string): boolean {
    return startsWith(url, '#');
}

function isEmpty(url: string): boolean {
    return url === undefined || url === null;
}

function startsWith(str: string, searchFor: string): boolean {
    return str.indexOf(searchFor) === 0;
}

function containsAnchor(url: string): boolean {
    return url.indexOf('#') > 0;
}

function removeFragmentIdentifier(url: string): string {
    return url.split('#')[0];
}

function getFragmentIdentifier(url: string): string {
    if (containsAnchor(url)) {
        return '#' + url.split('#')[1];
    } else {
        return '';
    }
}

function replaceSrLinkToRelative(url: string): string {
    return url.replace(srDomainRegex, '/');
}

function isSameRelativeUrl(left: string, right: string): boolean {
    const leftRelativeUrl = getRelativeUrl(left);
    const rightRelativeUrl = getRelativeUrl(right);
    return leftRelativeUrl.toLowerCase() === rightRelativeUrl.toLowerCase();
}

function getRelativeUrl(strUrl: string): string {
    const url = new URL(strUrl, window.location.origin);
    return url.pathname + url.search;
}

export default {
    isExternal,
    isSrLink,
    isApplicationLink,
    isFileDownload,
    isPlaylist,
    isImage,
    isEmpty,
    isAnchor,
    containsAnchor,
    removeFragmentIdentifier,
    getFragmentIdentifier,
    replaceSrLinkToRelative,
    isSameRelativeUrl,
    isTopsyUrl
};
