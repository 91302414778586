import httpClient from '@/common/http-client';
import { CardListModel } from './models';

export const LatestNewsApi = {
    async getEpisodes(params: GetLatestNewsEpisodesRequest): Promise<CardListModel | null> {
        try {
            const url = '/backend-for-frontend/latest-news-episodes';
            const response = await httpClient.get<CardListModel>(url, params);
            return response.data;
        } catch (err) {
            return null;
        }
    }
};

export interface GetLatestNewsEpisodesRequest {
    localChannelId: number;
}
