interface DeepLinkerOptions {
    onReturn?: () => void;
    onFallback?: () => void;
    onIgnored?: () => void;
}

class DeepLinker {
    private hasFocus: boolean = true;
    private didHide: boolean = false;
    private options: DeepLinkerOptions;

    constructor(options: DeepLinkerOptions) {
        if (!options) {
            throw new Error('no options');
        }
        this.options = options;

        // Bind methods to maintain `this` context
        this.onBlur = this.onBlur.bind(this);
        this.onVisibilityChange = this.onVisibilityChange.bind(this);
        this.onFocus = this.onFocus.bind(this);

        this.bindEvents('add');
    }

    private onBlur(): void {
        this.hasFocus = false;
    }

    private onVisibilityChange(e: Event): void {
        const visibilityState = (e.target as Document).visibilityState;
        if (visibilityState === 'hidden') {
            this.didHide = true;
        }
    }

    private onFocus(): void {
        if (this.didHide) {
            if (this.options.onReturn) {
                this.options.onReturn();
            }
            this.didHide = false; // reset
        } else {
            // Ignore duplicate focus event when returning from native app on
            // iOS Safari 13.3+
            if (!this.hasFocus && this.options.onFallback) {
                // Wait for app switch transition to fully complete - only then is
                // 'visibilitychange' fired
                setTimeout(() => {
                    // If browser was not hidden, the deep link failed
                    if (!this.didHide && this.options.onFallback) {
                        this.options.onFallback();
                    }
                }, 1000);
            }
        }

        this.hasFocus = true;
    }

    private bindEvents(mode: 'add' | 'remove'): void {
        type EventConf = [Window | Document, string, (e: Event) => void];
        const events: EventConf[] = [
            [window, 'blur', this.onBlur],
            [document, 'visibilitychange', this.onVisibilityChange],
            [window, 'focus', this.onFocus]
        ];

        events.forEach(([target, event, handler]) => {
            (target as Window & typeof globalThis)[`${mode}EventListener`](event, handler);
        });
    }

    public destroy(): void {
        this.bindEvents('remove');
    }

    public openURL(url: string): void {
        // It can take a while for the dialog to appear
        const dialogTimeout = 1000;

        setTimeout(() => {
            if (this.hasFocus && this.options.onIgnored) {
                this.options.onIgnored();
            }
        }, dialogTimeout);

        window.location.href = url;
    }
}

export default DeepLinker;

/** 

usage:

var url = 'fb://profile/240995729348595';
var badURL = 'lksadjgajsdhfaskd://slkdfs';

var linker = new DeepLinker({
  onIgnored: function() {
    console.log('browser failed to respond to the deep link');
  },
  onFallback: function() {
    console.log('dialog hidden or user returned to tab');
  },
  onReturn: function() {
    console.log('user returned to the page from the native app');
  },
});

linker.openURL(url);

**/
