















    import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
    import api, { WeatherItem } from '@/api/weather-api';
    import LocalModuleList from './local-module-list.vue';
    import LocalModuleListItem from './local-module-list-item.vue';
    import LocalWeatherItem from './local-weather-item.vue';
    import ComponentHeader from '@/components/shared/component-header.vue';
    import LinkExternal from '@/components/shared/link-external.vue';
    import LocalKicker from './local-kicker.vue';
    import DateFormatter from '@/common/date-formatter';

    @Component({
        components: {
            LocalModuleList,
            LocalModuleListItem,
            LocalWeatherItem,
            ComponentHeader,
            LinkExternal,
            LocalKicker
        }
    })
    export default class LocalWeather extends Vue {
        @Prop(String) private readonly channelId!: string;
        @Prop(String) private readonly channelName!: string;

        get timeTitle(): string {
            return this.time ? `Kl. ${this.time}` : '';
        }

        get timeAbbr(): string {
            return this.time ? `Klockan ${this.time}` : '';
        }

        get ready(): boolean {
            return !!this.weatherItems?.length;
        }

        @Watch('channelId')
        async onChannelIdChanged(newVal: string, oldVal: string): Promise<void> {
            if (newVal !== oldVal) {
                await this.load();
            }
        }

        private weatherItems: WeatherItem[] = [];

        private get time(): string | null {
            if (!this.weatherItems.length) {
                return null;
            }

            const weatherItem = this.weatherItems[0];

            if (weatherItem.time) {
                return DateFormatter.shortTime(weatherItem.time);
            }

            return null;
        }

        private async load(): Promise<void> {
            try {
                const result = await api.getLocalWeather({
                    localId: this.channelId
                });

                this.weatherItems = result.data;

                if (this.weatherItems?.length !== 0) {
                    this.$emit('ready');
                }
            } catch {
                this.weatherItems = [];
            }
        }

        async created(): Promise<void> {
            if (this.channelId) {
                await this.load();
            }
        }
    }
