import storage from 'common/storage';

var localChannel = {
    set: function (id, callback) {
        storage.localChannel = id;
        callback(id);
    },
    get: function (callback) {
        const localChannelId = storage.localChannel || 701;
        callback(localChannel.getChannel(localChannelId));
    },
    getChannel: function (id) {
        return {
            id: id,
            name: localChannel.all[id].name,
            url: localChannel.all[id].url
        };
    },
    all: {
        200: { name: 'Jämtland', url: '/jamtland' },
        201: { name: 'Kalmar', url: '/kalmar' },
        202: { name: 'Sörmland', url: '/sormland' },
        203: { name: 'Jönköping', url: '/jonkoping' },
        204: { name: 'Värmland', url: '/varmland' },
        205: { name: 'Gotland', url: '/gotland' },
        206: { name: 'Sjuhärad', url: '/sjuharad' },
        207: { name: 'Malmöhus', url: '/malmo' },
        208: { name: 'Skaraborg', url: '/skaraborg' },
        209: { name: 'Norrbotten', url: '/norrbotten' },
        210: { name: 'Gävleborg', url: '/gavleborg' },
        211: { name: 'Kristianstad', url: '/kristianstad' },
        212: { name: 'Göteborg', url: '/goteborg' },
        213: { name: 'Blekinge', url: '/blekinge' },
        214: { name: 'Kronoberg', url: '/kronoberg' },
        215: { name: 'Västerbotten', url: '/vasterbotten' },
        216: { name: 'Västernorrland', url: '/vasternorrland' },
        217: { name: 'Västmanland', url: '/vastmanland' },
        218: { name: 'Uppland', url: '/uppland' },
        219: { name: 'Väst', url: '/vast' },
        220: { name: 'Halland', url: '/halland' },
        221: { name: 'Örebro', url: '/orebro' },
        222: { name: 'Östergötland', url: '/ostergotland' },
        223: { name: 'Dalarna', url: '/dalarna' },
        5283: { name: 'Södertälje', url: '/sodertalje' },
        701: { name: 'Stockholm', url: '/stockholm' }
    }
};

export default {
    localChannel: localChannel
};
