import domAjax from '@/common/dom-ajax';

export default {
    getTrafficMessages(params: TrafficMessagesRequest, success: (data: string) => void) {
        const url = '/traffic/trafficmessagelist';
        return domAjax.get({ url, params, success });
    },
    getTrafficChannelHeader(params: TrafficChannelHeaderRequest, success: (data: string) => void) {
        const url = '/master/unitheader';
        return domAjax.get({ url, params, success });
    },
    getTrafficChannelRightColumn(params: TrafficChannelRightColumnRequest, success: (data: string) => void) {
        const url = '/master/rightcolumntvaspaltare';
        return domAjax.get({ url, params, success });
    }
};

type TrafficMessagesRequest = {
    latitude?: number | null;
    longitude?: number | null;
    unitId?: number | null;
};

type TrafficChannelHeaderRequest = {
    programId: number;
};

type TrafficChannelRightColumnRequest = {
    programId: number;
};
