
























    import { Mixins, Component, Prop } from 'vue-property-decorator';
    import EpisodeCard from '@/components/shared/EpisodeCard.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiShowMoreButton from '@/styleguide/elements/UiShowMoreButton.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import Breakpoint from '@/common/breakpoint';
    import SvgMixin from '@/components/mixins/SvgMixin';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import { AudioKey } from '@/components/models/';
    import type Vue from 'vue';
    import { CardModel } from '@/api/models';
    import { GoToPageEvent } from '@/common/custom-events/go-to-page-event';

    @Component({
        components: {
            EpisodeCard,
            UiHeading,
            UiShowMoreButton,
            UiCaption
        }
    })
    export default class EpisodeCardList extends Mixins(SvgMixin, PlaybackMixin) {
        $refs!: Vue['$refs'] & { list: HTMLElement; listItem: HTMLElement[] };

        @Prop() readonly list!: CardModel[];
        @Prop() readonly title?: string;
        @Prop() readonly playlistText!: string;
        @Prop() readonly url!: string;
        @Prop() readonly columns?: number;

        private isExpanded = false;

        protected get columnsDesktopCssClass(): string {
            return this.columns ? `list--desktop list--desktop-${this.columns}` : 'list--desktop';
        }

        get cardsList(): CardModel[] {
            if (this.isExpanded || Breakpoint.isNarrow()) {
                return this.list;
            }
            return this.list.slice(0, this.sliceValue);
        }

        get sliceValue(): number {
            return this.columns || 3;
        }

        get listLength(): number {
            return this.list.length;
        }

        get showMoreButton(): boolean {
            return this.listLength > 2 && !this.isExpanded && Breakpoint.isWide();
        }

        get playAllList(): AudioKey[] | undefined {
            if (!this.list) {
                return [];
            }

            return this.list.map(item => {
                return { id: item.audioId, type: item.audioType };
            });
        }

        onPlayAll(): void {
            this.$emit('playAllStart', { title: this.title });
        }

        onPlayAudio(index?: number): void {
            if (!this.playAllList) {
                return;
            }

            this.playQueue(this.playAllList, index, this.playlistText);
        }

        handleTouchMove(): void {
            this.$emit('touchScroll', { title: this.title });
            this.$refs.list.removeEventListener('touchmove', this.handleTouchMove);
        }

        onLinkClick(item: CardModel): void {
            this.$emit('linkClick', { title: this.title });
            window.dispatchEvent(new GoToPageEvent(item.url));
        }

        async onShowMoreClick(): Promise<void> {
            this.$emit('showMoreClick', { title: this.title });
            this.isExpanded = true;

            await this.$nextTick();

            const nextFocusableElement = this.$refs.listItem[this.sliceValue];
            nextFocusableElement.querySelector('button')?.focus();
        }

        mounted(): void {
            this.$refs.list.addEventListener('touchmove', this.handleTouchMove, {
                passive: true
            });
        }
    }
