







    import { Vue, Component, Prop } from 'vue-property-decorator';
    import SvgImage from '../../components/shared/SvgImage.vue';

    @Component({
        components: {
            SvgImage
        }
    })
    export default class UiShowMoreButton extends Vue {
        @Prop({ default: 'Visa fler' }) readonly label!: string;

        handleClick(): void {
            this.$emit('click');
        }
    }
