import httpClient from '@/common/http-client';
import { CardListModel } from './models';
import { NewsPlaylistModel } from '@/api/news-playlist-api';

export const CuratedApi = {
    async getCuratedCollections(): Promise<CuratedCollectionList> {
        const url = '/backend-for-frontend/curated-collections';
        const response = await httpClient.get<CuratedCollectionList>(url);

        return response.data;
    },
    async getCuratedPlaylists(): Promise<NewsPlaylistModel[]> {
        const url = '/backend-for-frontend/curated-playlists';
        const response = await httpClient.get<NewsPlaylistModel[]>(url);

        return response.data;
    },
    async getCuratedPlaylist(id: string): Promise<NewsPlaylistModel> {
        const url = `/backend-for-frontend/curated-playlist/${id}`;
        const response = await httpClient.get<NewsPlaylistModel>(url);

        return response.data;
    }
};

export interface CuratedCollectionList {
    items: CardListModel[];
}
