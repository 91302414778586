









    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { MainMenuLinkItem } from '@/components/main-menu/models';
    import MenuLink from '@/components/main-menu/MainMenuLink.vue';
    import { Channel } from '@/api/channels-api';

    @Component({
        components: {
            MenuLink
        }
    })
    export default class ChosenChannelLink extends Vue {
        @Prop() channel?: Channel | null;
        @Prop() buttonText!: string;

        get linkItem(): MainMenuLinkItem | null {
            if (!this.channel) {
                return null;
            }

            return { title: this.channel.title, url: this.channel.url };
        }

        onLinkClick(linkTitle: string): void {
            this.$emit('link-click', linkTitle);
        }

        onButtonClick(): void {
            this.$emit('change');
        }
    }
