import httpClient, { IHttpResponse } from '@/common/http-client';

export const programCategoryApi = {
    getProgramCategories(): Promise<IHttpResponse<ProgramCategoryListResult>> {
        const url = '/backend-for-frontend/program-categories';
        return httpClient.get<ProgramCategoryListResult>(url);
    },

    getProgramCategoryById(categoryId: number): Promise<IHttpResponse<ProgramCategory>> {
        const url = `/backend-for-frontend/program-category/${categoryId}`;
        return httpClient.get<ProgramCategory>(url);
    }
};

export type ProgramCategory = {
    id: number;
    name: string;
    url: string;
    iconType: string;
};

type ProgramCategoryListResult = {
    title: string;
    items: ProgramCategory[];
};
