






    import { Vue, Component, Prop } from 'vue-property-decorator';
    import SvgImage from '@/components/shared/SvgImage.vue';

    @Component({
        components: {
            SvgImage
        }
    })
    export default class Icon extends Vue {
        @Prop({ default: '' }) readonly name!: string;
        @Prop(Boolean) readonly small: boolean | undefined;
        @Prop(Boolean) readonly large: boolean | undefined;
        @Prop(String) readonly fill!: 'white' | 'inherit' | undefined;
    }
