import spaNavigationApi from '@/api/spa-navigation-api';
import IDomScript from '@/modules/i-dom-script';
import historyHandler from '@/spa-navigation/history-handler';
import { SpaSelector } from '@/spa-navigation/spa-selectors';
import spaPageRender from '@/spa-navigation/spa-page-render';
import { DomUpdatedEvent } from '@/common/custom-events/dom-updated-event';
import { TrackingMetadata } from '@/tracking/track-types';
import { getMetadata } from '@/common/tracking-metadata';
import { ShowMoreClickedEvent } from '@/common/custom-events/show-more-clicked-event';

export default class ShowMoreFlow implements IDomScript {
    private el: HTMLElement;
    private fetchMoreUrl: string | null;
    private isLoading = false;
    private metadata: TrackingMetadata;
    private readonly callback: Function | undefined;

    public constructor(el: HTMLElement, callback?: Function) {
        this.el = el;
        this.fetchMoreUrl = el.getAttribute('data-url');
        this.callback = callback;
        this.metadata = getMetadata(this.el);
    }

    public async fetchAndAppendItems(): Promise<void> {
        if (this.isLoading || !this.fetchMoreUrl) {
            return;
        }

        this.isLoading = true;

        try {
            this.changeButtonText();
            const flow: HTMLElement | null = document.querySelector(SpaSelector.TeaserFlow);
            flow?.removeAttribute('data-show-more-loaded');

            const res = await spaNavigationApi.getHtml(this.fetchMoreUrl);

            if (res.status == 204) {
                this.el.parentElement?.removeChild(this.el);
                return;
            }

            const incomingDocument = res.html;

            spaPageRender.removeNoscript(incomingDocument);

            const fragment = document.createDocumentFragment();
            const incomingShowMoreButton = incomingDocument.querySelector(SpaSelector.ShowMoreButton);

            incomingDocument.querySelectorAll(SpaSelector.TeaserItem).forEach(el => fragment.appendChild(el));

            if (incomingShowMoreButton) {
                fragment.appendChild(incomingShowMoreButton);
            }

            this.remove();

            flow?.appendChild(fragment);

            if (flow) {
                flow.dataset.showMoreLoaded = 'true';
            }

            const teaserFlowToStoreInHistory = flow?.innerHTML ?? '';

            historyHandler.saveTeaserFlow(teaserFlowToStoreInHistory);

            if (this.callback) {
                this.callback();
            }

            this.isLoading = false;

            window.dispatchEvent(new DomUpdatedEvent());
        } catch (error) {
            this.isLoading = false;
            throw error;
        } finally {
            window.dispatchEvent(new ShowMoreClickedEvent(this.metadata));
        }
    }

    private changeButtonText(): void {
        const button = this.el.querySelector('button');

        if (button) {
            button.innerText = 'Hämtar...';
        }
    }

    private remove(): void {
        this.dispose();
        spaPageRender.removeElement(this.el);
    }

    public execute(): void {
        this.el.addEventListener('click', this.fetchAndAppendItems.bind(this));
    }

    public dispose(): void {
        this.el.removeEventListener('click', this.fetchAndAppendItems.bind(this));
    }
}
