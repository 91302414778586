


















































    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { ScheduleItem, Track } from '@/api/schedule-api';
    import playButton from '@/components/shared/play-button.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import LiveProgressbar from '@/components/shared/LiveProgressbar.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import changeLocalButton from '@/components/shared/change-local-button.vue';
    import TimeAbbr from '@/components/shared/TimeAbbr.vue';
    import Thumbnail from '@/components/shared/Thumbnail.vue';
    import ChannelTrack from '@/components/all-channels/ChannelTrack.vue';
    import { LegacyPlayerAction } from '@/enums/LegacyPlayerAction';
    import ChannelIcon from '@/components/channels/ChannelIcon.vue';

    @Component({
        components: {
            ChannelIcon,
            playButton,
            UiHeading,
            LiveProgressbar,
            UiCaption,
            changeLocalButton,
            ChannelTrack,
            TimeAbbr,
            Thumbnail
        }
    })
    export default class ChannelItem extends Vue {
        @Prop() item!: any;
        @Prop() schedule!: ScheduleItem[];
        @Prop() track!: Track;
        @Prop(Boolean) showChangeLocal!: boolean;
        @Prop(String) trackingLocalLabel: string | undefined;

        get timeEpoch(): number {
            return this.$store.getters['timeEpoch'];
        }

        get startTimeEpoch(): number {
            return this.firstScheduleItem ? new Date(this.firstScheduleItem.start).getTime() : 0;
        }

        get endTimeEpoch(): number {
            return this.firstScheduleItem ? new Date(this.firstScheduleItem.end).getTime() : 0;
        }

        get showTrack(): boolean {
            return !!this.track;
        }

        get scheduleItems(): ScheduleItem[] {
            return this.schedule && this.schedule.length ? this.schedule : [];
        }

        get cssThemeClass(): string {
            return `th-${this.item.theme}`;
        }

        get firstScheduleItem(): ScheduleItem | undefined {
            return this.scheduleItems.find(item => {
                const start = new Date(item.start).getTime();
                const end = new Date(item.end).getTime();
                return start <= this.timeEpoch && end > this.timeEpoch;
            });
        }

        get nextScheduleItem(): ScheduleItem | undefined {
            return this.scheduleItems.find(item => {
                const start = new Date(item.start).getTime();
                return start > this.timeEpoch;
            });
        }

        play(): void {
            this.$store.dispatch(LegacyPlayerAction.PlayOrPause, { id: this.item.id, type: this.item.audioType });
        }
    }
