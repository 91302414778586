export default {
    parseSeconds(timeString: string | undefined): number {
        if (!timeString) {
            return 0;
        }

        let hours = 0;
        let minutes = 0;
        let seconds = 0;

        const timeSegments = timeString.split(':');

        switch (timeSegments.length) {
            case 1:
                seconds = parse(timeSegments[0]);
                break;
            case 2:
                minutes = parse(timeSegments[0]);
                seconds = parse(timeSegments[1]);
                break;
            case 3:
                hours = parse(timeSegments[0]);
                minutes = parse(timeSegments[1]);
                seconds = parse(timeSegments[2]);
                break;
        }

        return hours * 3600 + minutes * 60 + seconds;
    },
    validateTimeString(time: string): boolean {
        if (!time) {
            return false;
        }

        return !!time.match(/^[0-9:]+$/);
    }
};

function parse(str: string): number {
    const parsed = parseInt(str);
    if (isNaN(parsed)) {
        return 0;
    }

    return parsed;
}
