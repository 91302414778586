






    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class UiBodyText extends Vue {
        @Prop(Boolean) readonly gray: boolean | undefined;
    }
