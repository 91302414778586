
















    import { Vue, Component } from 'vue-property-decorator';
    import CompactCard from '@/components/shared/CompactCard.vue';
    import UiSectionHeader from '@/styleguide/elements/UiSectionHeader.vue';
    import UiSection from '@/styleguide/elements/UiSection.vue';
    import api, { WeatherReport } from '@/api/weather-api';

    @Component({
        components: {
            CompactCard,
            UiSectionHeader,
            UiSection
        }
    })
    export default class LatestWeatherReport extends Vue {
        latestWeatherReport: WeatherReport | null = null;

        created() {
            api.getLatestWeatherReport().then(result => {
                this.latestWeatherReport = result.data;
            });
        }
    }
