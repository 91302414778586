import IDomScript from '@/modules/i-dom-script';
import { DomUpdatedEvent } from '@/common/custom-events/dom-updated-event';

export default class MergeDays implements IDomScript {
    private el: HTMLElement | null;

    private readonly lastDay: string | undefined;

    public constructor(el: HTMLElement) {
        this.el = el;
        this.lastDay = this.getLastDay(el);
    }

    private getLastDay(el: HTMLElement): string | undefined {
        const dateElements = el.parentElement?.querySelectorAll('[data-date]');
        const elCount = dateElements?.length;

        if (!elCount || !dateElements) {
            return;
        }

        const lastElement = dateElements[elCount - 1];
        return lastElement.getAttribute('data-date') || undefined;
    }

    private showMoreCallback(): void {
        if (!this.lastDay) {
            return;
        }

        const duplicates = this.el?.parentElement?.querySelectorAll(`[data-date="${this.lastDay}"]`);

        if (duplicates && duplicates.length === 2) {
            this.moveItems(duplicates);
        }
    }

    private moveItems(duplicates: NodeListOf<Element>): void {
        const currentContainer = duplicates[0];
        const nextContainer = duplicates[1];

        const newItemsToMove = nextContainer?.querySelectorAll('li');
        const list = currentContainer?.querySelector('ul');

        if (newItemsToMove && list) {
            newItemsToMove.forEach(item => list.appendChild(item));
            nextContainer.remove();
        }
    }

    public execute(): void {
        window.addEventListener(DomUpdatedEvent.EventName, () => this.showMoreCallback());
    }

    public dispose(): void {
        window.removeEventListener(DomUpdatedEvent.EventName, () => this.showMoreCallback());
        this.el = null;
    }
}
