import IDomScript from '@/modules/i-dom-script';

export default class RemoveElement implements IDomScript {
    private el: HTMLElement | null;

    public static remove(el: HTMLElement) {
        // el.remove not supported in ie
        if (el.parentNode) {
            el.parentNode.removeChild(el);
        }
    }

    constructor(el: HTMLElement) {
        this.el = el;
    }

    public execute(): void {
        if (this.el) {
            RemoveElement.remove(this.el);
        }
    }

    public dispose(): void {
        this.el = null;
    }
}
