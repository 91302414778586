













    import { Component, Vue, Prop } from 'vue-property-decorator';
    import EpisodeCardList from '@/components/shared/EpisodeCardList.vue';
    import NewsCardList from '@/components/shared/NewsCardList.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import { RecommendationsApi } from '@/api/recommendations-api';
    import { CardListModel } from '@/api/models';
    import { SwipedEvent } from '@/common/custom-events/swiped-event';
    import { ShowAllClickedEvent } from '@/common/custom-events/show-all-clicked-event';
    import { ModuleClickedEvent } from '@/common/custom-events/module-clicked-event';

    @Component({
        components: {
            EpisodeCardList,
            NewsCardList,
            UiHeading
        }
    })
    export default class EpisodeRecommendations extends Vue {
        model: CardListModel | null = null;
        gaTitle: string = 'Hitta liknande lyssning';

        @Prop()
        readonly dataEpisodeId!: string;

        get ready(): boolean {
            return (this.model?.items?.length || 0) > 0;
        }

        onTouchScroll(): void {
            window.dispatchEvent(new SwipedEvent(this.gaTitle));
        }

        onLinkClick(): void {
            window.dispatchEvent(new ModuleClickedEvent('navigation', this.gaTitle, '', '', '', ''));
        }

        onShowMoreClick(): void {
            window.dispatchEvent(new ShowAllClickedEvent('karusell', this.gaTitle, ''));
        }

        async created(): Promise<void> {
            this.model = await RecommendationsApi.forEpisode(this.dataEpisodeId);
        }
    }
