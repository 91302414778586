








    import { Vue, Component, Prop } from 'vue-property-decorator';

    type MenuSrollBackground = 'gray' | 'white';

    @Component
    export default class MenuScroll extends Vue {
        @Prop() background?: MenuSrollBackground;
    }
