import IDomScript from '@/modules/i-dom-script';
import { SpaNavigationStartedEvent } from '@/common/custom-events/spa-navigation-started-event';
import { ModuleVisibleEvent } from '@/common/custom-events/module-visible-event';

export default class ScrollTracking implements IDomScript {
    private readonly el: HTMLElement;
    private readonly category: string;
    private readonly action: string | null;
    private readonly label?: string;
    private readonly visiblePercentage: number;
    private readonly scrollEventListener: EventListener;
    private readonly navigationEventListener: EventListener;
    private readonly moduleType: string;

    public constructor(el: HTMLElement) {
        this.el = el;
        const categoryStr = this.el.getAttribute('data-category');
        this.category = categoryStr || 'scroll';
        this.action = this.el.getAttribute('data-action');
        this.label = this.el.getAttribute('data-label') ?? undefined;
        this.moduleType = this.el.getAttribute('data-module-type') || 'modul';

        const visiblePercentageStr = this.el.getAttribute('data-visible-percentage');
        this.visiblePercentage = visiblePercentageStr !== null ? Number(visiblePercentageStr) : 100;

        this.scrollEventListener = (): void => {
            this.trackIfInViewport();
        };

        this.navigationEventListener = (): void => {
            this.removeEventHandler();
        };
    }

    private getThresholdPosition(): number {
        const boundingRect = this.el.getBoundingClientRect();
        const topPosition = boundingRect.top;
        const height = boundingRect.height;
        return topPosition + Math.round((height * this.visiblePercentage) / 100);
    }

    private trackIfInViewport(): void {
        if (this.getThresholdPosition() < window.innerHeight) {
            if (this.action) {
                const moduleName = this.label || this.moduleType;
                window.dispatchEvent(new ModuleVisibleEvent(this.moduleType, moduleName, this.visiblePercentage));
            }

            this.removeEventHandler();
        }
    }

    public execute(): void {
        if (this.action) {
            window.addEventListener('scroll', this.scrollEventListener, { passive: true });
            this.trackIfInViewport();
        }

        window.addEventListener(SpaNavigationStartedEvent.EventName, this.navigationEventListener);
    }

    private removeEventHandler(): void {
        window.removeEventListener('scroll', this.scrollEventListener);
        window.removeEventListener(SpaNavigationStartedEvent.EventName, this.navigationEventListener);
    }

    public dispose(): void {
        this.removeEventHandler();
    }
}
