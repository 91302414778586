export default class TabNavigationHighlight {
    public static init(): TabNavigationHighlight {
        if (!TabNavigationHighlight.instance) {
            TabNavigationHighlight.instance = new TabNavigationHighlight();
        }
        return TabNavigationHighlight.instance;
    }

    private static instance: TabNavigationHighlight;

    private constructor() {
        const tabDownClass = 'tab-navigation';
        const tabKeyCode = 'Tab';
        window.addEventListener('mousedown', () => {
            if (document.body.classList.contains(tabDownClass)) {
                document.body.classList.remove(tabDownClass);
            }
        });
        window.addEventListener('keydown', e => {
            if (e.key === tabKeyCode && !document.body.classList.contains(tabDownClass)) {
                document.body.classList.add(tabDownClass);
            }
        });
    }
}
