import domAjax from '@/common/dom-ajax';
import IDomScript from '@/modules/i-dom-script';

export default class AjaxLoader implements IDomScript {
    private readonly el: HTMLElement;

    public constructor(el: HTMLElement) {
        this.el = el;
    }

    private load(): void {
        const target = this.el.querySelector('.ajax-target') as HTMLElement;

        if (!target) {
            return;
        }

        const url = this.el.getAttribute('data-content-url');

        if (!url) {
            return;
        }

        if (target.classList.contains('ajax-loaded')) {
            return;
        }

        domAjax.get({
            url,
            success: data => {
                target.innerHTML = data;
                target.classList.add('ajax-loaded');
            }
        });
    }

    public execute(): void {
        this.load();
    }

    public dispose(): void {}
}
