<template>
    <UiCaption gray> Musik: {{ trackName }} </UiCaption>
</template>

<script>
    import UiCaption from '@/styleguide/elements/UiCaption';

    export default {
        components: {
            UiCaption
        },
        props: {
            trackName: String
        }
    };
</script>
