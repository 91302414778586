import IDomScript from '@/modules/i-dom-script';
import breakpoint from '@/common/breakpoint';
import trafficApi, { TrafficMessage } from '@/api/traffic-api';
import trafficDomApi from '@/api/traffic-dom-api';
import { DomUpdatedEvent } from '@/common/custom-events/dom-updated-event';

export default class Traffic implements IDomScript {
    private readonly el: HTMLElement;
    private requestedMessageId?: number | null;
    private userPosition: UserPosition | null = null;
    private defaultAreaId: number = 2859;

    public constructor(el: HTMLElement) {
        this.el = el;
    }

    public execute(): void {
        this.el.classList.add('ajax-loading-indicator');
        this.el.classList.add('hide-while-loading');

        const trafficUnit = this.getTrafficUnitFromRequest();
        this.requestedMessageId = this.getTrafficMessageIdFromRequest();

        if (this.requestedMessageId) {
            this.initForRequestedId();
        } else if (trafficUnit) {
            this.initForUnit(trafficUnit);
        } else {
            this.initForCurrentPosition();
        }
    }

    public dispose(): void {
        this.removeClickHandlers();
    }

    private headerClickHandler(event: Event): void {
        const target = event.currentTarget;
        if (!target) {
            return;
        }
        const parent = (target as HTMLElement).parentElement;
        if (!parent) {
            return;
        }
        parent.classList.toggle('expanded');
    }

    private addClickHandlers(): void {
        const trafficHeaders = this.getHeaders();
        if (!trafficHeaders) {
            return;
        }

        let i: number;
        for (i = 0; i < trafficHeaders.length; i++) {
            trafficHeaders[i].addEventListener('click', this.headerClickHandler.bind(this));
        }
    }

    private removeClickHandlers() {
        const trafficHeaders = this.getHeaders();
        if (!trafficHeaders) {
            return;
        }

        let i: number;
        for (i = 0; i < trafficHeaders.length; i++) {
            trafficHeaders[i].removeEventListener('click', this.headerClickHandler.bind(this));
        }
    }

    private getHeaders() {
        return this.el.querySelectorAll('.traffic-header');
    }

    private initForUnit(area: TrafficUnit) {
        if (this.userPosition) {
            this.getMessagesForList(this.userPosition.latitude, this.userPosition.longitude, area.unitId);
        } else {
            this.getMessagesForList(null, null, area.unitId);
        }
        this.reloadRightColumn(area.unitId);
    }

    private getTrafficUnitFromRequest(): TrafficUnit | null {
        const unitId = this.el.getAttribute('data-selected-id');
        if (unitId) {
            return {
                unitId: parseInt(unitId),
                name: this.el.getAttribute('data-selected-name') || ''
            };
        }
        return null;
    }

    private getTrafficMessageIdFromRequest(): number | null {
        const m = window.location.search.match(/id=(\d+)/);

        if (m != null && m.length === 2) {
            return parseInt(m[1]);
        }

        return null;
    }

    private initForRequestedId(): void {
        const urlParams = {
            format: 'json',
            pagination: 'false'
        };

        trafficApi.getTrafficMessages(urlParams).then(response => {
            const messages = response.data.messages;
            if (!messages) {
                return;
            }
            const requestedMessage = this.getRequestedMessage(messages);
            if (requestedMessage) {
                this.getMessagesForList(requestedMessage.latitude, requestedMessage.longitude);
                this.setAreaByPosition({
                    latitude: requestedMessage.latitude,
                    longitude: requestedMessage.longitude
                });
            } else {
                this.initForCurrentPosition();
            }
        });
    }

    private initForCurrentPosition() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                this.onGetCurrentPositionSucceeded.bind(this),
                this.setDefaultPosition.bind(this),
                {
                    enableHighAccuracy: true,
                    timeout: 8000,
                    maximumAge: 0
                }
            );
        } else {
            this.setDefaultPosition();
        }
    }

    private setDefaultPosition() {
        const position = { latitude: 59.32893, longitude: 18.06491 };
        this.setAreaByPosition(position);
        this.getMessagesForList(null, null, this.defaultAreaId);
    }

    private onGetCurrentPositionSucceeded(position: GeolocationPosition): void {
        this.userPosition = position.coords;

        if (this.userPosition) {
            this.setAreaByPosition(this.userPosition);
            this.getMessagesForList(this.userPosition.latitude, this.userPosition.longitude);
        }
    }

    private getRequestedMessage(messages: TrafficMessage[]) {
        if (messages && this.requestedMessageId) {
            for (let i = 0; i < messages.length; i++) {
                if (this.requestedMessageId === messages[i].id) {
                    return messages[i];
                }
            }
        }
        return null;
    }

    private getMessagesForList(latitude?: number | null, longitude?: number | null, unitId?: number | null) {
        const listContainer = this.el.querySelector('.list');
        if (!listContainer) {
            return;
        }

        trafficDomApi.getTrafficMessages(
            {
                latitude,
                longitude,
                unitId
            },
            html => {
                this.removeClickHandlers();
                listContainer.innerHTML = html;

                this.el.classList.remove('ajax-loading-indicator');
                this.el.classList.remove('hide-while-loading');

                this.addClickHandlers();
                this.collapseCategoriesInMobile();
                this.highlightMessage();
            }
        );
    }

    private collapseCategoriesInMobile(): void {
        const shouldCollapse = breakpoint.isNarrow() || this.requestedMessageId;
        if (!shouldCollapse) {
            return;
        }

        const categories = this.el.querySelectorAll('.traffic-category');

        if (!categories) {
            return;
        }

        let i: number;
        for (i = 0; i < categories.length; i++) {
            categories[i].classList.remove('expanded');
        }
    }

    private highlightMessage() {
        const selectedElement = document.getElementById(`traffic-message-${this.requestedMessageId}`);
        if (!selectedElement) {
            return;
        }

        selectedElement.classList.add('traffic-message--active');

        const category = selectedElement.closest('.traffic-category');
        if (!category) {
            return;
        }

        category.classList.add('expanded');
    }

    private setAreaByPosition(position: UserPosition) {
        trafficApi
            .getTrafficAreas({
                latitude: position.latitude,
                longitude: position.longitude,
                format: 'json'
            })
            .then(response => {
                const area = response.data.area;
                if (!area) {
                    return;
                }
                this.reloadHeader(area.trafficdepartmentunitid);
                this.reloadRightColumn(area.trafficdepartmentunitid);
            });
    }

    private reloadHeader(programId: number): void {
        const unitHeader = document.getElementById('secondary-menu');
        if (!unitHeader) {
            return;
        }

        trafficDomApi.getTrafficChannelHeader(
            {
                programId
            },
            html => {
                unitHeader.outerHTML = html;
                window.dispatchEvent(new DomUpdatedEvent());
            }
        );
    }

    private reloadRightColumn(programId: number): void {
        const rightColumn = document.getElementById('traffic-right-column');
        if (!rightColumn) {
            return;
        }

        trafficDomApi.getTrafficChannelRightColumn(
            {
                programId
            },
            html => {
                rightColumn.innerHTML = html;
            }
        );
    }
}

type TrafficUnit = {
    unitId: number;
    name: string;
};

interface UserPosition {
    latitude: number;
    longitude: number;
}
