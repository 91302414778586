








    import { Vue, Component, Prop } from 'vue-property-decorator';
    import MenuScroll from '@/components/shared/MenuScroll.vue';
    import { SecondaryMenuLinkItem } from '@/components/secondary-menu/models';
    import SecondaryMenuItem from '@/components/secondary-menu/SecondaryMenuItem.vue';

    interface ProgramMenuModel {
        links: SecondaryMenuLinkItem[];
    }

    @Component({
        components: {
            MenuScroll,
            SecondaryMenuItem
        }
    })
    export default class ProgramSecondaryMenu extends Vue {
        @Prop() private readonly dataJson!: string;

        private get model(): ProgramMenuModel {
            return JSON.parse(this.dataJson);
        }

        get menuLinks(): SecondaryMenuLinkItem[] {
            return this.model.links.map(l => ({
                ...l,
                isActive: this.isActive(l.url)
            }));
        }

        private isActive(url: string): boolean {
            return window.document.location.href.toLowerCase().includes(url.toLowerCase());
        }
    }
