import IDomScript from '@/modules/i-dom-script';
import { ShowAllClickedEvent } from '../common/custom-events/show-all-clicked-event';

export default class ShowAllTracking implements IDomScript {
    private el: HTMLElement | null;
    private readonly category: string;
    private readonly label: string;
    private readonly moduleHeading: string;

    public constructor(el: HTMLElement) {
        this.el = el;
        this.category = this.el.getAttribute('data-stat-category') ?? '';
        this.label = this.el.getAttribute('data-stat-label') ?? '';
        this.moduleHeading = this.el.getAttribute('data-stat-module-heading') ?? '';
    }

    private clickHandler(): void {
        window.dispatchEvent(new ShowAllClickedEvent(this.category, this.label, this.moduleHeading));
    }

    public execute(): void {
        if (!this.el) {
            return;
        }

        this.el.addEventListener('click', this.clickHandler.bind(this), true);
    }

    public dispose(): void {
        if (this.el) {
            this.el.removeEventListener('click', this.clickHandler.bind(this), true);
        }

        this.el = null;
    }
}
