import { BODY_CLASSES, META_SELECTOR, SpaSelector, SpaAttribute } from '@/spa-navigation/spa-selectors';
import { urlState } from '@/spa-navigation/url-state';

function renderPage(incomingDocument: Document): void {
    removeNoscript(incomingDocument);
    setReferrer();
    updateContent(incomingDocument);
    updateHead(incomingDocument);
    updateBodyTag(incomingDocument);
    updateHtmlTag(incomingDocument);
}

// Noscript tag content shown on render in IE. querySelectorAll('noscript')
// doesn't work in i.e., hence the classname selector
function removeNoscript(document: Document): void {
    document.querySelectorAll(SpaSelector.noscript).forEach(el => removeElement(el));
}

// Element.remove() does not work in IE
function removeElement(elementToRemove: Element | null): void {
    if (!elementToRemove) {
        return;
    }

    elementToRemove.parentElement?.removeChild(elementToRemove);
}

function updateContent(incoming: Document): void {
    const incomingContentWrapper = incoming.querySelector(SpaSelector.contentWrapper);
    const existingContentWrapper = document.querySelector(SpaSelector.contentWrapper);

    if (!incomingContentWrapper || !existingContentWrapper) {
        throw new Error('Content wrapper missing');
    }

    // Cannot use Element.replace(), due to IE
    existingContentWrapper.parentElement?.replaceChild(incomingContentWrapper, existingContentWrapper);
}

function updateHead(incomingDocument: Document): void {
    const incoming = incomingDocument.querySelector('head');
    const existing = document.querySelector('head');

    if (!incoming || !existing) {
        throw new Error('Head is missing in document');
    }

    const fragment = document.createDocumentFragment();

    incoming.querySelectorAll(META_SELECTOR).forEach(el => fragment.appendChild(el));

    existing.querySelectorAll(META_SELECTOR).forEach(el => removeElement(el));

    existing.appendChild(fragment);
}

function updateBodyTag(incomingDocument: Document): void {
    const incoming = incomingDocument.querySelector('body');
    const existing = document.querySelector('body');

    if (!incoming || !existing) {
        throw new Error('Body is missing in document');
    }

    const unitId = incoming.getAttribute(SpaAttribute.UnitId);
    const replacementClassList = incoming.classList;
    const existingClassList = existing.classList;

    BODY_CLASSES.forEach(className => {
        if (existingClassList.contains(className)) {
            replacementClassList.add(className);
        }
    });

    existing.setAttribute(SpaAttribute.UnitId, unitId ?? '');

    existingClassList.value = replacementClassList.toString();
}

function updateHtmlTag(incomingDocument: Document): void {
    const lang = incomingDocument.querySelector('html')?.getAttribute('lang') ?? 'sv';

    document.querySelector('html')?.setAttribute('lang', lang);
}

function setReferrer(): void {
    const currentUrl = urlState.getUrl();
    const fullUrl = new URL(currentUrl, window.location.origin);
    window.srReferrer = fullUrl.toString();
}

export default {
    renderPage,
    removeNoscript,
    removeElement
};
