





























    import { Mixins, Component, Prop } from 'vue-property-decorator';
    import playButton from '@/components/shared/play-button.vue';
    import AudioButton from '@/components/shared/AudioButton.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import { themeHelper } from '@/common/theme-helper';
    import HyphenationPoint from '@/components/shared/ascii/HyphenationPoint.vue';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import { AudioType } from '../models/AudioType';

    @Component({
        components: {
            playButton,
            UiHeading,
            HyphenationPoint,
            AudioButton
        }
    })
    export default class AudioHeading extends Mixins(PlaybackMixin) {
        @Prop({ type: String, required: true })
        tag!: string;

        @Prop(Number) id!: number;
        @Prop(String) type!: string;
        @Prop(String) title!: string;
        @Prop(String) programName!: string;
        @Prop(String) programTheme!: string;
        @Prop(String) duration!: string;
        @Prop(String) broadcastStart!: string;
        @Prop(String) broadcastEnd!: string;
        @Prop(String) durationAbbr!: string;
        @Prop(String) publicationDate!: string;
        @Prop(String) url!: string;
        @Prop(Boolean) hasAudio?: boolean;
        @Prop(Boolean) isAudioInactive?: boolean;
        @Prop(String) appearance?: string;

        get showBroadcast(): boolean {
            return this.broadcastStart != null && this.broadcastEnd != null;
        }

        get hasDuration(): boolean {
            return this.duration != null && this.duration.length > 0;
        }

        get hasProgramName(): boolean {
            return this.programName != null;
        }

        get broadcastAbbr(): string {
            return 'Sändes ' + this.broadcastStart + ' till ' + this.broadcastEnd;
        }

        get broadcastTitle(): string {
            return 'Sändes ' + this.broadcastStart + ' - ' + this.broadcastEnd;
        }

        get programThemeCssClass(): string {
            return themeHelper.getThemeClass(this.programTheme);
        }

        onLinkClick(): void {
            this.$emit('linkClick');
        }

        onPlayAudio(): void {
            const audioKey = {
                id: this.id,
                type: this.type as AudioType
            };
            this.play(audioKey);
        }
    }
