















    import { Mixins, Component, Watch } from 'vue-property-decorator';
    import PlayerBar from '@/components/player/PlayerBar.vue';
    import PlayerExpanded from '@/components/player/PlayerExpanded.vue';
    import FullpageSlide from '@/components/animations/FullpageSlide.vue';
    import Fade from '@/components/animations/Fade.vue';
    import Backdrop from '@/components/shared/Backdrop.vue';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import PlayerStateMixin from '@/components/mixins/PlayerStateMixin';
    import PlayerVolumeMixin from '@/components/mixins/PlayerVolumeMixin';
    import { AudioKey } from '@/components/models/';
    import { LegacyPlayerGetter } from '@/enums/LegacyPlayerGetter';
    import { LegacyPlayerAction } from '@/enums/LegacyPlayerAction';
    import togglePlay from '@/common/toggle-play';
    import keyCodes from '@/enums/key-codes';
    import { PageChangedEvent } from '@/common/custom-events/page-changed-event';
    import { ModalClosedEvent, ModalOpenedEvent } from '@/common/custom-events/modal-events';

    @Component({
        components: {
            PlayerBar,
            PlayerExpanded,
            FullpageSlide,
            Backdrop,
            Fade
        }
    })
    export default class SitePlayer extends Mixins(PlaybackMixin, MetadataMixin, PlayerStateMixin, PlayerVolumeMixin) {
        isExpanded: boolean = false;
        isHidden: boolean = false;
        scrollPosition: number = 0;

        get playerIsVisible(): boolean {
            if (this.isOndemandMode) {
                return true;
            }
            return this.$store.getters[LegacyPlayerGetter.IsVisible] || this.isPlaying || this.isFatal;
        }

        expandClickHandler(): void {
            this.isExpanded = true;
        }

        collapse(): void {
            this.isExpanded = false;
        }

        playHandler(audio: AudioKey, startTime?: number): void {
            if (
                this.isPlaying &&
                this.currentAudioKey &&
                Number(this.currentAudioKey.id) === Number(audio.id) &&
                this.currentAudioKey.type === audio.type
            ) {
                this.pause();
            } else {
                this.play(audio, startTime);
            }
        }

        onCloseModal(): void {
            this.$store.dispatch(LegacyPlayerAction.CloseOverlay);
        }

        unloadHandler(): void {
            this.$store.dispatch(LegacyPlayerAction.Unload);
            this.unload();
        }

        keydownHandler(event: KeyboardEvent): void {
            if (event.altKey) {
                switch (event.key) {
                    case keyCodes.k:
                    case keyCodes.iosOptionK:
                        if (this.isPlaying) {
                            this.pause();
                        } else {
                            this.resume();
                        }
                        break;
                }
            }
        }

        onPageNavigation(): void {
            this.isExpanded = false;
        }

        onModalOpened(): void {
            this.isHidden = true;
        }

        onModalClosed(): void {
            this.isHidden = false;
        }

        mounted(): void {
            window.sr = {
                player: {
                    play: this.playHandler,
                    playQueue: this.playQueue,
                    pause: this.pause,
                    isPlaying: () => this.isPlaying
                }
            };
            window.addEventListener('keydown', this.keydownHandler);
            window.addEventListener('beforeunload', this.unloadHandler);
            window.addEventListener(PageChangedEvent.EventName, this.onPageNavigation);
            window.addEventListener(ModalOpenedEvent.EventName, this.onModalOpened);
            window.addEventListener(ModalClosedEvent.EventName, this.onModalClosed);

            this.restoreSavedState();
        }

        destroyed(): void {
            window.removeEventListener('keydown', this.keydownHandler);
            window.removeEventListener('beforeunload', this.unloadHandler);
            window.removeEventListener(PageChangedEvent.EventName, this.onPageNavigation);
            window.removeEventListener(ModalClosedEvent.EventName, this.onModalClosed);
            window.removeEventListener(ModalOpenedEvent.EventName, this.onModalOpened);
        }

        @Watch('currentAudioKey')
        onCurrentAudioKeyChange(): void {
            this.togglePlayButtons();
        }

        @Watch('isPlaying')
        onIsPlayingChange(): void {
            this.togglePlayButtons();
        }

        private togglePlayButtons(): void {
            if (!this.isPlaying || !this.currentAudioKey) {
                togglePlay.resetPlayIcons();
            } else {
                togglePlay.togglePlayPause({
                    id: this.currentAudioKey.id,
                    type: this.currentAudioKey.type
                });
            }
        }
    }
