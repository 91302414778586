import userState from 'common/user-state.js';
import IDomScript from './i-dom-script';

export default class LocalHeader implements IDomScript {
    private readonly el: HTMLElement;

    public constructor(el: HTMLElement) {
        this.el = el;

        if (this.unitId == unitId.P4Riks || this.unitId == unitId.Meanraatio) {
            return;
        }

        userState.localChannel.get((localChannel: any) => {
            const localChannelLink = this.el.querySelector('[data-js="local-channel-link"]');
            const localChannelLabel = this.el.querySelector('[data-js="local-channel-label"]');

            if (!localChannelLink || !localChannelLabel) {
                return;
            }

            localChannelLink.setAttribute('href', localChannel.url);
            localChannelLink.setAttribute('title', 'Till startsidan för ' + localChannel.name);
            localChannelLabel.innerHTML = localChannel.name;
        });
    }

    private get unitId(): number | undefined {
        return Number(document.body.dataset.unitid) || undefined;
    }

    public execute(): void {}

    public dispose(): void {}
}

const unitId = {
    P4Riks: 158,
    Meanraatio: 1017
};
