











    import { consentService } from '@/common/consent-service';
    import { Vue, Component, Prop } from 'vue-property-decorator';
    import EmbeddedContentDisabled from '@/components/shared/EmbeddedContentDisabled.vue';
    import { CookieConsentUpdatedEvent } from '@/common/custom-events/cookie-consent-updated-event';

    @Component({
        components: { EmbeddedContentDisabled }
    })
    export default class VideoBlob extends Vue {
        @Prop(String) private readonly id!: string;
        @Prop(String) private readonly url!: string;
        @Prop({ type: Boolean, default: false }) private readonly isInModal!: boolean;

        private thirdPartyAllowed: boolean = false;

        get isEnabled(): boolean {
            return this.thirdPartyAllowed;
        }

        mounted(): void {
            this.thirdPartyAllowed = consentService.isThirdPartyAllowed();
            window.addEventListener(CookieConsentUpdatedEvent.EventName, this.onCookieConsentUpdated);
        }

        destroyed(): void {
            window.removeEventListener(CookieConsentUpdatedEvent.EventName, this.onCookieConsentUpdated);
        }

        onCookieConsentUpdated(event: CookieConsentUpdatedEvent): void {
            this.thirdPartyAllowed = event.detail.currentCookieSettings.thirdPartyAllowed;
        }
    }
