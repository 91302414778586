








    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { env } from '@/common/env';
    import Flex from '@/components/shared/Flex.vue';
    import PrimaryLinks from '@/components/shared/primary-links/PrimaryLinks.vue';
    import MenuScroll from '@/components/shared/MenuScroll.vue';

    @Component({
        components: {
            Flex,
            PrimaryLinks,
            MenuScroll
        }
    })
    export default class StartPageSecondaryMenu extends Vue {
        @Prop() private readonly path!: string;

        private clientSidePath: string | null = env.isClient() ? window.location.pathname : null;

        get currentPath(): string {
            return this.clientSidePath ? this.clientSidePath : this.path;
        }
    }
