import { MainMenuLinkCollection, MainMenuLinkItem } from './models';

export function getPrimaryLinks(): MainMenuLinkItem[] {
    return primaryLinks;
}

export function getLinkCollections(): MainMenuLinkCollection[] {
    return links;
}

const primaryLinks: MainMenuLinkItem[] = [
    {
        title: 'Start',
        url: '/',
        icon: 'home'
    },
    {
        title: 'Nyheter',
        url: '/nyheter',
        icon: 'news'
    },
    {
        title: 'Poddar & program',
        url: '/poddar-program',
        icon: 'podcast'
    },
    {
        title: 'Kanaler',
        url: '/kanaler',
        icon: 'direct'
    }
];

const links: MainMenuLinkCollection[] = [
    {
        title: '',
        links: getPrimaryLinks()
    },
    {
        title: 'Kanaler',
        links: [
            {
                title: 'P1',
                url: '/p1'
            },
            {
                title: 'P2',
                url: '/p2'
            },
            {
                title: 'P3',
                url: '/p3'
            },
            {
                title: 'P4',
                url: '',
                chosenLink: 'local'
            },
            {
                title: 'Favoritkanal',
                url: '',
                chosenLink: 'additional'
            }
        ]
    },
    {
        title: 'Redaktioner',
        links: [
            {
                title: 'Vetenskapsradion',
                url: '/vetenskapsradionnyheter'
            },
            {
                title: 'Kulturnytt',
                url: '/kulturnytt'
            },
            {
                title: 'Radiosporten',
                url: '/radiosporten'
            },
            {
                title: 'Barnradion',
                url: '/barnradion',
                hasMonkeyIcon: true
            }
        ]
    },
    {
        title: 'News in other languages',
        links: [
            {
                title: 'العربية (Arabiska)',
                url: '/radioswedenarabic'
            },
            {
                title: 'English',
                url: '/radiosweden'
            },
            {
                title: 'Finska',
                url: '/finska'
            },
            {
                title: 'Meänkieli',
                url: '/meanraatio'
            },
            {
                title: 'فارسی | دری (Farsi/Dari)',
                url: '/radioswedenfarsidari'
            },
            {
                title: 'Українська (Ukrainska)',
                url: '/radioswedenukrainian'
            }
        ]
    },
    {
        title: '',
        links: [
            {
                title: 'Romani',
                url: '/radioromano'
            },
            {
                title: 'Sámi',
                url: '/default.aspx?programid=2327'
            },
            {
                title: 'Somali',
                url: '/radioswedensomaliraadiyahaiswiidhen'
            },
            {
                title: 'Klartext - nyheter på ett enklare sätt',
                url: '/klartext'
            },
            {
                title: 'Radio Sweden på lätt svenska',
                url: '/radioswedenpalattsvenska'
            }
        ]
    }
];
