






    import { Vue, Component, Prop } from 'vue-property-decorator';
    import UiButton from './UiButton.vue';
    import SvgImage from '../../components/shared/SvgImage.vue';

    @Component({
        components: {
            UiButton,
            SvgImage
        }
    })
    export default class UiIconButton extends Vue {
        @Prop({ default: '' }) readonly icon!: string;

        onClick(): void {
            this.$emit('click');
        }
    }
