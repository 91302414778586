


















    import { Vue, Component, Prop } from 'vue-property-decorator';
    import UiContainer from '@/styleguide/elements/UiContainer.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import Flex from '@/components/shared/Flex.vue';
    import Grid from '@/components/shared/Grid.vue';
    import GridItem from '@/components/shared/GridItem.vue';
    import MainMenuSearchItem from '@/components/main-menu/MainMenuSearchItem.vue';
    import UiShowMoreButton from '@/styleguide/elements/UiShowMoreButton.vue';
    import { SearchResponseItem } from '@/api/search-api';
    import { SearchResultType } from '@/components/models/SearchResultType';

    @Component({
        components: {
            UiContainer,
            UiHeading,
            UiCaption,
            Flex,
            Grid,
            GridItem,
            MainMenuSearchItem,
            UiShowMoreButton
        }
    })
    export default class SearchResult extends Vue {
        @Prop({ type: Array, required: true }) readonly result!: SearchResponseItem[];
        @Prop({ type: String, required: true }) readonly title!: string;
        @Prop({ type: Number, required: true }) readonly totalItemsCount!: number;
        @Prop({ type: String, required: true }) readonly term!: string;
        @Prop({ required: true, type: String as () => SearchResultType }) trackingContext!: SearchResultType;

        get hasMoreItems(): boolean {
            return this.result.length < this.totalItemsCount;
        }

        onFetchMoreItems(): void {
            this.$emit('fetchMore');
        }
    }
