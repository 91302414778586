class UrlState {
    private _currentUrl: string = window.location.href;

    getUrl(): string {
        return this._currentUrl;
    }

    saveUrl(url: string): void {
        this._currentUrl = url;
    }
}

export const urlState = new UrlState();
