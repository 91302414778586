import IDomScript from '@/modules/i-dom-script';
import { getMetadata } from '@/common/tracking-metadata';
import type { TrackingMetadata } from '@/tracking/track-types';
import { DownloadEpisodeClickedEvent } from '@/common/custom-events/download-episode-clicked-event';

export default class DownloadButtonTracking implements IDomScript {
    private el: HTMLElement | null;
    private readonly metadata: TrackingMetadata = {};

    public constructor(el: HTMLElement) {
        this.el = el;
        this.metadata = getMetadata(this.el);
    }

    private clickHandler(): void {
        window.dispatchEvent(new DownloadEpisodeClickedEvent(this.metadata));
    }

    public execute(): void {
        if (!this.el) {
            return;
        }

        this.el.addEventListener('click', this.clickHandler.bind(this), true);
    }

    public dispose(): void {
        if (this.el) {
            this.el.removeEventListener('click', this.clickHandler.bind(this), true);
        }

        this.el = null;
    }
}
