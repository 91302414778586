








    import Vue from 'vue';
    import PageHeader from '@/components/poddar-program/PageHeader.vue';
    import PremiumProgramsCarousel from '@/components/poddar-program/poddar-program-page/components/PremiumProgramsCarousel.vue';
    import ProgramCategoryButtons from '@/components/poddar-program/ProgramCategoryButtons.vue';

    export default Vue.extend({
        components: {
            PageHeader,
            PremiumProgramsCarousel,
            ProgramCategoryButtons
        }
    });
