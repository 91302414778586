import IDomScript from '@/modules/i-dom-script';
import { PageChangedEvent } from '@/common/custom-events/page-changed-event';

export default class SystemMessage implements IDomScript {
    private readonly el: HTMLElement;
    private readonly closeButton: HTMLElement | null;
    private clickHandler: EventListener;

    public constructor(el: HTMLElement) {
        this.el = el;
        this.closeButton = this.el?.querySelector('#system-message__button');

        this.clickHandler = () => {
            if (!this.el || !this.closeButton) {
                return;
            }

            this.el.classList.toggle('hidden');
        };
    }

    public execute(): void {
        this.closeButton?.addEventListener('click', this.clickHandler);
    }

    public dispose(): void {
        this.closeButton?.removeEventListener('click', this.clickHandler);
    }
}
