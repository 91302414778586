




    import { Vue, Component, Prop } from 'vue-property-decorator';
    import UiProgressbar from '@/styleguide/elements/UiProgressbar.vue';

    @Component({
        components: {
            UiProgressbar
        }
    })
    export default class LiveProgressbar extends Vue {
        @Prop() theme!: string;
        @Prop() startTimeEpoch!: number;
        @Prop() endTimeEpoch!: number;

        get percentage(): number {
            const timeEpoch = this.$store.getters['timeEpoch'];
            if (!this.startTimeEpoch || !this.endTimeEpoch) {
                return 0;
            }
            const timeRange = this.endTimeEpoch - this.startTimeEpoch;
            const entryTimeEpochRange = timeEpoch - this.startTimeEpoch;
            const percentage = entryTimeEpochRange / timeRange;
            return percentage * 100;
        }
    }
