import spaPageTracker from '@/spa-navigation/spa-page-tracker';
import { CookieConsentUpdatedEvent, CookieConsentUpdatedEventPayload } from './custom-events/cookie-consent-updated-event';

class CookieConsentUpdatedTracker {
    private payload: CookieConsentUpdatedEventPayload | null = null;

    public init(): void {
        window.addEventListener(CookieConsentUpdatedEvent.EventName, e => this.setPayload(e));
    }

    public sendPageTrack(): void {
        if (this.statisticsToggledOn()) {
            spaPageTracker.track();
        }
    }

    private setPayload(event: CookieConsentUpdatedEvent): void {
        this.payload = event.detail;
    }

    private statisticsToggledOn(): boolean {
        return (
            this.payload?.previousCookieSettings.statisticsAllowed === false &&
            this.payload?.currentCookieSettings.statisticsAllowed === true
        );
    }
}

export const cookieConsentUpdatedTracker = new CookieConsentUpdatedTracker();
