

































    import { Vue, Component, Prop } from 'vue-property-decorator';
    import AudioButton from '@/components/shared/AudioButton.vue';
    import HyphenationPoint from '@/components/shared/ascii/HyphenationPoint.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import LazyImage from '@/components/shared/LazyImage.vue';
    import { CardModel } from '@/api/models';

    @Component({
        components: {
            AudioButton,
            UiHeading,
            UiCaption,
            LazyImage,
            HyphenationPoint
        }
    })
    export default class NewsCard extends Vue {
        @Prop() readonly item!: CardModel;

        protected get isValidAudio(): boolean {
            return !!this.item.audioId && this.item.audioId > 0;
        }

        protected onPlayAudio(): void {
            this.$emit('play');
        }

        protected onCardClick(): void {
            this.$emit('cardClick');
        }
    }
