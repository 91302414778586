






    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class UiContainer extends Vue {
        @Prop(Boolean) readonly narrow: boolean | undefined;
        @Prop({ default: 'div', type: String }) readonly tag!: string;
        @Prop(Boolean) readonly gutter!: boolean;
    }
