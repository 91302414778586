import linkEvaluator from '@/common/link-evaluator';
import { throttledErrorLogger } from '@/logging/ThrottledErrorLogger';
import { GoToPageEvent } from '@/common/custom-events/go-to-page-event';
import { ExternalLinkClickedEvent } from '@/common/custom-events/external-link-clicked-event';

export function clickEventListener(event: MouseEvent): void {
    let href;

    try {
        const element = tryGetLinkElement(event);

        if (!element) {
            return;
        }

        href = element.getAttribute('href');
        const target = element.getAttribute('target');
        const linkText = element.innerText;

        if (!href) {
            return;
        } else if (linkEvaluator.isExternal(href)) {
            event.preventDefault();
            trackExternalLink(href, linkText);
            window.open(href, undefined, 'noopener,menubar,toolbar,location,status,resizable,scrollbars'); // allt utom noopener behövs för att ie11 ska öppna i flik
            return;
        } else if (linkEvaluator.isAnchor(href)) {
            return;
        } else if (useSpaNavigation(href, event, target)) {
            spaNavigation(href, event);
        }

        // ...else: do nothing and let default behaviour take care of link click
    } catch (error) {
        throttledErrorLogger.logError({ message: 'Click in spa-navigation failed', error: error });

        // If default is prevented, treat as regular link click
        if (event.defaultPrevented && href) {
            window.location.href = href;
        }

        //...else: just return and let default link behaviour handle reload
    }
}

function tryGetLinkElement(event: MouseEvent): HTMLAnchorElement | null {
    if (!event.composedPath) {
        return tryGetLinkElementIEFix(event);
    }

    return (
        (event.composedPath().find(element => (element as HTMLElement).tagName?.toLowerCase() == 'a') as HTMLAnchorElement) ??
        null
    );
}

// Composed path does not work in IE
function tryGetLinkElementIEFix(event: MouseEvent): HTMLAnchorElement | null {
    const target = event.target as HTMLElement;

    if (!target.tagName) {
        return null;
    }

    if (target.tagName == 'A') {
        return target as HTMLAnchorElement;
    }

    if (target.parentElement && target.parentElement?.tagName == 'A') {
        return target.parentElement as HTMLAnchorElement;
    }

    return null;
}

function trackExternalLink(url: string | undefined, linkText: string): void {
    if (!url || url.includes('radioleaks')) {
        return;
    }

    window.dispatchEvent(new ExternalLinkClickedEvent(url, linkText));
}

function useSpaNavigation(url: string, event: MouseEvent, target: string | null): boolean {
    return (
        !event.metaKey &&
        !event.ctrlKey &&
        !linkEvaluator.isApplicationLink(url) &&
        !linkEvaluator.isImage(url) &&
        !linkEvaluator.isTopsyUrl(url) &&
        !linkEvaluator.isPlaylist(url) &&
        !linkEvaluator.isFileDownload(url) &&
        target !== '_blank'
    );
}

function spaNavigation(href: string, event: MouseEvent): void {
    href = linkEvaluator.replaceSrLinkToRelative(href);

    event.preventDefault();
    window.dispatchEvent(new GoToPageEvent(href));
}
