<template>
    <li class="local-module-list-item">
        <slot></slot>
    </li>
</template>

<script>
    export default {};
</script>
<style lang="scss" scoped>
    .local-module-list-item {
        @include grid-gutter;
    }

    .local-module-list-item {
        @include border-top;
    }
</style>
