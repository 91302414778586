














    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import SvgImage from '@/components/shared/SvgImage.vue';
    import AriaLabelsMixin from '@/components/mixins/AriaLabelsMixin';
    import PlayerStateMixin from '@/components/mixins/PlayerStateMixin';

    @Component({
        components: {
            SvgImage
        }
    })
    export default class PlayerPrevButton extends Mixins(
        PlaybackMixin,
        PlayerStateMixin,
        MetadataMixin,
        AriaLabelsMixin,
        PlayerStateMixin
    ) {
        @Prop(Boolean) large?: boolean;
        @Prop(Boolean) rounded?: boolean;

        get isDisabled(): boolean {
            if (this.isLiveMode && !this.isHlsLoaded) {
                return true;
            }

            return !this.hasPreviousAudios;
        }

        onClick(): void {
            this.playPrevious();
        }
    }
