










    import Vue from 'vue';
    import '@sverigesradio/stil/components/stil-text.js';
    import '@sverigesradio/stil/components/stil-spacer.js';
    import NavigationBar from './NavigationBar.vue';

    export default Vue.extend({
        props: {
            title: {
                type: String
            },
            dataTitle: {
                type: String
            },
            showNavBar: {
                type: Boolean,
                default: false
            }
        },
        components: {
            NavigationBar
        },
        data() {
            return {
                navItems: [
                    {
                        title: 'Upptäck',
                        url: '/poddar-program'
                    },
                    {
                        title: 'A-Ö',
                        url: '/poddar-program/allaprogram'
                    }
                ]
            };
        }
    });
