import IDomScript from '@/modules/i-dom-script';

export default class TablaUtskrift implements IDomScript {
    private readonly el: HTMLElement;
    private readonly button: HTMLElement | null;

    public constructor(el: HTMLElement) {
        this.el = el;
        this.button = this.el.querySelector('button');
    }

    private onClick(): void {
        const hiddenItems = document.querySelectorAll('.tabla-utskrift.hidden');
        hiddenItems.forEach(element => {
            element.classList.remove('hidden');
        });

        this.button?.classList.add('hidden');
    }

    public execute(): void {
        if (!this.button) {
            return;
        }

        this.button.addEventListener('click', this.onClick.bind(this));
    }

    public dispose(): void {
        if (!this.button) {
            return;
        }

        this.button.removeEventListener('click', this.onClick.bind(this));
    }
}
