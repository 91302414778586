

















    import { Vue, Component, Prop } from 'vue-property-decorator';
    import Icon from '@/components/shared/Icon.vue';
    import LocalWeatherWindIcon from '@/components/local-module/local-weather-wind-icon.vue';
    import { WeatherItem } from '@/api/weather-api';
    import WindDirectionHelper from '@/common/wind-direction-helper';

    @Component({
        components: {
            Icon,
            LocalWeatherWindIcon
        }
    })
    export default class LocalWeatherItem extends Vue {
        @Prop() private readonly item!: WeatherItem;

        get temperature(): string {
            return `${this.item.temperature}°`;
        }

        get wind(): string {
            return `${this.item.wind?.speed} (${this.item.wind?.gusts}) m/s`;
        }

        get iconName(): string {
            return this.item.weather ? `weather${this.item.timeOfDay}${this.getIconNameFromWeatherName}` : '';
        }

        get windDirection(): string {
            const abbr = WindDirectionHelper.fullName(this.item.wind?.direction);
            return abbr ? `Vindriktning: ${abbr}` : '';
        }

        get windDirectionAbbr(): string {
            return WindDirectionHelper.abbr(this.item.wind?.direction);
        }

        get getIconNameFromWeatherName(): number | undefined {
            switch (this.item.weather) {
                case 'clearSky':
                    return 1;
                case 'nearlyClearSky':
                    return 2;
                case 'variableCloudiness':
                    return 3;
                case 'halfclearSky':
                    return 4;
                case 'cloudySky':
                    return 5;
                case 'overcast':
                    return 6;
                case 'fog':
                    return 7;
                case 'lightRainShowers':
                case 'moderateRainShowers':
                case 'heavyRainShowers':
                    return 8;
                case 'thunderstorm':
                    return 9;
                case 'lightSleetShowers':
                case 'moderateSleetShowers':
                case 'heavySleetShowers':
                    return 10;
                case 'lightSnowShowers':
                case 'moderateSnowShowers':
                case 'heavySnowShowers':
                    return 11;
                case 'lightRain':
                case 'moderateRain':
                case 'heavyRain':
                    return 12;
                case 'thunder':
                    return 13;
                case 'lightSleet':
                case 'moderateSleet':
                case 'heavySleet':
                    return 14;
                case 'lightSnowfall':
                case 'moderateSnowfall':
                case 'heavySnowfall':
                    return 15;
                default:
                    return undefined;
            }
        }
    }
