








    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { MainMenuLinkItem } from '@/components/main-menu/models';
    import MainMenuMonkey from '@/components/main-menu/MainMenuMonkey.vue';
    import Icon from '@/components/shared/Icon.vue';
    import { PageChangedEvent } from '@/common/custom-events/page-changed-event';

    @Component({
        components: {
            Icon,
            MainMenuMonkey
        }
    })
    export default class MainMenuLink extends Vue {
        @Prop({ type: Object as () => MainMenuLinkItem, required: true }) link!: MainMenuLinkItem;

        path: string = window.location.pathname;

        get isActive(): boolean {
            return this.path === this.link.url || this.path.startsWith(`${this.link.url}/`);
        }

        onClick(): void {
            this.$emit('click', this.link.title);
        }

        onPageNavigation(): void {
            this.path = window.location.pathname;
        }

        mounted(): void {
            window.addEventListener(PageChangedEvent.EventName, this.onPageNavigation);
        }

        destroyed(): void {
            window.removeEventListener(PageChangedEvent.EventName, this.onPageNavigation);
        }
    }
