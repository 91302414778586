
















    import { Vue, Component } from 'vue-property-decorator';
    import NewsCardList from '@/components/shared/NewsCardList.vue';
    import { CuratedApi } from '@/api/curated-api';
    import { CardListModel } from '@/api/models';
    import { SwipedEvent } from '@/common/custom-events/swiped-event';
    import { ShowMoreClickedEvent } from '@/common/custom-events/show-more-clicked-event';

    @Component({
        components: {
            NewsCardList
        }
    })
    export default class Curated extends Vue {
        curatedCollectionList: CardListModel[] = [];
        isExpanded: boolean = false;
        hasTrackedTouchScroll: boolean = false;

        onTouchScroll(title: string): void {
            if (this.hasTrackedTouchScroll) {
                return;
            }

            window.dispatchEvent(new SwipedEvent(title));

            this.hasTrackedTouchScroll = true;
        }

        onShowMoreClick(): void {
            window.dispatchEvent(new ShowMoreClickedEvent({}));
        }

        async created(): Promise<void> {
            try {
                const result = await CuratedApi.getCuratedCollections();
                this.curatedCollectionList = result.items;
            } catch {
                this.curatedCollectionList = [];
            }
        }
    }
