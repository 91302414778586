import { WindDirection } from '@/api/weather-api';

function fullName(direction: WindDirection): string {
    switch (direction) {
        case 'N':
        case 'north':
            return 'Nord';
        case 'NO':
        case 'northEast':
            return 'Nordöst';
        case 'O':
        case 'east':
            return 'Öst';
        case 'SO':
        case 'southEast':
            return 'Sydöst';
        case 'S':
        case 'south':
            return 'Syd';
        case 'SV':
        case 'southWest':
            return 'Sydväst';
        case 'V':
        case 'west':
            return 'Väst';
        case 'NV':
        case 'northWest':
            return 'Nordväst';
        default:
            return '';
    }
}

function abbr(direction: WindDirection): string {
    switch (direction) {
        case 'N':
        case 'north':
            return 'N';
        case 'NO':
        case 'northEast':
            return 'NO';
        case 'O':
        case 'east':
            return 'O';
        case 'SO':
        case 'southEast':
            return 'SO';
        case 'S':
        case 'south':
            return 'S';
        case 'SV':
        case 'southWest':
            return 'SV';
        case 'V':
        case 'west':
            return 'V';
        case 'NV':
        case 'northWest':
            return 'NV';
        default:
            return '';
    }
}

function arrowRotation(direction: WindDirection): number {
    switch (direction) {
        case 'V':
        case 'west':
            return 0;
        case 'NV':
        case 'northWest':
            return 45;
        case 'N':
        case 'north':
            return 90;
        case 'NO':
        case 'northEast':
            return 135;
        case 'O':
        case 'east':
            return 180;
        case 'SO':
        case 'southEast':
            return 225;
        case 'S':
        case 'south':
            return 270;
        case 'SV':
        case 'southWest':
            return 315;
        default:
            return 0;
    }
}

export default {
    fullName,
    abbr,
    arrowRotation
};
