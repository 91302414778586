<template>
    <div :class="['extra-broadcast-item', { active: isActive }]">
        <div v-if="isActive" class="extra-broadcast-item__play">
            <play-button :id="item.id" :type="item.audioType" :title="item.title" :theme="item.theme"> </play-button>
        </div>
        <div class="time" v-else>
            <UiBodyText tag="h6">
                <strong>{{ startTimeFormatted }}</strong>
            </UiBodyText>
        </div>
        <div class="extra-broadcast-item-content__metadata">
            <div class="extra-broadcast-item-content__title">
                <UiBodyText tag="h6">
                    <a :href="item.url" v-if="item.url">
                        <strong>{{ item.title }}</strong>
                    </a>
                    <strong v-else>{{ item.title }}</strong>
                </UiBodyText>
            </div>
            <div v-if="isActive" class="extra-broadcast-item__progress">
                <div v-if="isActive" @click="play" class="extra-broadcast-item__live">
                    <UiLiveLabel />
                </div>
                <TimeAbbr prefix="Programmet började " :time="startTimeFormatted" />
                <div class="extra-broadcast-item__progress-bar">
                    <LiveProgressbar :startTimeEpoch="startTimeEpoch" :endTimeEpoch="endTimeEpoch" />
                </div>
                <TimeAbbr prefix="Programmet slutar " :time="endTimeFormatted" />
            </div>
            <UiBodyText v-if="showMetadata" gray>{{ item.description }}</UiBodyText>
            <footer v-if="showMetadata">
                <ShareButton :shareUrl="item.shareUrl" :broadcastId="item.id" :broadcastName="item.title" />
            </footer>
        </div>
    </div>
</template>

<script>
    import playButton from '@/components/shared/play-button';
    import UiHeading from '@/styleguide/elements/UiHeading';
    import LiveProgressbar from '@/components/shared/LiveProgressbar';
    import UiLiveLabel from '@/styleguide/elements/UiLiveLabel';
    import UiBodyText from '@/styleguide/elements/UiBodyText';
    import ShareButton from '@/components/shared/ShareButton';
    import TimeAbbr from '@/components/shared/TimeAbbr';
    import dateFormatter from '@/common/date-formatter';
    import { LegacyPlayerAction } from '@/enums/LegacyPlayerAction';

    export default {
        props: {
            item: Object,
            compact: Boolean
        },
        components: {
            playButton,
            UiHeading,
            LiveProgressbar,
            UiLiveLabel,
            UiBodyText,
            ShareButton,
            TimeAbbr
        },
        computed: {
            startTimeEpoch() {
                return this.item && this.item.start ? new Date(this.item.start).getTime() : 0;
            },
            endTimeEpoch() {
                return this.item && this.item.end ? new Date(this.item.end).getTime() : 0;
            },
            startTimeFormatted() {
                return this.item && this.item.start ? dateFormatter.shortTime(this.item.start) : 0;
            },
            endTimeFormatted() {
                return this.item && this.item.end ? dateFormatter.shortTime(this.item.end) : 0;
            },
            isActive() {
                return this.item && this.item.hasAudio;
            },
            showMetadata() {
                return !this.compact;
            }
        },
        methods: {
            play() {
                this.$store.dispatch(LegacyPlayerAction.PlayOrPause, { id: this.item.id, type: this.item.audioType });
            }
        }
    };
</script>

<style lang="scss" scoped>
    $image-size: $space-small * 5.5;
    $image-size-large: $space-small * 7;

    .time {
        margin-right: $space-small;
        line-height: 1;
    }

    .extra-broadcast-item {
        @include cell-padding-vertical;
        @include grid-gutter;
        display: flex;
    }

    .extra-broadcast-item__progress {
        @include flex-center;
        @include caption-style;
        line-height: 1;
        justify-content: space-between;
        margin: $space-small 0;
    }

    .extra-broadcast-item__progress-bar {
        padding: 0 $space-small;
        flex: 1;
    }

    .active {
        align-items: flex-start;
    }

    .extra-broadcast-item-content__metadata {
        flex-grow: 1;
        line-height: 1;
    }

    .extra-broadcast-item-progressbar {
        margin: $space-small 0;
    }

    .extra-broadcast-item-content__metaitem {
        display: flex;
    }

    .extra-broadcast-item-content__time {
        display: flex;
    }

    .extra-broadcast-item-content__heading {
        display: flex;
        margin-left: $space-xs;
    }

    .extra-broadcast-item__live {
        margin-right: $space-small;
        cursor: pointer;
        @include mq-tablet-portrait {
            margin-right: $space-small;
        }
    }

    figure {
        width: $image-size;
        overflow: hidden;
        position: relative;
        flex-shrink: 0;

        @include mq-tablet-portrait {
            width: $image-size-large;
        }
    }

    img {
        width: 100%;
        display: block;
    }

    .description {
        margin-top: $space-xs;
    }

    footer {
        display: flex;
        justify-content: flex-end;
    }

    .extra-broadcast-item__play button {
        padding: $space-small;
        margin-top: -$space-small;
        margin-left: -$space-small;
    }
</style>
