






    import { Vue, Component, Prop } from 'vue-property-decorator';
    import anime from 'animejs';

    @Component
    export default class Width extends Vue {
        @Prop({ required: true, type: Number }) expandedWidth!: number;

        get widthPercentage(): string {
            return `${this.expandedWidth}%`;
        }

        onEnter(el: HTMLElement): void {
            anime({
                targets: el,
                width: [0, this.widthPercentage],
                opacity: [0, 1],
                duration: 250,
                easing: 'easeOutCirc'
            });
        }

        onLeave(el: HTMLElement, doneFunc: (anim: anime.AnimeInstance) => void): void {
            anime({
                targets: el,
                width: [this.widthPercentage, 0],
                opacity: [1, 0],
                duration: 250,
                easing: 'easeOutCirc',
                complete: doneFunc
            });
        }
    }
