








    import { Vue, Component } from 'vue-property-decorator';
    import SvgImage from '@/components/shared/SvgImage.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';

    @Component({
        components: {
            SvgImage,
            UiCaption
        }
    })
    export default class MainMenuLogo extends Vue {}
