import { render, staticRenderFns } from "./ShareItem.vue?vue&type=template&id=36e3d9b0&scoped=true&"
import script from "./ShareItem.vue?vue&type=script&lang=ts&"
export * from "./ShareItem.vue?vue&type=script&lang=ts&"
import style0 from "./ShareItem.vue?vue&type=style&index=0&id=36e3d9b0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e3d9b0",
  null
  
)

export default component.exports