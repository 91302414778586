

































    import { Vue, Component, Prop } from 'vue-property-decorator';

    import MainMenuShowMore from '@/components/main-menu/MainMenuShowMore.vue';
    import MainMenuExpandedList from '@/components/main-menu/MainMenuExpandedList.vue';
    import MainMenuSearch from '@/components/main-menu/MainMenuSearch.vue';
    import MainMenuLogo from '@/components/main-menu/MainMenuLogo.vue';
    import Backdrop from '@/components/shared/Backdrop.vue';
    import MainMenuSearchResult from '@/components/main-menu/MainMenuSearchResult.vue';
    import PrimaryLinks from '@/components/shared/primary-links/PrimaryLinks.vue';
    import Flex from '@/components/shared/Flex.vue';
    import UiContainer from '@/styleguide/elements/UiContainer.vue';
    import Fade from '@/components/animations/Fade.vue';
    import Slide from '@/components/animations/Slide.vue';
    import { PageChangedEvent } from '@/common/custom-events/page-changed-event';
    import { SearchOpenEvent } from '@/common/custom-events/search/search-open-event';
    import { MenuOpenEvent } from '@/common/custom-events/menu-open-event';

    @Component({
        components: {
            MainMenuShowMore,
            MainMenuExpandedList,
            MainMenuSearch,
            MainMenuLogo,
            MainMenuSearchResult,
            Backdrop,
            PrimaryLinks,
            Flex,
            Fade,
            Slide,
            UiContainer
        }
    })
    export default class MainMenuWide extends Vue {
        showExpandedList: boolean = false;
        showSearch: boolean = false;

        @Prop() private readonly path!: string;

        onShowMore(): void {
            this.showSearch = false;
            this.showExpandedList = !this.showExpandedList;

            if (this.showExpandedList) {
                window.dispatchEvent(new MenuOpenEvent());
            }
        }

        onSearchOpen(): void {
            this.showSearch = true;
            this.showExpandedList = false;
            window.dispatchEvent(new SearchOpenEvent());
        }

        onClose(): void {
            this.showSearch = false;
            this.showExpandedList = false;
        }

        onSearchClose(): void {
            this.showSearch = false;
        }

        onPageNavigation(): void {
            this.showExpandedList = false;
            this.showSearch = false;
        }

        mounted(): void {
            window.addEventListener(PageChangedEvent.EventName, this.onPageNavigation);
        }

        destroyed(): void {
            window.removeEventListener(PageChangedEvent.EventName, this.onPageNavigation);
        }
    }
