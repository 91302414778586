<template>
    <section>
        <div class="mb-2">
            <local-traffic :channelId="channelId" :channelName="channelName" />
        </div>
        <div class="mb-2">
            <local-weather :channelId="channelId" :channelName="channelName" />
        </div>
    </section>
</template>

<script>
    import localWeather from './local-weather';
    import localTraffic from './local-traffic';
    import { mapGetters } from 'vuex';

    export default {
        props: {
            channelId: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                channelName: '',
                channelUrl: ''
            };
        },
        components: {
            localWeather,
            localTraffic
        },
        computed: {
            ...mapGetters('channels', ['channels']),
            localChannelId() {
                return this.channelId;
            },
            localChannelName() {
                return this.channel(this.localChannelId).title;
            }
        }
    };
</script>

<style lang="scss" scoped>
    $min-height: $space-small * 25;

    section > div {
        @include card-design;
        @include border-thick-left($color: $p4-default);
        min-height: $min-height;
    }
</style>
