
























    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import Skeleton from '@/components/shared/Skeleton.vue';
    import PlayerMusic from '@/components/player/PlayerMusic.vue';
    import BrandedHeading from '@/components/shared/branding/BrandedHeading.vue';
    import Ellipsis from '@/components/shared/Ellipsis.vue';
    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import { AudioMetadata } from '@/components/models';

    @Component({
        components: {
            UiHeading,
            UiCaption,
            Skeleton,
            PlayerMusic,
            BrandedHeading,
            Ellipsis
        }
    })
    export default class PlayerCard extends Mixins(MetadataMixin) {
        @Prop(Number) index!: number;
        @Prop({ type: Object as () => AudioMetadata }) audio!: AudioMetadata;

        private get programName(): string {
            if (this.audio.theme && this.audio.theme.length > 0) {
                return this.audio.theme;
            }
            return this.audio.programName;
        }

        private get audioDate(): string {
            const date = this.audio.date;

            if (!date) {
                return '';
            }

            return date.charAt(0).toUpperCase() + date.slice(1);
        }

        private get isActive(): boolean {
            return this.index === this.currentQueuePosition;
        }
        private get isBroadcast(): boolean {
            return !this.currentAudio.isPod;
        }

        private get showTrack(): boolean {
            return this.isBroadcast && !!this.currentTrack && this.isActive;
        }

        private get showImage(): boolean {
            const imageVisibilityThreshold = 520;
            return window.innerHeight >= imageVisibilityThreshold;
        }

        async mounted(): Promise<void> {
            await this.$nextTick();
            this.$emit('player-card-mounted', { width: this.$el.clientWidth });
        }
    }

    export interface PlayerCardMountedPayload {
        width: number;
    }
