import { Vue } from 'vue-property-decorator';
import { DirectiveOptions } from 'vue';
import keyCodes from '@/enums/key-codes';

const directive: DirectiveOptions = {
    componentUpdated(el) {
        Vue.nextTick().then(() => {
            registerEventListener(el);
        });
    }
};

function registerEventListener(el: HTMLElement): void {
    const focusableElements = el.querySelectorAll(
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
    );
    const firstElement = focusableElements[0] as HTMLElement;
    const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;

    firstElement.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === keyCodes.tab && event.shiftKey && lastElement) {
            event.preventDefault();
            lastElement.focus();
        }
    });
    lastElement.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === keyCodes.tab && !event.shiftKey && firstElement) {
            event.preventDefault();
            firstElement.focus();
        }
    });
}

export default directive;
