









    import { Vue, Component, Prop, Mixins } from 'vue-property-decorator';
    import WarningSign from '@/components/shared/ascii/WarningSign.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import PlayerStateMixin from '@/components/mixins/PlayerStateMixin';

    @Component({
        components: {
            WarningSign,
            UiCaption
        }
    })
    export default class StatusMessage extends Mixins(PlayerStateMixin) {}
