





































    import { Vue, Component } from 'vue-property-decorator';
    import breakpoint from '@/common/breakpoint';
    import httpClient, { IHttpResponse } from '@/common/http-client';
    import ShareItem from '@/components/share/ShareItem.vue';
    import Modal from '@/components/shared/Modal.vue';
    import ShareFromForm from '@/components/share/ShareFromForm.vue';
    import SvgImage from '@/components/shared/SvgImage.vue';
    import UiSectionHeader from '@/styleguide/elements/UiSectionHeader.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import { ShareResponseData, SocialLinkModel } from './models';
    import { SpaNavigationStartedEvent } from '@/common/custom-events/spa-navigation-started-event';
    import { ShareButtonClickedEvent } from '@/common/custom-events/share-button-clicked-event';
    import { TrackingMetadata } from '@/tracking/track-types';
    import { ShareEvent } from '@/common/custom-events/share-event';

    @Component({
        components: {
            Modal,
            ShareItem,
            ShareFromForm,
            SvgImage,
            UiSectionHeader,
            UiHeading
        }
    })
    export default class Share extends Vue {
        model: ShareResponseData | null = null;
        startTime: number = 0;
        isOpen: boolean = false;
        trackingMetadata: TrackingMetadata = {};

        get shareItems(): SocialLinkModel[] {
            if (!this.model) {
                return [];
            }

            if (breakpoint.isNarrow()) {
                return this.model.socialLinks;
            }

            return this.model.socialLinks.filter(x => !x.hideForDesktop);
        }

        get embedCode(): string {
            return `<iframe title="Inbäddat innehåll från Sveriges Radio" width="100%" height="150" src="${this.embedResourceUrl}" frameborder="0"></iframe>`;
        }

        get resourceTitle(): string {
            if (!this.model) {
                return '';
            }

            return `${this.model.title} - ${this.model.description}`;
        }

        get resourceUrl(): string {
            if (!this.model) {
                return '';
            }

            return `${this.model.url}${this.timeHash}`;
        }

        updateStartTime(startTime: number): void {
            this.startTime = startTime;
        }

        private get timeHash(): string {
            if (!this.startTime) {
                return '';
            }

            return `#${this.startTime}`;
        }

        private get embedResourceUrl(): string {
            return this.model?.embedUrl ?? '';
        }

        copyShareUrlToClipboard(): void {
            if (this.$refs.shareUrl instanceof HTMLTextAreaElement) {
                this.putElContentInCopyBuffer(this.$refs.shareUrl);
            }
            window.dispatchEvent(new ShareEvent(this.trackingMetadata));
        }

        copyEmbedToClipboard(): void {
            if (this.$refs.embedCode instanceof HTMLTextAreaElement) {
                this.putElContentInCopyBuffer(this.$refs.embedCode);
            }
        }

        private putElContentInCopyBuffer(el: HTMLTextAreaElement): void {
            el.select();
            el.setSelectionRange(0, 99999);
            document.execCommand('copy');
        }

        open(event: ShareButtonClickedEvent): void {
            this.clear();

            if (!event?.detail?.url) {
                return;
            }
            this.trackingMetadata = event?.detail?.trackingMetadata;
            httpClient
                .get<ShareResponseData>(event.detail.url)
                .then((response: IHttpResponse<ShareResponseData>) => {
                    const data = response.data;
                    this.model = data;
                    this.isOpen = true;
                })
                .catch(() => {
                    this.isOpen = false;
                    this.clear();
                });
        }

        close(): void {
            this.isOpen = false;
            this.clear();
        }

        clear(): void {
            this.model = null;
            this.startTime = 0;
        }

        created(): void {
            window.addEventListener(ShareButtonClickedEvent.EventName, this.open);
            window.addEventListener(SpaNavigationStartedEvent.EventName, this.close);
        }

        destroyed(): void {
            window.removeEventListener(ShareButtonClickedEvent.EventName, this.open);
            window.removeEventListener(SpaNavigationStartedEvent.EventName, this.close);
        }
    }
