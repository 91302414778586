















    import { Mixins, Component } from 'vue-property-decorator';
    import Flex from '@/components/shared/Flex.vue';
    import Icon from '@/components/shared/Icon.vue';
    import RadioButton from '@/components/shared/forms/RadioButton.vue';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import { AudioQuality } from '@/components/models/';
    import { PlayerMode } from '@/enums/PlayerMode';
    import MetadataMixin from '@/components/mixins/MetadataMixin';

    @Component({
        components: {
            Flex,
            Icon,
            UiCaption,
            RadioButton
        }
    })
    export default class PlayerAudioQuality extends Mixins(PlaybackMixin, MetadataMixin) {
        get localQuality(): AudioQuality {
            if (this.quality === AudioQuality.Medium && this.playerMode === PlayerMode.Hls) {
                return AudioQuality.High;
            }
            return this.quality;
        }

        set localQuality(quality: AudioQuality) {
            const currentQuality = this.localQuality;
            if (currentQuality !== quality) {
                this.setQuality(quality);
            }
        }

        get formQualities(): FormQuality[] {
            if (this.playerMode === PlayerMode.Hls) {
                return [
                    { id: AudioQuality.Low, name: 'Begränsad' },
                    { id: AudioQuality.High, name: 'Automatisk' }
                ];
            }
            return [
                { id: AudioQuality.Low, name: 'Låg' },
                { id: AudioQuality.Medium, name: 'Mellan' },
                { id: AudioQuality.High, name: 'Hög' }
            ];
        }
    }

    interface FormQuality {
        id: AudioQuality;
        name: string;
    }
