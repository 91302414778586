import IDomScript from '@/modules/i-dom-script';
import { ModalClosedEvent, ModalOpenedEvent } from '@/common/custom-events/modal-events';
import { PlayerExpandedEvent } from '@/common/custom-events/player-interaction/player-expanded-event';
import { PlayerCollapsedEvent } from '@/common/custom-events/player-interaction/player-collapse-event';

export default class ModalHelper implements IDomScript {
    private el: HTMLElement;
    private open: EventListener;
    private close: EventListener;
    private playerExpanded: EventListener;
    private playerCollapsed: EventListener;
    private focusedElement: HTMLElement | null;

    constructor(el: HTMLElement) {
        this.el = el;
        this.focusedElement = null;

        this.open = () => {
            this.el.classList.add('modal--open');
            this.el.setAttribute('aria-hidden', 'true');
            this.focusedElement = document.activeElement as HTMLElement;
        };

        this.close = () => {
            this.el.classList.remove('modal--open');
            this.el.removeAttribute('aria-hidden');

            if (this.focusedElement) {
                this.focusedElement.focus();
            }

            this.focusedElement = null;
        };

        this.playerExpanded = () => {
            this.el.setAttribute('aria-hidden', 'true');
            document.body.classList.add('body-player--expanded');
            this.el.classList.add('player--expanded');
            this.focusedElement = document.activeElement as HTMLElement;
        };

        this.playerCollapsed = () => {
            document.body.classList.remove('body-player--expanded');
            this.el.removeAttribute('aria-hidden');
            this.el.classList.remove('player--expanded');

            if (this.focusedElement) {
                this.focusedElement.focus();
            }

            this.focusedElement = null;
        };
    }

    public execute(): void {
        window.addEventListener(ModalOpenedEvent.EventName, this.open);
        window.addEventListener(ModalClosedEvent.EventName, this.close);
        window.addEventListener(PlayerExpandedEvent.EventName, this.playerExpanded);
        window.addEventListener(PlayerCollapsedEvent.EventName, this.playerCollapsed);
    }

    public dispose(): void {
        window.removeEventListener(ModalOpenedEvent.EventName, this.open);
        window.removeEventListener(ModalClosedEvent.EventName, this.close);
        window.removeEventListener(PlayerExpandedEvent.EventName, this.playerExpanded);
        window.removeEventListener(PlayerCollapsedEvent.EventName, this.playerCollapsed);
    }
}
