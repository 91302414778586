<template>
    <button :aria-label="ariaLabel" @click.prevent="play">
        <UiPlayPauseIcon :isPlaying="isPlaying" :theme="theme" />
    </button>
</template>

<script>
    import UiPlayPauseIcon from '@/styleguide/elements/UiPlayPauseIcon';
    import { LegacyPlayerAction } from '@/enums/LegacyPlayerAction';
    import { LegacyPlayerGetter } from '@/enums/LegacyPlayerGetter';

    export default {
        components: {
            UiPlayPauseIcon
        },
        props: {
            id: Number,
            type: String,
            title: String,
            theme: String
        },
        computed: {
            audioPlaying() {
                return this.$store.getters[LegacyPlayerGetter.AudioPlaying];
            },
            isPlaying() {
                return (
                    this.audioPlaying &&
                    this.audioPlaying.id.toString() === this.id.toString() &&
                    this.audioPlaying.type === this.type
                );
            },
            ariaLabel() {
                return 'Lyssna på ' + this.title;
            }
        },
        methods: {
            play() {
                this.$store.dispatch(LegacyPlayerAction.PlayOrPause, { id: this.id, type: this.type });
                this.$emit('playAudio');
            }
        }
    };
</script>
<style lang="scss" scoped>
    button {
        @include reset-input-styles;
        padding: 0;
    }
</style>
