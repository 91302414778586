


































    import { Component, Mixins } from 'vue-property-decorator';
    import { MainMenuLinkCollection } from '@/components/main-menu/models';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiContainer from '@/styleguide/elements/UiContainer.vue';
    import UiIconButton from '@/styleguide/elements/UiIconButton.vue';
    import Grid from '@/components/shared/Grid.vue';
    import GridItem from '@/components/shared/GridItem.vue';
    import MenuLink from '@/components/main-menu/MainMenuLink.vue';
    import MainMenuLocalChannelLink from '@/components/main-menu/MainMenuLocalChannelLink.vue';
    import ChosenChannelLink from '@/components/main-menu/ChosenChannelLink.vue';
    import KeyCodes from '@/enums/key-codes';
    import Icon from '@/components/shared/Icon.vue';
    import { getLinkCollections } from './mainMenuLinks';
    import ChannelsMixin from '@/components/mixins/ChannelsMixin';
    import { ChangeAdditionalChannelEvent } from '@/common/custom-events/select-channel/change-additional-channel-event';
    import { ChangeLocalChannelEvent } from '@/common/custom-events/select-channel/change-local-channel-event';
    import { MenuClickedEvent } from '@/common/custom-events/menu-clicked-event';

    @Component({
        components: {
            Icon,
            UiHeading,
            UiContainer,
            UiIconButton,
            Grid,
            GridItem,
            MenuLink,
            MainMenuLocalChannelLink,
            ChosenChannelLink
        }
    })
    export default class MainMenuExpandedList extends Mixins(ChannelsMixin) {
        get linkCollections(): MainMenuLinkCollection[] {
            return getLinkCollections();
        }

        onChangeLocalChannel(): void {
            window.dispatchEvent(new ChangeLocalChannelEvent('global-meny', true));
        }

        onChangeAdditionalChannel(): void {
            window.dispatchEvent(new ChangeAdditionalChannelEvent('global-meny'));
        }

        onKeydown(event: KeyboardEvent): void {
            if (event.key !== KeyCodes.escape) {
                return;
            }

            this.$emit('close');
        }

        onTabOut(event: KeyboardEvent): void {
            if (!event.shiftKey) {
                this.$emit('tabOut');
            }
        }

        onLinkClick(linkTitle: string): void {
            window.dispatchEvent(new MenuClickedEvent(linkTitle));
        }

        onClose(): void {
            this.$emit('close');
        }

        mounted(): void {
            window.addEventListener('keydown', this.onKeydown);
        }

        destroyed(): void {
            window.removeEventListener('keydown', this.onKeydown);
        }
    }
