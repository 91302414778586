








    import { Mixins, Component, Watch } from 'vue-property-decorator';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import Flex from '@/components/shared/Flex.vue';
    import RadioButton from '@/components/shared/forms/RadioButton.vue';
    import storage from '@/common/storage';
    import MetadataMixin from '../mixins/MetadataMixin';
    import { PlayerAutoPlayToggleEvent } from '@/common/custom-events/player-interaction/player-autoplay-toggle-event';

    @Component({
        components: {
            UiCaption,
            Flex,
            RadioButton
        }
    })
    export default class PlayerAutoPlayToggle extends Mixins(MetadataMixin) {
        private autoplay: boolean = storage.disableAutoplay ? false : true;

        @Watch('autoplay')
        onAutoplayChange(val: boolean): void {
            storage.disableAutoplay = !val;
            window.dispatchEvent(new PlayerAutoPlayToggleEvent(this.currentAudio, this.playlistTitle));
        }
    }
