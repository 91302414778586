















    import { Vue, Component, Prop } from 'vue-property-decorator';
    import anime from 'animejs';
    import { themeHelper } from '@/common/theme-helper';
    import siteSettings from '@/common/site-settings';

    @Component
    export default class UiPlayerPlayPauseIcon extends Vue {
        @Prop({ default: false }) readonly isPlaying!: boolean;
        @Prop(String) readonly theme: string | undefined;
        @Prop(Boolean) readonly large: boolean | undefined;

        get themeCssClass(): string {
            return themeHelper.getFillClass(this.theme);
        }

        get svgSpriteBaseUrl(): string {
            return siteSettings.svgSpriteName;
        }

        iconName(name: string): string {
            return `${this.svgSpriteBaseUrl}${name}`;
        }

        playIconLeave(el: HTMLElement, done: (anim: anime.AnimeInstance) => void): void {
            anime({
                targets: el,
                opacity: [1, 0],
                scale: [1, 0.6],
                duration: 250,
                easing: 'easeOutBack',
                complete: done
            });
        }

        playIconEnter(el: HTMLElement, done: (anim: anime.AnimeInstance) => void): void {
            anime({
                targets: el,
                opacity: [0, 1],
                scale: [0.6, 1],
                duration: 250,
                easing: 'easeOutBack',
                complete: done
            });
        }
    }
