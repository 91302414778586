







    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { consentService } from '@/common/consent-service';
    import EmbeddedContentDisabled from '@/components/shared/EmbeddedContentDisabled.vue';
    import { CookieConsentUpdatedEvent } from '@/common/custom-events/cookie-consent-updated-event';

    @Component({
        components: { EmbeddedContentDisabled }
    })
    export default class HtmlBlob extends Vue {
        private thirdPartyAllowed: boolean = false;

        @Prop(String) id!: string;
        @Prop(String) url!: string;

        get isEnabled(): boolean {
            return this.thirdPartyAllowed;
        }

        mounted(): void {
            this.thirdPartyAllowed = consentService.isThirdPartyAllowed();
            window.addEventListener(CookieConsentUpdatedEvent.EventName, this.onCookieConsentUpdated);
        }

        destroyed(): void {
            window.removeEventListener(CookieConsentUpdatedEvent.EventName, this.onCookieConsentUpdated);
        }

        onCookieConsentUpdated(event: CookieConsentUpdatedEvent): void {
            this.thirdPartyAllowed = event.detail.currentCookieSettings.thirdPartyAllowed;
        }
    }
