






    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class GridItem extends Vue {
        @Prop({
            default: 'div'
        })
        readonly tag!: string;
        @Prop(Boolean) readonly collapse: boolean | undefined;
        @Prop(Boolean) readonly border: boolean | undefined;

        get collapseCss(): string | undefined {
            return this.collapse ? 'grid-item--collapse' : undefined;
        }

        get borderCss(): string | undefined {
            return this.border ? 'grid-item--border' : undefined;
        }
    }
