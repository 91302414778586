






    import { Vue, Component } from 'vue-property-decorator';
    import anime from 'animejs';

    @Component({ name: 'Slide' })
    export default class Slide extends Vue {
        onEnter(el: HTMLElement): void {
            anime({
                targets: el,
                opacity: [0, 1],
                translateY: ['-5%', 0],
                duration: 250,
                easing: 'easeOutCirc'
            });
        }

        onLeave(el: HTMLElement, doneFunc: (anim: anime.AnimeInstance) => void): void {
            anime({
                targets: el,
                opacity: [1, 0],
                duration: 250,
                easing: 'easeOutCirc',
                complete: doneFunc
            });
        }
    }
