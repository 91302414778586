<template>
    <div class="local-news">
        <local-kicker :title="channelName" />
        <component-header title="Senaste nyheterna" :screen-reader-title="channelName">
            <change-local-button trackingLabel="senaste nyheterna" />
        </component-header>
        <local-module-list>
            <local-module-list-item v-for="item in items" :key="item.publicationId">
                <local-news-item :item="item" />
            </local-module-list-item>
        </local-module-list>
        <UiLinkInternal :title="linkText" :url="channelUrl" class="link" />
    </div>
</template>

<script>
    import localModuleList from './local-module-list';
    import localModuleListItem from './local-module-list-item';
    import localNewsItem from './local-news-item';
    import componentHeader from '@/components/shared/component-header';
    import UiLinkInternal from '@/styleguide/elements/UiLinkInternal';
    import changeLocalButton from '@/components/shared/change-local-button';
    import localKicker from './local-kicker';

    export default {
        props: {
            channelId: String,
            channelUrl: String,
            channelName: String
        },
        components: {
            localModuleList,
            localModuleListItem,
            localNewsItem,
            componentHeader,
            UiLinkInternal,
            changeLocalButton,
            localKicker
        },
        computed: {
            items() {
                return this.$store.getters['localNews/items'];
            },
            linkText() {
                return 'Till ' + this.channelName;
            }
        },
        watch: {
            channelId(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.$store.dispatch('localNews/load', newVal);
                }
            }
        },
        created() {
            if (this.channelId) {
                this.$store.dispatch('localNews/load', this.channelId);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .local-news {
        @include padding-top;
    }

    .link {
        @include border-top;
    }
</style>
