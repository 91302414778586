import { consentService } from './consent-service';
import siteSettings from './site-settings';

function install(): void {
    if (!shouldTrack()) {
        return;
    }

    const gTagManagerScript = document.createElement('script');
    gTagManagerScript.async = true;
    gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${siteSettings.googleAdsTagId}`;

    document.body.appendChild(gTagManagerScript);

    const timestamp = window.jsInitTimestamp ?? new Date();
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push('js', timestamp);
    window.dataLayer.push('config', siteSettings.googleAdsTagId);

    window.dataLayer.push('consent', 'default', {
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        ad_storage: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied'
    });
    window.dataLayer.push({ event: 'consent_updated' });
}

function shouldTrack(): boolean {
    return consentService.isStatisticsAllowed() && siteSettings.googleAdsTagEnabled && siteSettings.googleAdsTagId != '';
}

export const googleAdsTag = {
    install
};
