






    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { SecondaryMenuLinkItem } from '@/components/secondary-menu/models';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';

    @Component({
        components: {
            UiCaption
        }
    })
    export default class SecondaryMenuItem extends Vue {
        @Prop() link!: SecondaryMenuLinkItem;
    }
