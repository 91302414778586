













































    import { Mixins, Component } from 'vue-property-decorator';

    import Flex from '@/components/shared/Flex.vue';
    import SvgImage from '@/components/shared/SvgImage.vue';
    import UiPlayerPlayPauseIcon from '@/styleguide/elements/UiPlayerPlayPauseIcon.vue';
    import PlayerPrevButton from '@/components/player/shared/PlayerPrevButton.vue';
    import PlayerNextButton from '@/components/player/shared/PlayerNextButton.vue';

    import AriaLabelsMixin from '@/components/mixins/AriaLabelsMixin';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import PlayerStateMixin from '@/components/mixins/PlayerStateMixin';
    import PlayerProgressMixin from '@/components/mixins/PlayerProgressMixin';

    import { PlayerTabIndexes } from '@/enums/tab-index';

    @Component({
        components: {
            Flex,
            SvgImage,
            UiPlayerPlayPauseIcon,
            PlayerPrevButton,
            PlayerNextButton
        }
    })
    export default class PlayerBarControls extends Mixins(
        AriaLabelsMixin,
        PlaybackMixin,
        MetadataMixin,
        PlayerStateMixin,
        PlayerProgressMixin
    ) {
        private get tabIndex(): number {
            return this.queue.length < 1 ? PlayerTabIndexes.playberBarEmpty : PlayerTabIndexes.playerBar;
        }

        private get playButtonAriaLabel(): string {
            return this.isPlaying ? this.ariaLabels.Stop : this.playAriaLabel(this.currentAudio?.programName);
        }

        togglePlay(): void {
            if (this.isPlaying) {
                this.pause();
            } else {
                this.resume();
            }
        }
    }
