






    import { Vue, Component } from 'vue-property-decorator';
    import anime from 'animejs';

    @Component
    export default class FullpageSlide extends Vue {
        onEnter(el: HTMLElement, done: (anim: anime.AnimeInstance) => void): void {
            anime({
                targets: el,
                translateY: [100, 0],
                duration: 500,
                easing: 'easeOutExpo',
                complete: done
            });
        }

        onLeave(el: HTMLElement, done: (anim: anime.AnimeInstance) => void): void {
            anime({
                targets: el,
                translateY: [0, 50],
                opacity: [1, 0],
                easing: 'easeOutQuart',
                duration: 250,
                complete: done
            });
        }
    }
