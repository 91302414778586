




    import { Vue, Component, Prop } from 'vue-property-decorator';
    import RightArrow from '@/components/shared/ascii/RightArrow.vue';
    import { WindDirection } from '@/api/weather-api';
    import windDirectionHelper from '@/common/wind-direction-helper';

    @Component({
        components: {
            RightArrow
        }
    })
    export default class LocalWeatherWindIcon extends Vue {
        @Prop() private readonly direction!: WindDirection;

        get rotationCssClass(): string {
            return `rotation-${this.direction.toLowerCase()}`;
        }

        get rotationCss(): string {
            const rotation = windDirectionHelper.arrowRotation(this.direction);

            return rotation ? `transform: rotate(${rotation}deg)` : '';
        }
    }
