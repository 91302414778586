import { render, staticRenderFns } from "./EmbeddedContentDisabled.vue?vue&type=template&id=268de172&scoped=true&"
import script from "./EmbeddedContentDisabled.vue?vue&type=script&lang=ts&"
export * from "./EmbeddedContentDisabled.vue?vue&type=script&lang=ts&"
import style0 from "./EmbeddedContentDisabled.vue?vue&type=style&index=0&id=268de172&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "268de172",
  null
  
)

export default component.exports