import { render, staticRenderFns } from "./MainMenuSearchResult.vue?vue&type=template&id=2fea1044&scoped=true&"
import script from "./MainMenuSearchResult.vue?vue&type=script&lang=ts&"
export * from "./MainMenuSearchResult.vue?vue&type=script&lang=ts&"
import style0 from "./MainMenuSearchResult.vue?vue&type=style&index=0&id=2fea1044&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fea1044",
  null
  
)

export default component.exports