import { secondsToTime } from '@/common/player-time-helper';
import IDomScript from '@/modules/i-dom-script';

export default class ShowIfStarttime implements IDomScript {
    private readonly secondsPerDay = 86400;

    public constructor(el: HTMLElement) {
        const starttime = window.location.hash.replace('#', '');
        const starttimeSpan = el.querySelector('#starttime-metadata');

        if (!Number(starttime) || Number(starttime) <= 0 || Number(starttime) > this.secondsPerDay || !starttimeSpan) {
            return;
        }

        el.classList.remove('hidden');
        starttimeSpan.textContent = secondsToTime(Number(starttime));
    }

    public execute(): void {}

    public dispose(): void {}
}
