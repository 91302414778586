<template>
    <header class="component-header">
        <UiHeading tag="h2" appearance="h3"
            >{{ title }} <span class="offscreen">{{ screenReaderTitle }}</span></UiHeading
        >
        <div class="component-header__meta">
            <slot></slot>
        </div>
    </header>
</template>

<script>
    import UiHeading from '@/styleguide/elements/UiHeading';
    export default {
        components: {
            UiHeading
        },
        props: {
            title: {
                type: String,
                required: true
            },
            screenReaderTitle: {
                type: String
            }
        }
    };
</script>

<style lang="scss" scoped>
    .component-header {
        @include grid-gutter;
        @include flex-items-on-sides($align: center);
        @include divider-bottom-small;
    }
</style>
