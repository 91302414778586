<template>
    <stil-card :href="url" :gap="2">
        <stil-image slot="media" aspectRatio="1x1" :src="src" rounded></stil-image>
        <stil-grid slot="content" :gap="0">
            <stil-text as="h3" variant="h3" :truncateLine="2">{{ title }}</stil-text>
            <stil-text as="p" variant="caption" color="secondary" :truncateLine="2">{{ description }}</stil-text>
        </stil-grid>
    </stil-card>
</template>

<script>
    import Vue from 'vue';
    import '@sverigesradio/stil/components/stil-card.js';
    import '@sverigesradio/stil/components/stil-grid.js';
    import '@sverigesradio/stil/components/stil-image.js';
    import '@sverigesradio/stil/components/stil-text.js';

    export default Vue.extend({
        props: {
            title: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: true
            },
            src: {
                type: String
            },
            url: {
                type: String,
                required: true
            }
        }
    });
</script>
