import domModules from './dom-modules';
import vueSiteInit from './vue-site-init';
import { DomUpdatedEvent } from '@/common/custom-events/dom-updated-event';
import { SpaNavigationStartedEvent } from './common/custom-events/spa-navigation-started-event';

export default class DomHookup {
    private static instance: DomHookup;
    private readonly registeredModules: IModuleObject[] = [];

    public static init(): DomHookup {
        if (!DomHookup.instance) {
            DomHookup.instance = new DomHookup();
        }
        return DomHookup.instance;
    }

    private constructor() {
        this.hookup();
        window.addEventListener(DomUpdatedEvent.EventName, () => {
            this.disposeUnusedModules();
            this.hookup();
            vueSiteInit.initFromSinglePage();
        });
        window.addEventListener(SpaNavigationStartedEvent.EventName, () => {
            vueSiteInit.destroy();
        });
    }

    private hookup(): void {
        const elements = document.querySelectorAll('[data-require]:not([data-require-id])');
        let i: number;
        for (i = 0; i < elements.length; i++) {
            this.initDomModules(elements[i]);
        }
    }

    private initDomModules(el: Element): void {
        const modulesToRequire = el.getAttribute('data-require'),
            modules = modulesToRequire ? modulesToRequire.split(' ') : [],
            moduleId = Math.floor(Math.random() * 100000000);

        el.setAttribute('data-require-id', moduleId.toString());

        let i: number;
        for (i = 0; i < modules.length; i++) {
            const moduleName = modules[i],
                domModule = domModules.get(moduleName);

            if (domModule) {
                this.initializeAndRegisterModule(el, domModule, moduleName, moduleId);
            }
        }
    }

    private initializeAndRegisterModule(el: Element, domModule: any, moduleName: string, moduleId: number): void {
        let initializedModule: any = null;

        if (domModule.init) {
            // init js module
            initializedModule = domModule.init.apply(el);
        } else if (domModule.prototype.execute && domModule.prototype.dispose) {
            // init ts modules
            const Module = domModule;
            initializedModule = new Module(el);
            initializedModule.execute();
        }

        if (initializedModule) {
            const moduleObject: IModuleObject = {
                id: moduleId,
                name: moduleName,
                module: initializedModule,
                disposable: typeof initializedModule.dispose === 'function'
            };
            this.registeredModules.push(moduleObject);
        }
    }

    private disposeUnusedModules(): void {
        let i: number, moduleObject: IModuleObject;

        for (i = 0; i < this.registeredModules.length; i++) {
            moduleObject = this.registeredModules[i];
            if (!moduleObject) {
                continue;
            }

            const el = document.querySelector(`[data-require-id="${moduleObject.id}"]`);

            if (el) {
                continue;
            }

            if (moduleObject.disposable) {
                moduleObject.module.dispose();
            }

            this.registeredModules.splice(i, 1);
            i--;
        }
    }
}

interface IModuleObject {
    id: number;
    name: string;
    module: any;
    disposable: boolean;
}
