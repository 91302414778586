import IDomScript from '@/modules/i-dom-script';

export default class ShowOnClick implements IDomScript {
    private el: HTMLElement | null;
    private button: HTMLElement | null;
    private hideItems: NodeListOf<HTMLElement>;
    private showItems: NodeListOf<HTMLElement>;
    private clickHandler: EventListener;

    public constructor(el: HTMLElement) {
        this.el = el;
        this.button = this.el.querySelector('[data-show-button="true"]');
        this.hideItems = this.el.querySelectorAll('[data-show="hide"]');
        this.showItems = this.el.querySelectorAll('[data-show="show"]');
        this.showItems.forEach(o => o.setAttribute('aria-expanded', 'false'));

        this.clickHandler = (e: Event): void => {
            e.preventDefault();
            this.disposeButton();
            this.hideItems.forEach(o => o.remove());
            this.showItems.forEach(o => {
                o.style.display = 'block';
                o.setAttribute('aria-expanded', 'true');
            });
        };
    }

    public execute(): void {
        if (!this.button) {
            return;
        }

        this.button.addEventListener('click', this.clickHandler);
    }

    private disposeButton(): void {
        if (this.button) {
            this.button.removeEventListener('click', this.clickHandler);
        }

        this.button = null;
    }

    public dispose(): void {
        this.disposeButton();
        this.el = null;
    }
}
