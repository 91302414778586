




























    import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
    import Icon from '@/components/shared/Icon.vue';
    import storage from '@/common/storage';
    import Fade from '@/components/animations/Fade.vue';
    import Width from '@/components/animations/Width.vue';
    import { PageChangedEvent } from '@/common/custom-events/page-changed-event';
    import { GoToPageEvent } from '@/common/custom-events/go-to-page-event';

    @Component({
        components: {
            Icon,
            Fade,
            Width
        }
    })
    export default class MainMenuSearch extends Vue {
        @Prop({ type: Boolean, required: true }) isSearchActive!: boolean;

        $refs!: Vue['$refs'] & { search: HTMLElement };

        get term(): string {
            return this.$store.getters['search/term'];
        }

        set term(value: string) {
            this.$store.dispatch('search/setSearchTerm', value);
        }

        get hasSearchResult(): boolean {
            return this.$store.getters['search/hasSearchResult'];
        }

        get showCancelButton(): boolean {
            const hasSearchHistory = storage.searchHistory && !!storage.searchHistory.length;

            return this.isSearchActive && (hasSearchHistory || this.hasSearchResult);
        }

        get showResetButton(): boolean {
            return !!this.term.length;
        }

        onSearchFocus(): void {
            this.$emit('open');
        }

        onClose() {
            this.close();
        }

        onReset() {
            this.clear();
            (this.$refs.search as HTMLElement).focus();
        }

        onInputEscKeydown(event: KeyboardEvent) {
            if (this.term.length) {
                return;
            }
            if (event.target instanceof HTMLElement) {
                event.target.blur();
            }

            this.close();
        }

        onInputTabShiftKeydown() {
            this.close();
        }

        onEnter() {
            window.dispatchEvent(new GoToPageEvent(`/sok?query=${this.term}`));
        }

        @Watch('isSearchActive')
        onIsSearchActiveChange(newVal: boolean) {
            if (newVal === true) {
                this.$refs.search.focus();
            } else {
                this.clear();
            }
        }

        close(): void {
            this.clear();
            this.$emit('close');
        }

        clear(): void {
            this.term = '';
        }

        async mounted(): Promise<void> {
            window.addEventListener(PageChangedEvent.EventName, this.close);

            if (this.isSearchActive) {
                await this.$nextTick();
                this.$refs.search.focus();
            }
        }

        destroyed(): void {
            this.term = '';
            window.removeEventListener(PageChangedEvent.EventName, this.close);
        }
    }
