import { customEventInit } from './custom-events-common';

type GoToPageEventPayload = { url: string };

export class GoToPageEvent extends CustomEvent<GoToPageEventPayload> {
    public static EventName = 'go-to-page' as const;
    constructor(url: string) {
        super(GoToPageEvent.EventName, customEventInit({ url }));
    }
}

declare global {
    interface WindowEventMap {
        [GoToPageEvent.EventName]: GoToPageEvent;
    }
}
