import { consentService } from '@/common/consent-service';
import httpClient from '@/common/http-client';
import IDomScript from '@/modules/i-dom-script';
import { AxiosError } from 'axios';
import siteSettings from '@/common/site-settings';

export default class PageViewTracker implements IDomScript {
    private unitId: string;
    private publicationId: string;

    constructor(el: HTMLElement) {
        this.unitId = el.getAttribute('data-unit-id') ?? '';
        this.publicationId = el.getAttribute('data-publication-id') ?? '';
    }

    public execute(): void {
        if (!consentService.isStatisticsAllowed()) {
            return;
        }

        const encodedReferrer = encodeURIComponent(window.srReferrer ?? '');
        const url = `${siteSettings.statisticsEventApiUrl}v1/pageview/statistics.gif?programId=${this.unitId}&publicationId=${this.publicationId}&referrer=${encodedReferrer}`;

        httpClient.get(url).catch((error: AxiosError) => {
            throw error;
        });
    }

    public dispose(): void {}
}
