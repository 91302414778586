<template>
    <UiPage class="extra-broadcasts">
        <TabNavigation :items="navigationItems" />
        <UiPageSection v-if="hasLiveItems">
            <UiSectionHeader title="Pågående" compact />
            <Grid :medium="2" collapse>
                <GridItem v-for="(item, index) in liveItems" :key="index" collapse border>
                    <ExtraBroadcastItem :item="item" />
                </GridItem>
            </Grid>
        </UiPageSection>
        <UiPageSection v-for="day in upcomingDays" :key="day.key">
            <UiSectionHeader :title="day.day" capitalize compact />
            <Grid :medium="2" collapse>
                <GridItem v-for="(item, index) in day.items" :key="index" collapse border>
                    <ExtraBroadcastItem :item="item" />
                </GridItem>
            </Grid>
        </UiPageSection>
    </UiPage>
</template>

<script>
    import UiPage from '@/styleguide/elements/UiPage';
    import UiSectionHeader from '@/styleguide/elements/UiSectionHeader';
    import UiPageSection from '@/styleguide/elements/UiPageSection';
    import Grid from '@/components/shared/Grid';
    import GridItem from '@/components/shared/GridItem';
    import TabNavigation from '@/components/shared/TabNavigation';
    import ExtraBroadcastItem from '@/components/extra-broadcasts/ExtraBroadcastItem';
    import dateFormatter from '@/common/date-formatter';
    import httpClient from '@/common/http-client';

    let UPDATE_INTERVAL;

    export default {
        data() {
            return {
                jsonData: null,
                navigationItems: [
                    {
                        title: 'Kanaler',
                        url: '/kanaler'
                    },
                    {
                        title: 'Extrasändningar',
                        url: '/extrasandningar',
                        active: true
                    }
                ]
            };
        },
        props: {
            dataJson: String
        },
        components: {
            UiPage,
            UiPageSection,
            UiSectionHeader,
            ExtraBroadcastItem,
            Grid,
            GridItem,
            TabNavigation
        },
        computed: {
            hasLiveItems() {
                return this.liveItems.length;
            },
            liveItems() {
                return this.jsonData ? this.jsonData.liveItems || [] : [];
            },
            upcomingItems() {
                return this.jsonData ? this.jsonData.upcomingItems || [] : [];
            },
            upcomingDays() {
                return groupByDay(this.upcomingItems);
            }
        },
        methods: {
            reloadData() {
                httpClient.get('/extrabroadcasts').then(result => {
                    this.setData(result.data);
                });
            },
            setData(jsonData) {
                if (!jsonData) {
                    return;
                }
                this.jsonData = jsonData;
            }
        },
        created() {
            const propsData = JSON.parse(this.dataJson);
            this.setData(propsData);

            UPDATE_INTERVAL = setInterval(() => {
                this.reloadData();
            }, 60 * 1000);
        },
        destroyed() {
            clearInterval(UPDATE_INTERVAL);
        }
    };

    function groupByDay(items) {
        let i = 0,
            date,
            key,
            index,
            formattedDay,
            keys = [],
            result = [];
        for (; i < items.length; i++) {
            date = items[i].start;
            key = dateFormatter.isoShort(date);
            formattedDay = dateFormatter.relative(date);
            index = keys.indexOf(key);
            if (index > -1) {
                result[index].items.push(items[i]);
            } else {
                keys.push(key);
                result.push({ key: key, day: formattedDay, items: [items[i]] });
            }
        }
        return result;
    }
</script>

<style lang="scss" scoped>
    .extra-broadcasts {
        @include divider-top-medium;
    }
</style>
