




    import { Vue, Component, Prop } from 'vue-property-decorator';
    import UiOverline from '@/styleguide/elements/UiOverline.vue';

    @Component({
        components: {
            UiOverline
        }
    })
    export default class LocalKicker extends Vue {
        @Prop() title!: string;
        @Prop() theme!: string;
    }
