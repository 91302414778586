

















    import { Vue, Component, Prop } from 'vue-property-decorator';
    import keyCodes from '@/enums/key-codes';
    import Backdrop from '@/components/shared/Backdrop.vue';
    import UiCloseModal from '@/styleguide/icons/UiCloseModal.vue';
    import UiContainer from '@/styleguide/elements/UiContainer.vue';
    import FullpageSlide from '@/components/animations/FullpageSlide.vue';
    import keyboardTrapping from '@/directives/keyboard-trapping';
    import { ModalClosedEvent, ModalOpenedEvent } from '@/common/custom-events/modal-events';

    @Component({
        components: {
            UiCloseModal,
            UiContainer,
            Backdrop,
            FullpageSlide
        },
        directives: {
            keyboardTrapping
        }
    })
    export default class Modal extends Vue {
        @Prop(Boolean) readonly narrow: boolean | undefined;
        @Prop(Boolean) readonly darkContent: boolean | undefined;
        @Prop(Boolean) readonly lightBackdrop: boolean | undefined;

        scrollPositionBeforeMounted: number = 0;
        isModalContentVisible = false;

        get white(): boolean {
            return !this.darkContent;
        }

        close(): void {
            this.$emit('closeModal');
        }

        keyHandler(event: KeyboardEvent): void {
            if (event.key === keyCodes.escape) {
                this.close();
            }
        }

        created(): void {
            this.scrollPositionBeforeMounted = window.pageYOffset;
            window.dispatchEvent(new ModalOpenedEvent());
            document.addEventListener('keydown', this.keyHandler);
        }

        mounted(): void {
            this.isModalContentVisible = true;
            window.scrollTo(window.scrollX, 0);
            (this.$refs.closeButton as HTMLElement).focus();
        }

        destroyed(): void {
            window.dispatchEvent(new ModalClosedEvent());
            this.$nextTick().then(() => {
                window.scrollTo(window.scrollX, this.scrollPositionBeforeMounted);
            });
            document.removeEventListener('keydown', this.keyHandler);
        }
    }
