






    import { Vue, Component } from 'vue-property-decorator';
    import UiSection from './UiSection.vue';

    @Component({
        components: {
            UiSection
        }
    })
    export default class UiPage extends Vue {}
