import spaNavigationApi from '@/api/spa-navigation-api';
import IDomScript from '@/modules/i-dom-script';
import { SpaSelector } from '@/spa-navigation/spa-selectors';
import spaPageRender from '@/spa-navigation/spa-page-render';
import { DomUpdatedEvent } from '@/common/custom-events/dom-updated-event';
import { ShowMoreClickedEvent } from '@/common/custom-events/show-more-clicked-event';
import { TrackingMetadata } from '@/tracking/track-types';
import { getMetadata } from '@/common/tracking-metadata';

export default class ShowMoreEpisodesGrid implements IDomScript {
    private el: HTMLElement;
    private fetchMoreUrl: string;
    private gridId: string;
    private isLoading = false;
    private metadata: TrackingMetadata;

    public constructor(el: HTMLElement) {
        this.el = el;
        this.fetchMoreUrl = el.getAttribute('data-showmore-url') ?? '';
        const targetId = el.getAttribute('data-showmore-grid-id');
        this.gridId = `#${targetId}`;

        if (!this.fetchMoreUrl || !targetId) {
            this.remove();
        }
        this.metadata = getMetadata(this.el);
    }

    private async fetchAndAppendItems(): Promise<void> {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        try {
            this.changeButtonText();

            const response = await spaNavigationApi.getHtml(this.fetchMoreUrl);

            if (response.status == 204) {
                this.remove();
                return;
            }

            const fragment = document.createDocumentFragment();

            const incomingDocument = response.html;

            spaPageRender.removeNoscript(incomingDocument);

            incomingDocument.querySelectorAll(SpaSelector.TeaserItem).forEach(el => fragment.appendChild(el));

            document.querySelector(this.gridId)?.appendChild(fragment);

            this.remove();
            window.dispatchEvent(new DomUpdatedEvent());
        } catch (error) {
            this.isLoading = false;
            throw error;
        } finally {
            window.dispatchEvent(new ShowMoreClickedEvent(this.metadata));
        }
    }

    private changeButtonText(): void {
        const button = this.el.querySelector('button');

        if (button) {
            button.innerText = 'Hämtar...';
        }
    }

    private remove(): void {
        this.dispose();
        spaPageRender.removeElement(this.el);
    }

    public execute(): void {
        this.el.addEventListener('click', this.fetchAndAppendItems.bind(this));
    }

    public dispose(): void {
        this.el.removeEventListener('click', this.fetchAndAppendItems.bind(this));
    }
}
