import { customEventInit, NoPayload } from '../custom-events-common';

export class PlayerCollapsedEvent extends CustomEvent<NoPayload> {
    public static EventName = 'player-collapsed' as const;

    constructor() {
        super(PlayerCollapsedEvent.EventName, customEventInit());
    }
}

declare global {
    interface WindowEventMap {
        [PlayerCollapsedEvent.EventName]: PlayerCollapsedEvent;
    }
}
