






    import { Vue, Component, Prop } from 'vue-property-decorator';
    import UiHeading from './UiHeading.vue';

    @Component({
        components: {
            UiHeading
        }
    })
    export default class UiPageHeader extends Vue {
        @Prop(String) readonly title: string | undefined;
    }
