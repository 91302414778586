









    import { Vue, Component, Prop } from 'vue-property-decorator';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';

    @Component({
        components: {
            UiCaption
        }
    })
    export default class Checkbox extends Vue {
        @Prop() private readonly value!: boolean;
        @Prop() private readonly inputValue!: boolean;
        @Prop({ default: false }) private readonly disabled!: boolean;

        private get model(): boolean {
            return this.value;
        }
        private set model(value: boolean) {
            this.$emit('input', value);
        }
    }
