






    import { Vue, Component } from 'vue-property-decorator';
    import SoundBar from '@/components/sound-bars/SoundBar.vue';

    @Component({
        components: {
            SoundBar
        }
    })
    export default class SoundBarsAnimation extends Vue {}
