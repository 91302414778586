import { ShareButtonClickedEvent } from '@/common/custom-events/share-button-clicked-event';
import IDomScript from '@/modules/i-dom-script';
import { getMetadata } from '../common/tracking-metadata';
import { TrackingMetadata } from '../tracking/track-types';

export default class ShareButton implements IDomScript {
    private el: HTMLElement;
    private url: string;
    private onClick: EventListener;
    private readonly metadata: TrackingMetadata = {};

    public constructor(el: HTMLElement) {
        this.el = el;
        this.url = this.el.getAttribute('data-form-url') || '';
        this.metadata = getMetadata(this.el);

        this.onClick = (): void => {
            window.dispatchEvent(new ShareButtonClickedEvent(this.url, this.metadata));
        };
    }

    public execute(): void {
        this.el.addEventListener('click', this.onClick);
    }

    public dispose(): void {
        this.el.removeEventListener('click', this.onClick);
    }
}
