import { Vue, Component } from 'vue-property-decorator';
import { Channel } from '@/api/channels-api';
import { ScheduleItem } from '@/api/schedule-api';

@Component
export default class ChannelsMixin extends Vue {
    get $_primaryChannels(): Channel[] {
        return this.$store.getters['channels/primaryChannels'];
    }

    get $_localChannels(): Channel[] {
        return this.$store.getters['channels/localChannels'];
    }

    get $_additionalChannels(): Channel[] {
        return this.$store.getters['channels/additionalChannels'];
    }

    get $_localChannel(): Channel {
        return this.$store.getters['channels/selectedLocalChannel'];
    }

    $_setLocalChannel(channelId: number): void {
        this.$store.dispatch('channels/setLocalChannel', channelId);
    }

    get $_additionalChannel(): Channel | null {
        return this.$store.getters['channels/selectedAdditionalChannel'];
    }

    $_setAdditionalChannel(channelId: number): void {
        this.$store.dispatch('channels/setAdditionalChannel', channelId);
    }

    $_startPollingPrimarySchedules(): void {
        this.$store.dispatch('channels/startPollingPrimaryChannels');
    }

    $_startPollingAllChannels(): void {
        this.$store.dispatch('channels/startPollingAllChannels');
    }

    $_getSchedule(channelId: number): ScheduleItem[] {
        return this.$store.getters['channels/schedule'](channelId) || [];
    }

    $_stopPollingSchedules(): void {
        this.$store.dispatch('channels/unmount');
    }

    $_getCurrentScheduleItem(channelId: number): ScheduleItem | undefined {
        return this.$_getSchedule(channelId).find(item => {
            const start = new Date(item.start).getTime();
            const end = new Date(item.end).getTime();
            return start <= this.$_timeEpoch && end > this.$_timeEpoch;
        });
    }

    $_getNextScheduleItem(channelId: number): ScheduleItem | undefined {
        return this.$_getSchedule(channelId).find(item => {
            const start = new Date(item.start).getTime();
            return start > this.$_timeEpoch;
        });
    }

    private get $_timeEpoch(): number {
        return this.$store.getters['timeEpoch'];
    }

    $store: any;
}
