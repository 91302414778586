import siteSettings from '@/common/site-settings';

export default class EmbedMessageListener {
    private static instance: EmbedMessageListener;

    private constructor() {
        const url = siteSettings.embedUrl;
        window.addEventListener('message', event => this.receiveMessage(event, url), false);
    }

    public static init(): EmbedMessageListener {
        if (!EmbedMessageListener.instance) {
            EmbedMessageListener.instance = new EmbedMessageListener();
        }
        return EmbedMessageListener.instance;
    }

    private receiveMessage(event: MessageEvent, embedUrl: string): void {
        const origin = event.origin + '/';
        const data: Data = event.data;

        if (origin.indexOf(embedUrl) < 0 || !data.id) {
            return;
        }

        if (data.type === 'resize' && data.height) {
            this.setHeight(data.height, data.id);
            event.source?.postMessage({ type: 'resize-received' }, { targetOrigin: embedUrl });
        }

        if (data.type === 'player' && data.audioId && data.audioType) {
            this.playAudio(data.audioId, data.audioType);
        }
    }

    private setHeight(height: string, id: string): void {
        const iframes: NodeListOf<HTMLElement> = document.querySelectorAll(`[data-iframe-id="${id}"]`);

        if (!iframes || !iframes.length || !height || !id) {
            return;
        }

        iframes.forEach(i => (i.style.height = `${height}px`));
    }

    private playAudio(audioId: string, audioType: string): void {
        if (!audioId || !audioType || !window.sr || !window.sr.player) {
            return;
        }

        window.sr.player.play({ id: audioId, type: audioType.toLowerCase() });
    }
}

type Data = {
    id: string;
    height: string;
    audioId: string;
    audioType: string;
    type: 'resize' | 'player';
};
