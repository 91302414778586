






















    import { Vue, Component, Prop } from 'vue-property-decorator';
    import storeSearchItemToStorage from '@/components/main-menu/storeSearchItemToStorage';
    import UiOverline from '@/styleguide/elements/UiOverline.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import dateFormatter from '@/common/date-formatter';
    import { SearchResponseItem } from '@/api/search-api';
    import { consentService } from '@/common/consent-service';
    import { SearchResultClickedEvent } from '@/common/custom-events/search/search-result-clicked-event';
    import { SearchResultType } from '@/components/models/SearchResultType';

    @Component({
        components: {
            UiOverline,
            UiCaption
        }
    })
    export default class MainMenuSearchItem extends Vue {
        @Prop({ type: Object as () => SearchResponseItem, required: true }) result!: SearchResponseItem;
        @Prop(Boolean) readonly showHighlight: boolean | undefined;
        @Prop({ type: String }) term: string | undefined;
        @Prop({ required: true, type: String as () => SearchResultType }) trackingContext!: SearchResultType;

        onClick(): void {
            this.storeSearch();
            const label = this.term || '';
            window.dispatchEvent(new SearchResultClickedEvent(label, this.trackingContext));
        }

        get formattedDate(): string {
            return this.result.publishDate ? dateFormatter.calendar(this.result.publishDate) : '';
        }

        private storeSearch(): void {
            if (consentService.isSettingsAllowed()) {
                storeSearchItemToStorage(this.result);
            }
        }
    }
