import { customEventInit, NoPayload } from './custom-events-common';

export class ModalOpenedEvent extends CustomEvent<NoPayload> {
    public static EventName = 'modal-opened' as const;
    constructor() {
        super(ModalOpenedEvent.EventName, customEventInit());
    }
}

export class ModalClosedEvent extends CustomEvent<NoPayload> {
    public static EventName = 'modal-closed' as const;
    constructor() {
        super(ModalClosedEvent.EventName, customEventInit());
    }
}

declare global {
    interface WindowEventMap {
        [ModalOpenedEvent.EventName]: ModalOpenedEvent;
        [ModalClosedEvent.EventName]: ModalClosedEvent;
    }
}
