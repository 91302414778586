































    import { Vue, Component, Prop } from 'vue-property-decorator';
    import Modal from '@/components/shared/Modal.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import VideoBlob from '@/components/shared/VideoBlob.vue';
    import LazyImage from '@/components/shared/LazyImage.vue';
    import api, { SlideshowImageItem, SlideshowVideoItem } from '@/api/slideshow-api';
    import { SpaNavigationStartedEvent } from '@/common/custom-events/spa-navigation-started-event';
    import { SlideshowEvent } from '@/common/custom-events/slideshow-event';

    @Component({
        components: {
            Modal,
            UiCaption,
            VideoBlob,
            LazyImage
        }
    })
    export default class Slideshow extends Vue {
        @Prop(String) publicationId: string | undefined;
        @Prop(String) type: string | undefined;

        images: SlideshowImageItem[] = [];
        videos: SlideshowVideoItem[] = [];
        isOpen: boolean = false;
        languageDirection: string = '';

        get total(): number {
            return this.images.length;
        }

        get hasItems(): boolean {
            return this.hasImages || this.hasVideos;
        }

        get hasImages(): boolean {
            return !!this.images.length;
        }

        get hasVideos(): boolean {
            return !!this.videos.length;
        }

        get isRtl(): boolean {
            return this.languageDirection === 'rtl';
        }

        show(): void {
            this.isOpen = true;
        }

        open(event: SlideshowEvent): void {
            this.languageDirection = event.detail.languageDirection;
            if (event.detail.jsonData) {
                const images: SlideshowImageItem[] = JSON.parse(event.detail.jsonData);
                this.images = images;
                this.isOpen = true;
            } else {
                this.fetchFromApi(event);
            }
        }

        private fetchFromApi(event: SlideshowEvent): void {
            if (!event.detail.publicationId || !event.detail.type) {
                return;
            }

            api.getSlideshowItems({
                id: event.detail.publicationId,
                type: event.detail.type
            })
                .then(result => {
                    this.isOpen = true;
                    if (result.data.videos) {
                        this.videos = result.data.videos;
                    }
                    if (result.data.images) {
                        this.images = result.data.images;
                    }
                })
                .catch(() => {
                    this.clear();
                    this.isOpen = false;
                });
        }

        clear(): void {
            this.images = [];
            this.videos = [];
        }

        close(): void {
            this.isOpen = false;
            this.clear();
        }

        created(): void {
            window.addEventListener(SlideshowEvent.EventName, this.open);
            window.addEventListener(SpaNavigationStartedEvent.EventName, this.close);
        }

        destroyed(): void {
            window.removeEventListener(SlideshowEvent.EventName, this.open);
            window.removeEventListener(SpaNavigationStartedEvent.EventName, this.close);
        }
    }
