<template>
    <UiPage>
        <UiPageHeader :title="title" />
        <UiPageSection gutter v-if="item">
            <ExtraBroadcastItem :item="item" />
        </UiPageSection>
    </UiPage>
</template>

<script>
    import UiPage from '@/styleguide/elements/UiPage';
    import UiPageHeader from '@/styleguide/elements/UiPageHeader';
    import UiPageSection from '@/styleguide/elements/UiPageSection';
    import ExtraBroadcastItem from '@/components/extra-broadcasts/ExtraBroadcastItem';
    import dateFormatter from '@/common/date-formatter';

    export default {
        data() {
            return {
                jsonData: null
            };
        },
        props: {
            dataJson: String
        },
        components: {
            UiPage,
            UiPageHeader,
            UiPageSection,
            ExtraBroadcastItem
        },
        computed: {
            item() {
                return this.jsonData ? this.jsonData.item : null;
            },
            title() {
                const date = this.item ? dateFormatter.dayAndDate(this.item.start) : '';
                return `Extrasändning ${date}`;
            }
        },
        created() {
            this.jsonData = JSON.parse(this.dataJson);
        }
    };
</script>

<style lang="scss" scoped>
    header {
        @include divider-bottom-small;
    }
</style>
