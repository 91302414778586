import IDomScript from '@/modules/i-dom-script';

export default class Toggler implements IDomScript {
    private el: HTMLElement | null;
    private toggleElement: HTMLElement | null;
    private clickHandler: EventListener;

    public constructor(el: HTMLElement) {
        this.el = el;
        this.toggleElement = this.el.querySelector('.toggle-click');

        this.clickHandler = (event: Event): void => {
            if (!this.el || (event.target as HTMLElement)?.tagName?.toLowerCase() == 'a') {
                return;
            }

            const hiddenElements = this.el.querySelectorAll('.toggle-hidden');

            let i: number;
            for (i = 0; i < hiddenElements.length; i++) {
                hiddenElements[i].classList.toggle('is-expanded');
            }

            this.el.classList.toggle('is-expanded');
            this.el.classList.toggle('is-collapsed');
        };
    }

    public execute(): void {
        if (!this.toggleElement) {
            return;
        }

        this.toggleElement.addEventListener('click', this.clickHandler);
    }

    public dispose(): void {
        if (this.toggleElement) {
            this.toggleElement.removeEventListener('click', this.clickHandler);
        }

        this.el = null;
        this.toggleElement = null;
    }
}
