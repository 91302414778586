










    import { Vue, Component, Prop } from 'vue-property-decorator';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import Icon from '@/components/shared/Icon.vue';
    import Flex from '@/components/shared/Flex.vue';

    @Component({
        components: {
            UiCaption,
            Icon,
            Flex
        }
    })
    export default class ScrollToSettings extends Vue {
        private onClick(): void {
            this.$emit('click');
        }
    }
