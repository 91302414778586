

















    import { Mixins, Component } from 'vue-property-decorator';
    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import Flex from '@/components/shared/Flex.vue';
    import Hyphen from '@/components/shared/ascii/Hyphen.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import UiOverline from '@/styleguide/elements/UiOverline.vue';
    import Note from '@/components/shared/ascii/Note.vue';
    import Thumbnail from '@/components/shared/Thumbnail.vue';

    @Component({
        components: {
            Flex,
            Hyphen,
            UiCaption,
            Note,
            Thumbnail,
            UiOverline
        }
    })
    export default class PlayerMusic extends Mixins(MetadataMixin) {
        get albumArtUrl(): string {
            return this.currentTrack ? this.currentTrack.albumartUrl : '';
        }

        get artist(): string {
            return this.currentTrack ? this.currentTrack.artist : '';
        }

        get title(): string {
            return this.currentTrack ? this.currentTrack.title : '';
        }
    }
