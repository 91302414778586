import { render, staticRenderFns } from "./SoundBar.vue?vue&type=template&id=1b0854a9&scoped=true&"
import script from "./SoundBar.vue?vue&type=script&lang=ts&"
export * from "./SoundBar.vue?vue&type=script&lang=ts&"
import style0 from "./SoundBar.vue?vue&type=style&index=0&id=1b0854a9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b0854a9",
  null
  
)

export default component.exports