






















    import { Component, Mixins, Watch } from 'vue-property-decorator';
    import UiContainer from '@/styleguide/elements/UiContainer.vue';
    import PlayerCarouselPrevButton from '@/components/player/shared/PlayerCarouselPrevButton.vue';
    import PlayerCarouselNextButton from '@/components/player/shared/PlayerCarouselNextButton.vue';
    import PlayerCard, { PlayerCardMountedPayload } from '@/components/player/shared/PlayerCard.vue';
    import QueueProgress from '@/components/player/QueueProgress.vue';
    import anime from 'animejs';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import PlayerProgressMixin from '@/components/mixins/PlayerProgressMixin';
    import PlayerStateMixin from '@/components/mixins/PlayerStateMixin';
    import deviceDetection from '@/common/device-detection';

    @Component({
        components: {
            UiContainer,
            PlayerCard,
            QueueProgress,
            PlayerCarouselPrevButton,
            PlayerCarouselNextButton
        }
    })
    export default class PlayerCarousel extends Mixins(PlaybackMixin, MetadataMixin, PlayerProgressMixin, PlayerStateMixin) {
        private cardWidth: number = 0;
        private startTouch: Touch | null | undefined;
        private isInitialized = false;

        private get isSmartphoneOrTablet(): boolean {
            return deviceDetection.isSmartphoneOrTablet;
        }

        private onTouchstart(event: TouchEvent): void {
            this.startTouch = event.targetTouches.item(0);
        }

        private onTouchend(event: TouchEvent): void {
            const endTouch = event.changedTouches.item(0);

            if (!this.startTouch || !endTouch) {
                return;
            }

            const x = this.startTouch.clientX - endTouch.clientX;
            const y = this.startTouch.clientY - endTouch.clientY;
            this.startTouch = null;

            if (Math.abs(y) > 10) {
                return;
            }

            if (x > 2) {
                this.playNext();
            }

            if (x < -2) {
                this.playPrevious();
            }
        }

        onPlayerCardMounted(ev: PlayerCardMountedPayload): void {
            this.initCarousel(ev.width);
        }

        @Watch('currentQueuePosition')
        onCurrentQueuePositionUpdate(value: number): void {
            const translateX = -(value * this.cardWidth);
            anime({
                targets: this.$refs.carousel,
                translateX,
                duration: 500,
                easing: 'easeOutQuart'
            });
        }

        async initCarousel(childWidth: number): Promise<void> {
            if (this.isInitialized) {
                return;
            }

            if (this.isOndemandMode || this.isLiveMode) {
                this.isInitialized = true;
                await this.$nextTick();
                this.cardWidth = childWidth + 16;
                const translateX = -(this.currentQueuePosition * this.cardWidth);
                anime({
                    targets: this.$refs.carousel,
                    translateX,
                    duration: 0
                });
            }
        }
    }
