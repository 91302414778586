






    import { Mixins, Component, Prop } from 'vue-property-decorator';
    import Icon from '@/components/shared/Icon.vue';
    import AriaLabelsMixin from '@/components/mixins/AriaLabelsMixin';

    @Component({
        components: {
            Icon
        }
    })
    export default class PlayerExpand extends Mixins(AriaLabelsMixin) {
        @Prop(Boolean) active: boolean | undefined;
        isNavigatedOnTab = false;

        expand() {
            if (document.activeElement === this.el) {
                this.isNavigatedOnTab = true;
            }

            this.$emit('expand');
        }

        get el(): HTMLElement {
            return this.$refs.expandButton as HTMLElement;
        }

        updated() {
            if (this.isNavigatedOnTab) {
                this.el.focus();
            }

            this.isNavigatedOnTab = false;
        }
    }
