export function isVersionMismatch(incomingDocument: Document): boolean {
    const serverVersion = getVersionFromBody(incomingDocument);
    return isNewVersion(serverVersion);
}

function isNotSet(version: string | undefined | null): boolean {
    if (version === undefined || version === null || version === '') {
        return true;
    }

    return false;
}

function getVersionFromBody(doc: Document): string | null {
    return doc.body.getAttribute('data-version') || null;
}

function isNewVersion(serverVersion: string | null | undefined): boolean {
    if (isInvalidVersion(serverVersion)) {
        return false;
    }

    const clientVersion = getVersionFromBody(document);

    if (isInvalidVersion(clientVersion)) {
        return false;
    }

    return clientVersion !== serverVersion;
}

function isInvalidVersion(version: string | null | undefined): boolean {
    if (isNotSet(version)) {
        return true;
    }

    // This will protect us if build script is not setting
    // the version in web.config for dev/prod environments
    if (version === '1.0.0' || version === '1.0.0.0') {
        return true;
    }

    return false;
}
