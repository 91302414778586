




    import { Vue, Component, Prop } from 'vue-property-decorator';
    import NewsCardList from '@/components/shared/NewsCardList.vue';
    import { CardListModel } from '@/api/models';

    @Component({
        components: {
            NewsCardList
        }
    })
    export default class PublicationRelations extends Vue {
        @Prop(String) dataJson!: string;

        get model(): CardListModel {
            return JSON.parse(this.dataJson);
        }
    }
