import { consentService } from '@/common/consent-service';
import IDomScript from '@/modules/i-dom-script';

export default class SifoTracker implements IDomScript {
    public constructor(el: HTMLElement) {
        const channelName = el.getAttribute('data-orvesto-channel-name');

        if (!channelName || !consentService.isStatisticsAllowed()) {
            return;
        }

        this.track(channelName);
    }

    private track(channelName: string): void {
        window._cInfo = window._cInfo || [];
        if (document.cookie.match('__codnt') === null) {
            window._cInfo.push(
                { cmd: '_trackContentPath', val: '/' + encodeURIComponent(channelName) },
                { cmd: '_executeTracking' }
            );

            const sifoAnalyticsScriptTag = document.getElementById('sifo-analytics');

            if (!sifoAnalyticsScriptTag) {
                this.createSifoScriptTag();
            }
        }
    }

    private createSifoScriptTag(): void {
        const ca = document.createElement('script');
        ca.type = 'text/javascript';
        ca.id = 'sifo-analytics';
        ca.async = true;
        ca.src = 'https://analytics.codigo.se/j/43d1258f9a2d405980c8270ef7282644.js';
        const s = document.getElementsByTagName('script')[0];

        if (s && s.parentNode) {
            s.parentNode.insertBefore(ca, s);
        }
    }

    public execute(): void {}

    public dispose(): void {}
}
