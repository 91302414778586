export enum AriaLabels {
    PlayerExpand = 'Fäll ut spelaren',
    Volume = 'Justera volym',
    PlayerClose = 'Fäll ihop spelaren',
    Rewind = 'Backa 15 sekunder',
    Back = 'Föregående ljud',
    Skip = 'Nästa ljud',
    Forward = 'Spola fram 15 sekunder',
    Stop = 'Stoppa ljudet',
    CurrentPosition = 'Lyssnad tid',
    Duration = 'Ljudets längd',
    PlayPartial = 'Lyssna på ',
    Menu = 'Meny'
}
