export interface HistoryState {
    scroll: number;
    teaserFlow: string;
    title: string;
}

function pushState(url: string, title: string): void {
    history.pushState(null, title, url);
}

function saveTeaserFlow(teaserFlow: string): void {
    _updateHistoryState({ teaserFlow });
}

function saveScroll(scroll: number): void {
    _updateHistoryState({ scroll });
}

function _updateHistoryState(data: { teaserFlow?: string; title?: string; url?: string; scroll?: number }): void {
    const currentState: HistoryState = history.state ?? {};

    const state: HistoryState = {
        scroll: data.scroll ?? currentState.scroll ?? 0,
        teaserFlow: data.teaserFlow ?? currentState.teaserFlow ?? '',
        title: data.title ?? currentState.title ?? ''
    };

    const url = data.url ?? window.location.href; // cannot be undefined because of IE

    history.replaceState(state, state.title, url);
}

export default {
    pushState,
    saveTeaserFlow,
    saveScroll
};
