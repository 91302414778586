











    import { Mixins, Component, Prop } from 'vue-property-decorator';
    import Icon from '@/components/shared/Icon.vue';
    import AriaLabelsMixin from '@/components/mixins/AriaLabelsMixin';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';

    @Component({
        components: {
            Icon,
            UiCaption
        }
    })
    export default class MainMenuToggle extends Mixins(AriaLabelsMixin) {
        @Prop({ type: Boolean, required: true }) isMenuOpen!: boolean;

        onClick(): void {
            this.$emit('toggle');
        }

        public get title(): string {
            return this.isMenuOpen ? 'Stäng' : 'Meny';
        }
    }
