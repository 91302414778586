



































    import { Component, Prop, Mixins } from 'vue-property-decorator';
    import AudioButton from '@/components/shared/AudioButton.vue';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import UiOverline from '@/styleguide/elements/UiOverline.vue';
    import { NewsPlaylistModel } from '@/api/news-playlist-api';
    import LazyImage from '@/components/shared/LazyImage.vue';
    import Ellipsis from '@/components/shared/Ellipsis.vue';

    @Component({
        components: {
            AudioButton,
            UiHeading,
            UiCaption,
            UiOverline,
            LazyImage,
            Ellipsis
        }
    })
    export default class NewsPlaylistCard extends Mixins(PlaybackMixin) {
        @Prop() model!: NewsPlaylistModel;

        get picture(): PictureModel | null {
            if (!this.model?.images?.length) {
                return null;
            }

            const sources = this.model.images
                .sort((a, b) => a.width - b.width)
                .map(i => ({ url: i.url, media: this.getMediaQuery(i.width) }));

            const defaultImage = this.model.images.reduce((prev, current) => (prev.width > current.width ? prev : current));

            return {
                altText: this.model.playlistAltText,
                defaultUrl: defaultImage.url,
                sources: sources
            };
        }

        async onPlayButtonClick(): Promise<void> {
            this.playPlaylist();
            this.$emit('onPlayButtonClick', this.model.name);
        }

        onHeaderClick(): void {
            this.playPlaylist();
            this.$emit('onHeaderClick', this.model.name);
        }

        onImageClick(): void {
            this.playPlaylist();
            this.$emit('onImageClick', this.model.name);
        }

        async playPlaylist(): Promise<void> {
            await this.playQueue(this.model.playlist, 0, this.model.name);
        }

        getMediaQuery(width: number): string {
            if (width <= 512) {
                return '(max-width: 768px)';
            }

            return '(max-width: 10000px)';
        }
    }

    export interface PictureModel {
        altText: string;
        defaultUrl: string;
        sources: PictureSourceModel[];
    }

    export interface PictureSourceModel {
        url: string;
        media: string;
    }
