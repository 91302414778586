











    import { Vue, Component } from 'vue-property-decorator';
    import SitePlayer from '@/components/player/site-player.vue';
    import Share from '@/components/share/Share.vue';
    import Slideshow from '@/components/slideshow/Slideshow.vue';
    import SelectLocalChannel from '@/components/select-local-channel/SelectLocalChannel.vue';
    import SelectAdditionalChannel from '@/components/select-additional-channel/SelectAdditionalChannel.vue';
    import { RootAction } from '@/store/RootAction';
    import CookieConsent from '@/components/cookie-consent/CookieConsent.vue';

    @Component({
        components: {
            SitePlayer,
            Share,
            Slideshow,
            SelectLocalChannel,
            SelectAdditionalChannel,
            CookieConsent
        }
    })
    export default class SiteApp extends Vue {
        created(): void {
            this.$store.dispatch(RootAction.Init);
        }

        destroyed(): void {
            this.$store.dispatch(RootAction.Destroy);
        }
    }
