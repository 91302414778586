


























    import { Mixins, Component, Watch, Prop } from 'vue-property-decorator';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import { StoryCollectionModel, StoryModel } from '@/api/story-collection-api';
    import Carousel from '@/components/shared/Carousel.vue';
    import StoryCollectionCard from '@/components/story-collection/StoryCollectionCard.vue';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import Breakpoint from '@/common/breakpoint';
    import ScrollTracking from '@/modules/scroll-tracking';
    import { GoToPageEvent } from '@/common/custom-events/go-to-page-event';
    import { SwipedToLastEvent } from '@/common/custom-events/swiped-to-last-event';
    import { SwipedEvent } from '@/common/custom-events/swiped-event';

    @Component({
        components: {
            UiHeading,
            Carousel,
            StoryCollectionCard
        }
    })
    export default class StoryCollection extends Mixins(PlaybackMixin) {
        @Prop(String) dataJson!: string;
        protected storyCollection: StoryCollectionModel | null = null;
        ScrollTrackingInstance?: ScrollTracking;

        private get heading(): string {
            return this.storyCollection?.title || '';
        }

        private get items(): StoryModel[] {
            return this.storyCollection?.items || [];
        }

        private get itemRows(): StoryModel[][] {
            if (this.items.length === 0) {
                return [];
            }

            if (this.items.length === 2 && Breakpoint.isWide()) {
                return [this.items];
            }

            const row1: StoryModel[] = [];
            const row2: StoryModel[] = [];

            this.items.forEach((item, index) => {
                if (index % 2 === 0) {
                    row1.push(item);
                } else {
                    row2.push(item);
                }
            });

            return row2.length === 0 ? [row1] : [row1, row2];
        }

        private async onClick(item: StoryModel): Promise<void> {
            await this.fetchAndPlayPlaylist(item.id);
        }

        private async onLinkClick(item: StoryModel): Promise<void> {
            if (!item) {
                return;
            }

            window.dispatchEvent(new GoToPageEvent(item.url));
        }

        async created(): Promise<void> {
            this.storyCollection = JSON.parse(this.dataJson);
        }

        get ready(): boolean {
            return !!this.storyCollection;
        }

        @Watch('ready')
        readyChanged(newValue: boolean): void {
            if (newValue) {
                this.ScrollTrackingInstance = new ScrollTracking(this.$refs.collection as HTMLElement);
                this.ScrollTrackingInstance.execute();
            }
        }

        beforeDestroy(): void {
            if (this.ScrollTrackingInstance) {
                this.ScrollTrackingInstance?.dispose();
            }
        }

        trackSwipeStart(): void {
            window.dispatchEvent(new SwipedEvent('storyplaylist'));
        }

        trackSwipeEnd(): void {
            window.dispatchEvent(new SwipedToLastEvent('storyplaylist'));
        }
    }
