



















































    import { Mixins, Component } from 'vue-property-decorator';

    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import UiContainer from '@/styleguide/elements/UiContainer.vue';
    import UiLiveLabel from '@/styleguide/elements/UiLiveLabel.vue';
    import Flex from '@/components/shared/Flex.vue';
    import ProgressBarOndemand from '@/components/player/progress-bar/ProgressBarOndemand.vue';
    import ProgressBarLive from '@/components/player/progress-bar/ProgressBarLive.vue';
    import PlayerExpand from '@/components/player/PlayerExpand.vue';
    import Hyphen from '@/components/shared/ascii/Hyphen.vue';
    import HyphenationPoint from '@/components/shared/ascii/HyphenationPoint.vue';
    import Ellipsis from '@/components/shared/Ellipsis.vue';
    import PlayerBarMeta from '@/components/player/PlayerBarMeta.vue';
    import PlayerBarControls from '@/components/player/PlayerBarControls.vue';
    import PlayerAudioVolume from '@/components/player/PlayerAudioVolume.vue';
    import Skeleton from '@/components/shared/Skeleton.vue';
    import StatusMessage from '@/components/shared/StatusMessage.vue';
    import PlayerMessage from '@/components/shared/PlayerMessage.vue';
    import BrandedHeading from '@/components/shared/branding/BrandedHeading.vue';
    import Thumbnail from '@/components/shared/Thumbnail.vue';

    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import PlayerProgressMixin from '@/components/mixins/PlayerProgressMixin';
    import PlayerStateMixin from '@/components/mixins/PlayerStateMixin';

    import { PlayerTabIndexes } from '@/enums/tab-index';
    import deviceDetection from '@/common/device-detection';
    import { EmitEvents } from '@/enums/EmitEvents';

    @Component({
        components: {
            UiContainer,
            UiCaption,
            UiLiveLabel,
            Flex,
            Hyphen,
            HyphenationPoint,
            ProgressBarOndemand,
            Ellipsis,
            PlayerExpand,
            PlayerBarControls,
            PlayerAudioVolume,
            Skeleton,
            PlayerBarMeta,
            StatusMessage,
            PlayerMessage,
            BrandedHeading,
            Thumbnail,
            ProgressBarLive
        }
    })
    export default class PlayerBar extends Mixins(MetadataMixin, PlaybackMixin, PlayerProgressMixin, PlayerStateMixin) {
        onExpand(): void {
            this.$emit(EmitEvents.Expand);
        }

        get tabIndex(): number {
            return this.queue.length < 1 ? PlayerTabIndexes.playberBarEmpty : PlayerTabIndexes.playerBar;
        }

        get heading(): string {
            return this.isLiveMode ? this.currentAudio.channelName || '' : this.currentAudio.programName;
        }

        get subtitle(): string {
            return this.isLiveMode ? this.currentAudio.programName : this.currentAudio.episodeTitle;
        }

        get isSmartphoneOrTablet(): boolean {
            return deviceDetection.isSmartphoneOrTablet;
        }

        get hasPlayerMessage(): boolean {
            return !!this.playerMessage;
        }
    }
