<template>
    <abbr :aria-label="ariaLabel">{{ time }}</abbr>
</template>

<script>
    export default {
        props: {
            time: String,
            prefix: String
        },
        computed: {
            ariaLabel() {
                return this.prefix + this.time;
            }
        }
    };
</script>

<style lang="scss" scoped>
    abbr {
        font-weight: normal;
    }
</style>
