import httpClient from '@/common/http-client';
import { DomUpdatedEvent } from '@/common/custom-events/dom-updated-event';

export default {
    get(options: DomAjaxOptions): void {
        httpClient
            .get<string>(options.url, options.params)
            .then(response => {
                options.success(response.data, response.status);
                window.dispatchEvent(new DomUpdatedEvent());
            })
            .catch(reason => {
                if (options.error) {
                    options.error(reason);
                }
            });
    }
};

type DomAjaxOptions = {
    url: string;
    params?: any;
    success: (data: string, status?: number) => void;
    error?: (reason?: any) => void;
};
