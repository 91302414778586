import httpClient from '@/common/http-client';
import { AudioKey } from '@/components/models/AudioKey';

export default {
    async getNewsPlaylists(params: GetNewsPlaylistsRequest): Promise<NewsPlaylistModel[]> {
        const url = 'backend-for-frontend/news';
        const response = await httpClient.get<NewsPlaylistModel[]>(url, params);
        return response.data;
    }
};

type GetNewsPlaylistsRequest = {
    localChannelId: number;
};

export interface PlaylistImage {
    url: string;
    width: number;
    height: number;
}

export interface NewsPlaylistModel {
    id: string;
    name: string;
    count: number;
    overline: string;
    title: string;
    playlistAltText: string;
    images: PlaylistImage[];
    playlist: AudioKey[];
}
