<template>
    <link-icon :title="title" :url="url">
        <Icon name="external" />
    </link-icon>
</template>

<script>
    import linkIcon from '@/components/shared/link-icon';
    import Icon from '@/components/shared/Icon';

    export default {
        components: {
            linkIcon,
            Icon
        },
        props: {
            url: {
                type: String
            },
            title: {
                type: String
            }
        }
    };
</script>
