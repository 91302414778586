


































    import { Mixins, Component } from 'vue-property-decorator';
    import UiPage from '@/styleguide/elements/UiPage.vue';
    import UiPageSection from '@/styleguide/elements/UiPageSection.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiSection from '@/styleguide/elements/UiSection.vue';
    import ChannelItem from '@/components/all-channels/ChannelItem.vue';
    import Grid from '@/components/shared/Grid.vue';
    import GridItem from '@/components/shared/GridItem.vue';
    import TabNavigation from '@/components/shared/TabNavigation.vue';
    import channelsMixin from '@/components/mixins/ChannelsMixin';
    import { Channel } from '@/api/channels-api';

    @Component({
        components: {
            UiPage,
            UiPageSection,
            UiHeading,
            UiSection,
            ChannelItem,
            Grid,
            GridItem,
            TabNavigation
        }
    })
    export default class AllChannels extends Mixins(channelsMixin) {
        navigationItems = [
            {
                title: 'Kanaler',
                url: '/kanaler',
                active: true
            },
            {
                title: 'Extrasändningar',
                url: '/extrasandningar'
            }
        ];

        get primaryChannels(): Channel[] {
            return this.$_localChannel ? [...this.$_primaryChannels, this.$_localChannel] : this.$_primaryChannels;
        }

        get additionalChannels(): Channel[] {
            return this.$_additionalChannels;
        }

        get localChannels(): Channel[] {
            return this.$_localChannels;
        }

        get hasChannels(): boolean {
            return !!this.$_primaryChannels.length || !!this.$_additionalChannels.length || !!this.$_localChannels.length;
        }

        created() {
            this.$_startPollingAllChannels();
        }

        destroyed() {
            this.$_stopPollingSchedules();
        }

        showChangeLocal(type: string) {
            return type === 'local';
        }
    }
