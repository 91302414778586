import { customEventInit, NoPayload } from './custom-events-common';

export class SpaNavigationStartedEvent extends CustomEvent<NoPayload> {
    public static EventName = 'spa-navigation-started' as const;
    constructor() {
        super(SpaNavigationStartedEvent.EventName, customEventInit());
    }
}

declare global {
    interface WindowEventMap {
        [SpaNavigationStartedEvent.EventName]: SpaNavigationStartedEvent;
    }
}
