







    import { Vue, Component, Prop } from 'vue-property-decorator';
    import MainMenuNarrow from '@/components/main-menu/MainMenuNarrow.vue';
    import MainMenuWide from '@/components/main-menu/MainMenuWide.vue';
    import { env } from '@/common/env';
    import { PageChangedEvent } from '@/common/custom-events/page-changed-event';

    @Component({
        components: {
            MainMenuNarrow,
            MainMenuWide
        }
    })
    export default class MainMenu extends Vue {
        @Prop() private readonly path!: string;

        private clientSidePath: string | null = env.isClient() ? window.location.pathname : null;

        get currentPath(): string {
            return this.clientSidePath ? this.clientSidePath : this.path;
        }

        onPageNavigation(): void {
            this.clientSidePath = window.location.pathname;
        }

        mounted(): void {
            window.addEventListener(PageChangedEvent.EventName, this.onPageNavigation);
        }

        destroyed(): void {
            window.removeEventListener(PageChangedEvent.EventName, this.onPageNavigation);
        }
    }
