import { LegacyPlayerAction } from '@/enums/LegacyPlayerAction';
import { AudioActions, AudioGetters } from '@/store';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PlayerVolumeMixin extends Vue {
    protected volumeDown(): number {
        return (this.volume = this.volume - 10);
    }
    protected volumeUp(): number {
        return (this.volume = this.volume + 10);
    }

    protected get volume(): number {
        return this.$store.getters[AudioGetters.Volume];
    }

    protected set volume(volume: number) {
        if (volume === undefined) {
            volume = 100;
        }
        if (volume > 100) {
            volume = 100;
        }
        if (volume < 0) {
            volume = 0;
        }

        this.$store.dispatch(AudioActions.SetVolume, volume);
        this.$store.dispatch(LegacyPlayerAction.Volume, volume);
    }

    $store: any;
}
