



















    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import { LatestNewsApi } from '@/api/latest-news-api';
    import Carousel from '@/components/shared/Carousel.vue';
    import { Mixins, Component, Watch, Prop } from 'vue-property-decorator';
    import { CardModel } from '@/api/models';
    import EpisodeCard from '@/components/shared/EpisodeCard.vue';
    import { AudioKey } from '../models';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import LocalChannelMixin from '@/components/mixins/LocalChannelMixin';
    import { GoToPageEvent } from '@/common/custom-events/go-to-page-event';
    import type { TrackingMetadata } from '@/tracking/track-types';
    import { SwipedToLastEvent } from '@/common/custom-events/swiped-to-last-event';
    import { SwipedEvent } from '@/common/custom-events/swiped-event';
    import { ModuleClickedEvent } from '@/common/custom-events/module-clicked-event';

    @Component({
        components: {
            EpisodeCard,
            UiHeading,
            Carousel
        }
    })
    export default class LatestNewsEpisodes extends Mixins(PlaybackMixin, LocalChannelMixin) {
        @Prop(String) private readonly dataTitle!: string;
        episodes: CardModel[] = [];

        get ready(): boolean {
            return !!this.episodes.length;
        }

        get playlist(): AudioKey[] {
            if (!this.episodes) {
                return [];
            }

            return this.episodes
                .filter(item => !!item.audioId && item.audioId > 0)
                .map(item => ({
                    id: item.id,
                    type: item.audioType,
                    publicationId: item.id
                }));
        }

        @Watch('localChannelId')
        private async fetchData(): Promise<void> {
            const episodes = await LatestNewsApi.getEpisodes({
                localChannelId: this.localChannelId
            });
            this.episodes = episodes?.items || [];
        }

        onPlayAudio(item: CardModel): void {
            const index = this.playlist.findIndex(o => o.id === item.id);

            if (index < 0) {
                return;
            }

            this.playQueue(this.playlist, index, this.dataTitle);
        }

        protected onCardClick(item: CardModel): void {
            window.dispatchEvent(new GoToPageEvent(item.url));
            const trackingMetaData = this.trackingMetadata(item);

            window.dispatchEvent(
                new ModuleClickedEvent(
                    'karusell',
                    'senaste nyhetssändningarna',
                    trackingMetaData.programName ?? '',
                    trackingMetaData.programId ?? '',
                    trackingMetaData.category ?? '',
                    ''
                )
            );
        }

        trackingMetadata(item: CardModel): TrackingMetadata {
            return {
                channelName: item.metadata?.channelName,
                channelId: item.metadata?.channelId,
                programName: item.metadata?.programName,
                programId: item.metadata?.programId,
                episodeName: item.title,
                episodeId: item.metadata?.episodeId
            };
        }

        onSwipeStart(): void {
            window.dispatchEvent(new SwipedEvent('senaste nyhetssändningarna'));
        }

        onSwipeEnd(): void {
            window.dispatchEvent(new SwipedToLastEvent('senaste nyhetssändningarna'));
        }

        async created(): Promise<void> {
            await this.fetchData();
        }
    }
