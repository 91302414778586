export function getPropertiesFromAttributes(element: Element): Record<string, string> {
    const obj: Record<string, string> = {};

    for (let i = 0; i < element.attributes.length; i++) {
        const attribute = element.attributes[i];
        const value = attribute.value || '';
        obj[attribute.name] = value;
    }

    return obj;
}

export function nextTick(func: () => void): void {
    setTimeout(() => {
        func();
    }, 0);
}
