
















    import { Vue, Component, Prop } from 'vue-property-decorator';
    import AudioHeading from '@/components/shared/audio-heading.vue';
    import Thumbnail from '@/components/shared/Thumbnail.vue';

    @Component({
        components: {
            AudioHeading,
            Thumbnail
        }
    })
    export default class CompactCard extends Vue {
        @Prop(String) thumbnailUrl!: string;
        @Prop(Number) audioId!: number;
        @Prop(String) audioType!: string;
        @Prop(String) audioTitle!: string;
        @Prop(String) url!: string;
        @Prop(String) startTime!: string;
        @Prop(String) endTime!: string;
        @Prop({ type: Boolean, default: true }) hasAudio!: boolean;
    }
