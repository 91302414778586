






    import { Vue, Component, Prop } from 'vue-property-decorator';
    import Flex from '@/components/shared/Flex.vue';
    import anime from 'animejs';

    type LoadingCircle = {
        theme: string;
    };

    @Component({
        components: {
            Flex
        }
    })
    export default class LoadingIcon extends Vue {
        @Prop(Boolean) readonly isLoading!: boolean;

        circles: LoadingCircle[] = [
            {
                theme: 'th-p1-bg-color'
            },
            {
                theme: 'th-p2-bg-color'
            },
            {
                theme: 'th-p3-bg-color'
            },
            {
                theme: 'th-p4-bg-color'
            }
        ];

        mounted(): void {
            anime({
                targets: '.circle',
                translateY: [25, 0],
                opacity: [0, 1],
                delay: anime.stagger(100),
                loop: true
            });
        }

        enter(el: HTMLElement, doneFunc: (anim: anime.AnimeInstance) => void): void {
            anime({
                targets: el,
                opacity: [0, 1],
                padding: [0, 16],
                height: [0, 8],
                duration: 100,
                easing: 'easeOutQuad',
                complete: doneFunc
            });
        }
    }
