














    import LocalNews from './local-news.vue';
    import LocalWeather from './local-weather.vue';
    import LocalTraffic from './local-traffic.vue';
    import { Vue, Component } from 'vue-property-decorator';
    import { Channel } from '@/api/channels-api';

    @Component({
        components: {
            LocalNews,
            LocalWeather,
            LocalTraffic
        }
    })
    export default class LocalModule extends Vue {
        private shouldRenderWeather: boolean = false;

        get selectedLocalChannel(): Channel | null {
            return this.$store.getters['channels/selectedLocalChannel'];
        }
        get localChannelId(): string | null {
            return this.selectedLocalChannel ? String(this.selectedLocalChannel.id) : null;
        }
        get localChannelUrl(): string | null {
            return this.selectedLocalChannel ? this.selectedLocalChannel.url : null;
        }
        get localChannelName(): string | null {
            return this.selectedLocalChannel ? this.selectedLocalChannel.title : null;
        }

        onWeatherReady(): void {
            this.shouldRenderWeather = true;
        }
    }
