









    import { Component, Mixins } from 'vue-property-decorator';
    import SelectChannelModal from '@/components/select-channel-modal/SelectChannelModal.vue';
    import { ChannelOption } from '@/components/select-channel-modal/models';
    import Modal from '@/components/shared/Modal.vue';
    import ChannelsMixin from '@/components/mixins/ChannelsMixin';
    import { GoToPageEvent } from '@/common/custom-events/go-to-page-event';
    import { consentService } from '@/common/consent-service';
    import { ShowCookieConsentEvent } from '@/common/custom-events/show-cookie-consent-event';
    import { ChangeLocalChannelEvent } from '@/common/custom-events/select-channel/change-local-channel-event';

    @Component({
        components: {
            Modal,
            SelectChannelModal
        }
    })
    export default class SelectLocalChannel extends Mixins(ChannelsMixin) {
        isOpen: boolean = false;
        shouldRedirect: boolean = false;

        get localChannels(): ChannelOption[] {
            return this.$_localChannels;
        }

        get selectedChannelId(): number | null {
            return this.$_localChannel?.id;
        }

        changeChannel(channel: ChannelOption): void {
            this.$_setLocalChannel(channel.id);

            if (this.shouldRedirect) {
                window.dispatchEvent(new GoToPageEvent(channel.url));
            }
            this.close();
        }

        open(event: ChangeLocalChannelEvent): void {
            if (!consentService.isSettingsAllowed()) {
                window.dispatchEvent(new ShowCookieConsentEvent('select-local-channel'));
                return;
            }

            this.isOpen = true;
            this.shouldRedirect = event.detail.redirect || false;
        }

        close(): void {
            this.isOpen = false;
        }

        created(): void {
            window.addEventListener(ChangeLocalChannelEvent.EventName, this.open);
        }

        destroyed(): void {
            window.addEventListener(ChangeLocalChannelEvent.EventName, this.open);
        }
    }
