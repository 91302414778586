export default class Breakpoint {
    public static isFullWidth(clientWidth?: number): boolean {
        const w = clientWidth || Breakpoint.clientWidth;
        return w >= Breakpoint.fullWidth;
    }

    public static isWide(clientWidth?: number): boolean {
        const w = clientWidth || Breakpoint.clientWidth;
        return w >= Breakpoint.breakpoint;
    }

    public static isNarrow(clientWidth?: number): boolean {
        const w = clientWidth || Breakpoint.clientWidth;
        return w < Breakpoint.breakpoint;
    }

    private static get breakpoint(): number {
        return 768 - Breakpoint.scrollbarWidth;
    }

    private static get fullWidth(): number {
        return 1005 - Breakpoint.scrollbarWidth;
    }

    private static get scrollbarWidth(): number {
        const clientWidth = Breakpoint.clientWidth;
        if (!clientWidth) {
            return 0;
        }
        return window.innerWidth - clientWidth;
    }

    private static get clientWidth(): number {
        return document.documentElement.clientWidth;
    }
}
