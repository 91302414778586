import IDomScript from '@/modules/i-dom-script';
import breakpoint from '@/common/breakpoint';

export default class CollapsibleMenu implements IDomScript {
    private el: HTMLElement;
    private activeLink: HTMLElement | null;

    public constructor(el: HTMLElement) {
        this.el = el;
        this.activeLink = this.el.querySelector('.collapsible-menu__item--active > a');
    }

    private clickHandler(event: MouseEvent): void {
        if (breakpoint.isNarrow() || breakpoint.isWide()) {
            event.preventDefault();
            event.stopPropagation();
            this.el.classList.toggle('is-collapsed');
            this.el.classList.toggle('is-expanded');
        }
    }

    public execute(): void {
        this.activeLink?.addEventListener('click', this.clickHandler.bind(this), false);
    }

    public dispose(): void {
        this.activeLink?.removeEventListener('click', this.clickHandler.bind(this), false);
    }
}
