import { LogData } from '@/logging/LogTypes';
import { SrError } from '@/logging/SrError';
import { throttledErrorLogger } from '@/logging/ThrottledErrorLogger';

export function registerErrorLoggingEventListeners(): void {
    window.addEventListener('unhandledrejection', unhandledRejectionEventListener);
    window.addEventListener('error', errorEventListener);
}

function unhandledRejectionEventListener(event: PromiseRejectionEvent): void {
    const data = getLogData(event.reason, event.reason.toString());
    throttledErrorLogger.logError(data);
}

function errorEventListener(event: ErrorEvent): void {
    const data = getLogData(event.error, event.message);
    throttledErrorLogger.logError(data);
}

export function vueErrorHandler(err: Error, vm: Vue, info: string): void {
    const componentName = (vm?.$options as any)?._componentTag;
    throttledErrorLogger.logError({
        message: err?.message,
        source: `VueComponent: ${componentName}, ${info}`,
        error: err
    });

    throw err;
}

function getLogData(error: Error, message?: string): LogData {
    if (error instanceof SrError) {
        const data = error.data;
        data.error = error;
        return data;
    }

    return {
        message: message || error.message,
        error: error
    };
}
