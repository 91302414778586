






















































    import { Mixins, Component, Prop } from 'vue-property-decorator';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiLinkInternal from '@/styleguide/elements/UiLinkInternal.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import CompactChannelItem from '@/components/all-channels/CompactChannelItem.vue';
    import ChangeChannelButton from '@/components/channels/ChangeChannelButton.vue';
    import { Channel } from '@/api/channels-api';
    import SvgImage from '@/components/shared/SvgImage.vue';
    import { ScheduleItem } from '@/api/schedule-api';
    import LiveProgressbar from '@/components/shared/LiveProgressbar.vue';
    import Thumbnail from '@/components/shared/Thumbnail.vue';
    import TimeAbbr from '@/components/shared/TimeAbbr.vue';
    import ChannelIcon from '@/components/channels/ChannelIcon.vue';
    import { LegacyPlayerAction } from '@/enums/LegacyPlayerAction';
    import ChannelsMixin from '@/components/mixins/ChannelsMixin';
    import { ChangeLocalChannelEvent } from '@/common/custom-events/select-channel/change-local-channel-event';
    import { ChangeAdditionalChannelEvent } from '@/common/custom-events/select-channel/change-additional-channel-event';
    import { ScheduleClickedEvent } from '@/common/custom-events/schedule-clicked-event';
    import type { TrackingMetadata } from '@/tracking/track-types';

    @Component({
        components: {
            UiHeading,
            UiCaption,
            UiLinkInternal,
            CompactChannelItem,
            SvgImage,
            LiveProgressbar,
            Thumbnail,
            TimeAbbr,
            ChannelIcon,
            ChangeChannelButton
        }
    })
    export default class ChannelCard extends Mixins(ChannelsMixin) {
        @Prop() channel!: Channel;
        @Prop() schedule!: ScheduleItem[];

        get isPrimaryChannel(): boolean {
            const priorityChannelIds = this.$_primaryChannels.map(c => c.id);
            return priorityChannelIds.includes(this.channel.id);
        }

        get isP4(): boolean {
            return this.channel.type === 'local';
        }

        get isAdditional(): boolean {
            return this.channel.type === 'additional';
        }

        get title(): string {
            return this.currentScheduleItem?.title ?? '';
        }

        get nextTitle(): string {
            return this.nextScheduleItem?.title ?? '';
        }

        get startTime(): string {
            return this.currentScheduleItem?.startTimeFormatted ?? '';
        }

        get endTime(): string {
            return this.currentScheduleItem?.endTimeFormatted ?? '';
        }

        get startTimeEpoch(): number {
            return this.currentScheduleItem ? new Date(this.currentScheduleItem.start).getTime() : 0;
        }

        get endTimeEpoch(): number {
            return this.currentScheduleItem ? new Date(this.currentScheduleItem.end).getTime() : 0;
        }

        get scheduleItems(): ScheduleItem[] {
            return this.$_getSchedule(this.channel.id);
        }

        get currentScheduleItem(): ScheduleItem | undefined {
            return this.$_getCurrentScheduleItem(this.channel.id);
        }

        get nextScheduleItem(): ScheduleItem | undefined {
            return this.$_getNextScheduleItem(this.channel.id);
        }

        get trackingMetadata(): TrackingMetadata {
            return {
                channelId: this.channel.id.toString(),
                channelName: this.channel.title
            };
        }

        onChangeLocalChannel(): void {
            window.dispatchEvent(new ChangeLocalChannelEvent('karusell'));
        }

        onChangeAdditionalChannel(): void {
            window.dispatchEvent(new ChangeAdditionalChannelEvent('karusell'));
        }

        onStartAudio(): void {
            this.$store.dispatch(LegacyPlayerAction.PlayOrPause, { id: this.channel.id, type: this.channel.audioType });
        }

        trackSchedule(): void {
            window.dispatchEvent(new ScheduleClickedEvent(this.channel.title, this.channel.id.toString()));
        }
    }
