import httpClient from '@/common/http-client';

export default {
    getLatestWeatherReport() {
        const url = '/ajax/latestweatherreport';
        return httpClient.get<WeatherReport>(url);
    },
    getLocalWeather(params: LocalWeatherRequest) {
        const url = '/ajax/localweather';
        return httpClient.get<WeatherItem[]>(url, params);
    }
};

export type WeatherReport = {
    audioType: string;
    broadCastEnd: string;
    broadCastStart: string;
    hasAudio: boolean;
    id: number;
    imageUrl: string;
    title: string;
    url: string;
};

type LocalWeatherRequest = {
    localId: string;
};

export type WeatherItem = {
    locality: string;
    temperature: string;
    weather: Weather;
    timeOfDay: TimeOfDay;
    time: string;
    wind: WeatherItemWind;
};

type WeatherItemWind = {
    direction: WindDirection;
    speed: string;
    gusts: string;
};

export type WindDirection =
    | 'S'
    | 'SV'
    | 'V'
    | 'NV'
    | 'N'
    | 'NO'
    | 'O'
    | 'SO'
    | 'north'
    | 'northWest'
    | 'west'
    | 'southWest'
    | 'south'
    | 'southEast'
    | 'east'
    | 'northEast';

type TimeOfDay = 'day' | 'night';

type Weather =
    | 'undefined'
    | 'clearSky'
    | 'nearlyClearSky'
    | 'variableCloudiness'
    | 'halfclearSky'
    | 'cloudySky'
    | 'overcast'
    | 'fog'
    | 'lightRainShowers'
    | 'moderateRainShowers'
    | 'heavyRainShowers'
    | 'thunderstorm'
    | 'lightSleetShowers'
    | 'moderateSleetShowers'
    | 'heavySleetShowers'
    | 'lightSnowShowers'
    | 'moderateSnowShowers'
    | 'heavySnowShowers'
    | 'lightRain'
    | 'moderateRain'
    | 'heavyRain'
    | 'thunder'
    | 'lightSleet'
    | 'moderateSleet'
    | 'heavySleet'
    | 'lightSnowfall'
    | 'moderateSnowfall'
    | 'heavySnowfall';
