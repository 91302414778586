















    import { Vue, Component, Prop } from 'vue-property-decorator';
    import UiHeading from './UiHeading.vue';
    import UiLinkInternal from './UiLinkInternal.vue';
    import UiLiveLabel from './UiLiveLabel.vue';

    @Component({
        components: {
            UiHeading,
            UiLiveLabel,
            UiLinkInternal
        }
    })
    export default class UiSectionHeader extends Vue {
        @Prop(String) readonly title: string | undefined;
        @Prop(String) readonly url: string | undefined;
        @Prop(String) readonly metaLinkTitle: string | undefined;
        @Prop(String) readonly metaLinkUrl: string | undefined;
        @Prop(Boolean) readonly capitalize: boolean | undefined;
        @Prop(Boolean) readonly compact: boolean | undefined;
        @Prop(Boolean) readonly noBorder: boolean | undefined;
    }
