










    import { Vue, Component, Prop } from 'vue-property-decorator';
    import SearchResult from '@/components/search/SearchResult.vue';
    import SearchApi, { SearchResponseItem } from '@/api/search-api';
    import { SearchResultType } from '@/components/models/SearchResultType';

    @Component({
        components: {
            SearchResult
        }
    })
    export default class SearchPrograms extends Vue {
        @Prop(String) readonly term!: string;

        total: number = 0;
        result: SearchResponseItem[] = [];
        page: number = 1;
        take: number = 8;

        get hasMoreItems(): boolean {
            return this.result.length < this.total;
        }

        get hasResults(): boolean {
            return this.result.length > 0;
        }

        get searchResultType(): typeof SearchResultType {
            return SearchResultType;
        }

        onFetchMore(): void {
            this.page += 1;
            this.fetch();
        }

        created(): void {
            this.fetch();
        }

        fetch(): void {
            SearchApi.getPrograms({ query: this.term, page: this.page, take: this.take }).then(response => {
                this.result = this.result.concat(response.data.hits);
                this.total = response.data.totalHits;
            });
        }
    }
