




















    import { Mixins, Component, Watch } from 'vue-property-decorator';
    import Carousel from '@/components/shared/Carousel.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import playlistApi, { NewsPlaylistModel } from '@/api/news-playlist-api';
    import LocalChannelMixin from '@/components/mixins/LocalChannelMixin';
    import NewsPlaylistCard from '@/components/shared/cards/NewsPlaylistCard.vue';
    import PlaybackMixin from '../mixins/PlaybackMixin';
    import { SwipedToLastEvent } from '@/common/custom-events/swiped-to-last-event';
    import { SwipedEvent } from '@/common/custom-events/swiped-event';
    import { ModuleStartedEvent } from '@/common/custom-events/module-started-event';

    @Component({
        components: {
            UiHeading,
            UiCaption,
            Carousel,
            NewsPlaylistCard
        }
    })
    export default class NewsPlaylistCarousel extends Mixins(LocalChannelMixin, PlaybackMixin) {
        protected playlists: NewsPlaylistModel[] = emptyData;
        protected fetchFailed: boolean = false;
        moduleName: string = 'nyhetskarusellen';

        trackSwipeStart(): void {
            window.dispatchEvent(new SwipedEvent(this.moduleName));
        }

        trackSwipeEnd(): void {
            window.dispatchEvent(new SwipedToLastEvent(this.moduleName));
        }

        async onImageClick(collectionName: string): Promise<void> {
            window.dispatchEvent(new ModuleStartedEvent('image', this.moduleName, collectionName));
        }

        async onHeaderClick(collectionName: string): Promise<void> {
            window.dispatchEvent(new ModuleStartedEvent('header', this.moduleName, collectionName));
        }

        async onPlayButtonClick(collectionName: string): Promise<void> {
            window.dispatchEvent(new ModuleStartedEvent('play-button', this.moduleName, collectionName));
        }

        @Watch('localChannelId')
        private async fetchData(): Promise<void> {
            try {
                this.playlists = await playlistApi.getNewsPlaylists({
                    localChannelId: this.localChannelId
                });
                this.fetchFailed = false;
            } catch (err) {
                this.fetchFailed = true;
            }
        }

        async created(): Promise<void> {
            await this.fetchData();
        }
    }

    const emptyNewsPlaylistModel = (index: number): NewsPlaylistModel => ({
        id: String(index),
        name: '',
        count: 0,
        overline: '',
        title: '',
        playlistAltText: '',
        images: [],
        playlist: []
    });

    const emptyData = Array.from({ length: 5 }, (value, index) => emptyNewsPlaylistModel(index));
