




    import { Component, Prop, Mixins } from 'vue-property-decorator';
    import AudioButton from '@/components/shared/AudioButton.vue';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';

    @Component({
        components: {
            AudioButton
        }
    })
    export default class StoryAudioButton extends Mixins(PlaybackMixin) {
        @Prop(String) dataStoryId!: string;
        @Prop(String) dataStoryTitle!: string;

        async onPlayButtonClick(): Promise<void> {
            if (this.dataStoryId) {
                await this.fetchAndPlayPlaylist(this.dataStoryId);
            }
        }
    }
