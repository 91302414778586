














    import { Vue, Component, Prop } from 'vue-property-decorator';
    import SearchResult from '@/components/search/SearchResult.vue';
    import SearchApi, { SearchResponseItem } from '@/api/search-api';
    import { SearchResultType } from '@/components/models/SearchResultType';

    type SearchInterval = {
        text: string;
        value: string;
    };

    @Component({
        components: {
            SearchResult
        }
    })
    export default class SearchContent extends Vue {
        @Prop(String) readonly term!: string;
        @Prop(String) readonly interval!: string;

        selectedInterval: string = 'anytime';

        intervals: SearchInterval[] = [
            { text: 'När som helst', value: 'anytime' },
            { text: 'Senaste dygnet', value: 'day' },
            { text: 'Senaste månaden', value: 'month' },
            { text: 'Senaste året', value: 'year' },
            { text: 'Äldre än ett år', value: 'olderThanYear' }
        ];
        total: number = 0;
        result: SearchResponseItem[] = [];
        skip: number = 0;
        take: number = 8;

        get hasMoreItems(): boolean {
            return this.result.length < this.total;
        }

        get hasResults(): boolean {
            return this.result.length > 0;
        }

        get searchResultType(): typeof SearchResultType {
            return SearchResultType;
        }

        onFetchMore(): void {
            this.skip += this.take;
            this.fetch();
        }

        created(): void {
            this.fetch();
        }

        onIntervalChange(): void {
            this.result = [];
            this.skip = 0;
            this.fetch();
        }

        fetch(): void {
            SearchApi.getContent({ query: this.term, take: this.take, skip: this.skip, interval: this.selectedInterval }).then(
                response => {
                    this.result = this.result.concat(response.data.hits);
                    this.total = response.data.totalHits;
                }
            );
        }
    }
