import { customEventInit } from './custom-events-common';

export type SlideshowEventPayload = { publicationId?: string; type?: string; languageDirection: string; jsonData?: string };

export class SlideshowEvent extends CustomEvent<SlideshowEventPayload> {
    public static EventName = 'slideshow' as const;

    constructor(payload: SlideshowEventPayload) {
        super(
            SlideshowEvent.EventName,
            customEventInit({
                publicationId: payload.publicationId,
                type: payload.type,
                languageDirection: payload.languageDirection,
                jsonData: payload.jsonData
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [SlideshowEvent.EventName]: SlideshowEvent;
    }
}
