<template>
    <a class="link-icon" :href="url">
        <span class="link-icon__title">
            {{ title }}
        </span>
        <slot></slot>
    </a>
</template>

<script>
    export default {
        props: {
            url: {
                type: String
            },
            title: {
                type: String
            }
        }
    };
</script>

<style lang="scss" scoped>
    .link-icon {
        @include link-style;
        @include flex-center;
        @include cell-padding;
        @include border-top;
    }

    .link-icon__title {
        @include divider-right-base;
    }
</style>
