













    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { MainMenuLinkItem } from '@/components/main-menu/models';
    import { getPrimaryLinks } from '@/components/main-menu/mainMenuLinks';
    import { MenuClickedEvent } from '@/common/custom-events/menu-clicked-event';

    @Component
    export default class PrimaryLinks extends Vue {
        @Prop() private readonly path!: string;

        get linkCollection(): MainMenuLinkItem[] {
            return getPrimaryLinks();
        }

        onLinkClick(linkTitle: string): void {
            window.dispatchEvent(new MenuClickedEvent(linkTitle));
        }

        isActive(path: string): boolean {
            return path === this.path || this.path.startsWith(`${path}/`);
        }
    }
