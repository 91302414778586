


























    import { Vue, Component, Prop } from 'vue-property-decorator';
    import SvgImage from '@/components/shared/SvgImage.vue';
    import HyphenationPoint from '@/components/shared/ascii/HyphenationPoint.vue';

    interface CollaborationIconsModel {
        isSvtCollaboration: boolean;
        isRadioHjalpenCollaboration: boolean;
        isSvtBarnkanalCollaboration: boolean;
    }

    @Component({
        components: {
            SvgImage,
            HyphenationPoint
        }
    })
    export default class CollaborationIcons extends Vue {
        @Prop() private readonly dataJson!: string;
        private model: CollaborationIconsModel | null = null;

        get hasSvtCollaboration(): boolean {
            return this.model?.isSvtCollaboration ?? false;
        }

        get hasRadioHjalpenCollaboration(): boolean {
            return this.model?.isRadioHjalpenCollaboration ?? false;
        }

        get hasSvtBarnkanalCollaboration(): boolean {
            return this.model?.isSvtBarnkanalCollaboration ?? false;
        }

        created(): void {
            this.model = JSON.parse(this.dataJson);
        }
    }
