import userState from '@/common/user-state.js';
import IDomScript from '@/modules/i-dom-script';

export default class P4Redirect implements IDomScript {
    private static p4Redirect(isCurrentUrlp4: boolean): void {
        userState.localChannel.get((data: LocalChannelData) => {
            if (!isCurrentUrlp4) {
                return;
            }

            window.location.href = data.url;
        });
    }

    private get isCurrentUrlP4(): boolean {
        const currentUrl = window.location.href.toLowerCase();
        return currentUrl.endsWith('/p4') || currentUrl.endsWith('/p4/');
    }

    public execute(): void {
        P4Redirect.p4Redirect(this.isCurrentUrlP4);
    }

    public dispose(): void {}
}

type LocalChannelData = {
    id: number | string;
    url: string;
};
