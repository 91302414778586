import { render, staticRenderFns } from "./UiCloseModal.vue?vue&type=template&id=6c0cf413&scoped=true&"
import script from "./UiCloseModal.vue?vue&type=script&lang=ts&"
export * from "./UiCloseModal.vue?vue&type=script&lang=ts&"
import style0 from "./UiCloseModal.vue?vue&type=style&index=0&id=6c0cf413&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c0cf413",
  null
  
)

/* custom blocks */
import block0 from "./UiCloseModal.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

export default component.exports