import userState from '@/common/user-state';

function LocalContent(el) {
    this.el = el;
    this.setLocalContent();
}

LocalContent.prototype.setLocalContent = function () {
    var self = this;

    var callback = function (channel) {
        var links = [...self.el.querySelectorAll('.local-url')],
            titles = [...self.el.querySelectorAll('.local-title')],
            localChannels = [...self.el.querySelectorAll('[data-is-local-channel="true"]')];

        links.forEach(function (link) {
            link.setAttribute('href', channel.url);
        });
        titles.forEach(function (title) {
            title.innerText = 'P4 ' + channel.name;
        });
        localChannels.forEach(function (localChannel) {
            localChannel.setAttribute('href', '#');
            localChannel.setAttribute('title', channel.name + ', lyssna direkt');
            localChannel.setAttribute('data-audio-id', channel.id);
        });
    };

    userState.localChannel.get(callback);
};

LocalContent.prototype.init = function () {
    return new LocalContent(this);
};

export default {
    init: LocalContent.prototype.init
};
