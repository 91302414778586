







    import { Component, Prop, Vue } from 'vue-property-decorator';
    import SvgImage from '@/components/shared/SvgImage.vue';
    import { Channel } from '@/api/channels-api';

    @Component({
        components: {
            SvgImage
        }
    })
    export default class ChannelIcon extends Vue {
        @Prop() channel!: Channel;
    }
