import historyHandler from '@/spa-navigation/history-handler';
import spaPageRender from '@/spa-navigation/spa-page-render';
import spaPageTracker from '@/spa-navigation/spa-page-tracker';
import { isVersionMismatch } from '@/spa-navigation/version-handler';
import spaNavigationApi from '@/api/spa-navigation-api';
import { throttledErrorLogger } from '@/logging/ThrottledErrorLogger';
import { urlState } from '@/spa-navigation/url-state';
import linkEvaluator from '@/common/link-evaluator';
import { DomUpdatedEvent } from '@/common/custom-events/dom-updated-event';
import { PageChangedEvent } from '@/common/custom-events/page-changed-event';
import { SpaNavigationStartedEvent } from '@/common/custom-events/spa-navigation-started-event';
import { GoToPageEvent } from '@/common/custom-events/go-to-page-event';
import { PageRenderedEvent } from '@/common/custom-events/page-rendered-event';

export async function gotopageEventListener(event: GoToPageEvent): Promise<void> {
    const url = event.detail.url;

    try {
        if (!url) {
            return;
        }

        window.dispatchEvent(new SpaNavigationStartedEvent());

        saveScroll();

        const res = await spaNavigationApi.getHtml(url);

        if (isVersionMismatch(res.html) || res.xPoweredByNextJs) {
            hardReload(url);
            return;
        }

        spaPageRender.renderPage(res.html);
        window.dispatchEvent(new PageRenderedEvent(res.html));

        const resultUrl = res.redirectedToUrl || url;
        urlState.saveUrl(resultUrl);

        historyHandler.pushState(resultUrl, document.title);
        scroll(url);
        saveScroll();

        window.dispatchEvent(new DomUpdatedEvent());
        window.dispatchEvent(new PageChangedEvent(res.html));
        spaPageTracker.track();
    } catch (error) {
        throttledErrorLogger.logError({ message: 'Spa-navigation failed', error: error });
        hardReload(url);
    }
}

function saveScroll(): void {
    historyHandler.saveScroll(window.pageYOffset);
}

function scroll(url: string): void {
    const anchor = getAnchor(url);

    if (anchor) {
        anchor.scrollIntoView();
    } else {
        window.scrollTo(0, 0);
    }
}

function getAnchor(url: string): HTMLElement | null {
    const anchor = linkEvaluator.getFragmentIdentifier(url);
    const id = anchor?.replace('#', '');

    if (!id) {
        return null;
    }

    return document.getElementById(id);
}

function hardReload(url: string): void {
    window.location.href = url;
}
