







    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { SocialLinkModel } from '@/components/share/models';
    import SvgImage from '@/components/shared/SvgImage.vue';
    import { TrackingMetadata } from '@/tracking/track-types';
    import { ShareEvent } from '@/common/custom-events/share-event';

    @Component({
        components: {
            SvgImage
        }
    })
    export default class ShareItem extends Vue {
        @Prop(Object) readonly model!: SocialLinkModel;
        @Prop(String) readonly resourceTitle!: string;
        @Prop(String) readonly resourceUrl!: string;
        @Prop(Boolean) readonly isUseStartTimeActive!: boolean;
        @Prop(Object) readonly trackingMetadata!: TrackingMetadata;

        get href(): string {
            if (!this.model?.url) {
                return '';
            }

            return this.model.url
                .replace('{text}', encodeURIComponent(this.resourceTitle))
                .replace('{url}', encodeURIComponent(this.resourceUrl));
        }

        onClick(): void {
            window.dispatchEvent(new ShareEvent(this.trackingMetadata));
        }
    }
