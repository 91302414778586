




















    import { Mixins, Component, Prop, Vue } from 'vue-property-decorator';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiShowMoreButton from '@/styleguide/elements/UiShowMoreButton.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import UiOverline from '@/styleguide/elements/UiOverline.vue';
    import HyphenationPoint from '@/components/shared/ascii/HyphenationPoint.vue';
    import AudioButton from '@/components/shared/AudioButton.vue';
    import NewsCard from '@/components/shared/cards/NewsCard.vue';
    import LazyImage from '@/components/shared/LazyImage.vue';
    import Breakpoint from '@/common/breakpoint';
    import SvgMixin from '@/components/mixins/SvgMixin';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import { AudioKey } from '@/components/models/';
    import { CardModel } from '@/api/models';
    import { GoToPageEvent } from '@/common/custom-events/go-to-page-event';

    @Component({
        components: {
            UiHeading,
            UiShowMoreButton,
            UiCaption,
            UiOverline,
            HyphenationPoint,
            NewsCard,
            AudioButton,
            LazyImage
        }
    })
    export default class NewsCardList extends Mixins(SvgMixin, PlaybackMixin) {
        $refs!: Vue['$refs'] & { card: HTMLElement[] };

        isExpanded: boolean = false;

        @Prop(Array) list!: CardModel[];
        @Prop(String) title!: string;
        @Prop(String) url?: string;
        @Prop(Boolean) showPlaylistLength?: boolean;
        @Prop(Boolean) darkButton?: boolean;

        get cardsList(): CardModel[] {
            if (Breakpoint.isNarrow() || this.isExpanded) {
                return this.list;
            }
            return this.list.slice(0, 2);
        }

        get showMoreButton(): boolean {
            return this.list.length > 2 && !this.isExpanded && Breakpoint.isWide();
        }

        get audioKeys(): AudioKey[] {
            if (!this.list) {
                return [];
            }

            return this.list
                .filter(item => this.isValidAudio(item))
                .map(item => ({
                    id: item.id,
                    type: item.audioType,
                    publicationId: item.id
                }));
        }

        onPlayAudio(item: CardModel): void {
            const index = this.audioKeys.findIndex(o => o.id === item.id);

            if (index < 0) {
                return;
            }

            this.playQueue(this.audioKeys, index, this.title);
        }

        private onCardClick(item: CardModel): void {
            window.dispatchEvent(new GoToPageEvent(item.url));
        }

        private isValidAudio(card: CardModel): boolean {
            return !!card.audioId && card.audioId > 0;
        }

        async onShowMoreClick(): Promise<void> {
            this.$emit(NewsCardListEvent.ShowMoreClick);
            this.isExpanded = true;

            await this.$nextTick();

            const nextFocusableElement = this.$refs.card[2];
            nextFocusableElement.querySelector('button')?.focus();
        }
    }

    enum NewsCardListEvent {
        ShowMoreClick = 'showMoreClick',
        PlayAudio = 'playAudio',
        LinkClick = 'linkClick'
    }
