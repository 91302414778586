import httpClient from '@/common/http-client';
import { CardListModel } from './models';

export const RecommendationsApi = {
    async forEpisode(episodeId: string): Promise<CardListModel | null> {
        try {
            const url = `/backend-for-frontend/recommendations/forepisode/${episodeId}`;
            const response = await httpClient.get<CardListModel>(url);
            return response.data;
        } catch (err) {
            return null;
        }
    }
};
