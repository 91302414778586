import httpClient from '@/common/http-client';

export default {
    getSlideshowItems(params: SlideshowRequest) {
        const url = '/slideshow/items';
        return httpClient.get<SlideshowResponse>(url, params);
    }
};

type SlideshowRequest = {
    id: string;
    type: string;
};

type SlideshowResponse = {
    images: SlideshowImageItem[];
    videos: SlideshowVideoItem[];
};

export type SlideshowImageItem = {
    altText: string;
    caption: string;
    creativeCommonsUrl: string;
    photographer: string;
    url: string;
};

export type SlideshowVideoItem = {
    id: string;
    url: string;
};
