



























    import '@sverigesradio/stil/components/CardImagePortrait.lit';
    import SectionHeader from '@/components/shared/SectionHeader.vue';
    import Vue from 'vue';
    import Carousel from '@/components/shared/Carousel.vue';
    import { Program, programsApi } from '@/api/programs-api';
    import { SwipedEvent } from '@/common/custom-events/swiped-event';
    import { SwipedToLastEvent } from '@/common/custom-events/swiped-to-last-event';
    import { ModuleClickedEvent } from '@/common/custom-events/module-clicked-event';
    import ScrollTracking from '@/modules/scroll-tracking';

    export default Vue.extend({
        components: {
            Carousel,
            SectionHeader
        },

        created() {
            this.populatePremiumProgramCollection();
        },

        computed: {
            premiumProgramCollectionItems(): Program[] {
                return this.premiumProgramCollection.items;
            },
            showPremiumProgramCollection(): boolean {
                return this.premiumProgramCollection.items.length > 0;
            }
        },

        beforeDestroy() {
            this.scrollTrackingInstance?.dispose();
        },

        methods: {
            trackSwipeStart(): void {
                window.dispatchEvent(new SwipedEvent('utvalda_poddar'));
            },
            trackSwipeEnd(): void {
                window.dispatchEvent(new SwipedToLastEvent('utvalda_poddar'));
            },
            onCardClicked(program: Program): void {
                window.dispatchEvent(
                    new ModuleClickedEvent(
                        'karusell',
                        'utvalda_poddar',
                        program.title,
                        program.id.toString(),
                        program.category,
                        'utvalda poddar'
                    )
                );
            },
            initiateScrollTracking(): void {
                this.scrollTrackingInstance = new ScrollTracking(this.$refs.section as HTMLElement);
                this.scrollTrackingInstance.execute();
            },
            async populatePremiumProgramCollection() {
                const response = await programsApi.getPremiumProgramCollection();
                this.premiumProgramCollection.title = response.data.title;
                this.premiumProgramCollection.items = response.data.items;
            }
        },
        data() {
            return {
                premiumProgramCollection: {
                    title: '',
                    items: [] as Program[]
                },
                scrollTrackingInstance: {} as ScrollTracking
            };
        },
        watch: {
            showPremiumProgramCollection(newValue: boolean) {
                if (newValue) {
                    this.initiateScrollTracking();
                }
            }
        }
    });
