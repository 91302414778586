




















    import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
    import Icon from '@/components/shared/Icon.vue';
    import anime from 'animejs';

    const CARD_PADDING = 8;
    const SCROLL_BUFFERT = 100;

    @Component({
        components: {
            Icon
        }
    })
    export default class Carousel extends Vue {
        @Prop({
            default: true,
            type: Boolean
        })
        navigationOutsideContainer!: boolean;

        $refs!: Vue['$refs'] & {
            wrapper: HTMLElement;
        };

        private scrollLeft = 0;
        private scrollRight = 0;
        private breakpointLeft = 0;
        private breakpointRight = 0;
        private containerWidth = 0;
        private cardWidth = 0;
        private swipeStartEmitted = false;
        private swipeEndEmitted = false;

        public get showBackButton(): boolean {
            return this.scrollLeft > SCROLL_BUFFERT;
        }

        public get showForwardButton(): boolean {
            return this.scrollRight > SCROLL_BUFFERT;
        }

        private get scrollDistance(): number {
            return Math.floor(this.containerWidth / this.cardWidth);
        }

        public onBackButton(): void {
            const fn = (item: Element): boolean => item.getBoundingClientRect().left - CARD_PADDING >= this.breakpointLeft;
            const targetCardIndex = this.getCardIndex(fn) - this.scrollDistance;
            const trimmedIndex = this.trimIndex(targetCardIndex);

            this.scrollTo(trimmedIndex * this.cardWidth);
        }

        public onForwardButton(): void {
            const fn = (item: Element): boolean => item.getBoundingClientRect().right > this.breakpointRight + CARD_PADDING;
            const targetCardIndex = this.getCardIndex(fn);
            const trimmedIndex = this.trimIndex(targetCardIndex);
            this.scrollTo(trimmedIndex * this.cardWidth);
        }

        private getCardIndex(fn: (item: Element) => boolean): number {
            const elements = this.getCards();
            const arr = elements ? Array.from(elements) : [];
            return arr.findIndex(fn);
        }

        private getCards(): HTMLCollection | null {
            return this.$refs.wrapper?.firstElementChild?.children ?? null;
        }

        private trimIndex(index: number): number {
            const cardLength = this.getCards()?.length ?? 0;

            if (index < 0 || cardLength <= this.scrollDistance) {
                return 0;
            }

            const highestPossibleTargetIndex = cardLength - this.scrollDistance;

            return index <= highestPossibleTargetIndex ? index : highestPossibleTargetIndex;
        }

        private scrollTo(scrollLeft: number): void {
            anime({
                targets: this.$refs.wrapper,
                scrollLeft,
                duration: 700,
                easing: 'easeOutQuart'
            });
        }

        @Watch('scrollLeft')
        public emitSwipeStart(scrollLeft: number): void {
            if (!this.swipeStartEmitted && !!scrollLeft) {
                this.$emit('swipeStart');
                this.swipeStartEmitted = true;
            }
        }

        @Watch('scrollRight')
        public emitSwipeEnd(scrollRight: number): void {
            if (!this.swipeEndEmitted && scrollRight < SCROLL_BUFFERT) {
                this.$emit('swipeEnd');
                this.swipeEndEmitted = true;
            }
        }

        private updateDOMRectValues(): void {
            const el = this.$refs.wrapper;

            if (!el) {
                return;
            }

            const rect = el.getBoundingClientRect();

            this.breakpointLeft = rect.left;
            this.breakpointRight = rect.right;
            this.containerWidth = rect.width;
            this.cardWidth = el.firstElementChild?.children[0]?.getBoundingClientRect().width ?? 0;
        }

        private updateScrollValues(): void {
            const el = this.$refs.wrapper;
            this.scrollLeft = el.scrollLeft;
            this.scrollRight = el.scrollWidth - el.scrollLeft - el.clientWidth;
        }

        mounted(): void {
            this.updateDOMRectValues();
            this.updateScrollValues();

            this.$refs.wrapper.addEventListener('scroll', this.updateScrollValues, { passive: true });
            window.addEventListener('resize', this.updateDOMRectValues, { passive: true });
        }

        unmounted(): void {
            this.$refs.wrapper.removeEventListener('scroll', this.updateScrollValues);
            window.removeEventListener('resize', this.updateDOMRectValues);
        }
    }
