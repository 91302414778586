import httpClient, { IHttpResponse } from '@/common/http-client';

export const programsApi = {
    getPremiumProgramCollection(): Promise<IHttpResponse<PremiumProgramCollectionResult>> {
        const url = '/backend-for-frontend/program-collection/premium';
        return httpClient.get<PremiumProgramCollectionResult>(url);
    },

    getMostStartedProgramCollection(id: number, take: number): Promise<IHttpResponse<MostStartedProgramCollectionResult>> {
        const url = `/backend-for-frontend/program-collections/category/${id}/most-started?take=${take}`;
        return httpClient.get<MostStartedProgramCollectionResult>(url);
    },

    getProgramByCategory(categoryId: number): Promise<IHttpResponse<ProgramByCategoryListResult>> {
        const url = `/backend-for-frontend/program/category/${categoryId}`;
        return httpClient.get<ProgramByCategoryListResult>(url);
    }
};

export type ProgramImage = {
    url: string;
    altText: string;
};

export type Program = {
    id: number;
    image: ProgramImage;
    title: string;
    description: string;
    url: string;
    category: string;
};

type PremiumProgramCollectionResult = {
    title: string;
    items: Program[];
};

type MostStartedProgramCollectionResult = {
    title: string;
    items: Program[];
};

type ProgramByCategoryListResult = {
    categoryName: string;
    firstCharacterGroupings: FirstCharacterGrouping[];
};

export type FirstCharacterGrouping = {
    firstCharacter: string;
    programs: Program[];
};
