import { TrackingMetadata } from '@/tracking/track-types';

export function getMetadata(el: Element): TrackingMetadata {
    const metadata: TrackingMetadata = {};

    const metadataId = el.getAttribute('data-stat-metadata-id');

    if (!metadataId) {
        return metadata;
    }

    const metadataEl = document.getElementById(metadataId);

    if (metadataEl) {
        const channelName = metadataEl.getAttribute('data-stat-channel-name');
        const channelId = metadataEl.getAttribute('data-stat-channel-id');
        const programName = metadataEl.getAttribute('data-stat-program-name');
        const programId = metadataEl.getAttribute('data-stat-program-id');
        const episodeName = metadataEl.getAttribute('data-stat-episode-name');
        const episodeId = metadataEl.getAttribute('data-stat-episode-id');
        const collectionName = metadataEl.getAttribute('data-stat-collection-name');
        const articleTitle = metadataEl.getAttribute('data-stat-article-title');
        const category = metadataEl.getAttribute('data-stat-category');
        const storyId = metadataEl.getAttribute('data-stat-story-id');
        const broadcastId = metadataEl.getAttribute('data-stat-broadcast-id');
        const broadcastName = metadataEl.getAttribute('data-stat-broadcast-name');

        if (channelName) {
            metadata.channelName = channelName;
        }

        if (channelId) {
            metadata.channelId = channelId;
        }

        if (programName) {
            metadata.programName = programName;
        }

        if (programId) {
            metadata.programId = programId;
        }

        if (episodeName) {
            metadata.episodeName = episodeName;
        }

        if (episodeId) {
            metadata.episodeId = episodeId;
        }

        if (collectionName) {
            metadata.collectionName = collectionName;
        }

        if (articleTitle) {
            metadata.articleTitle = articleTitle;
        }

        if (category) {
            metadata.category = category;
        }

        if (storyId) {
            metadata.storyId = storyId;
        }

        if (broadcastId) {
            metadata.broadcastId = broadcastId;
        }

        if (broadcastName) {
            metadata.broadcastName = broadcastName;
        }
    }

    return metadata;
}
