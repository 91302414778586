import { SearchResponseItem } from '@/api/search-api';
import Storage from '@/common/storage';

export default function storeSearchItemToStorage(searchResultItem: SearchResponseItem): void {
    let searches: SearchResponseItem[] = Storage.searchHistory || [];

    searches = searches.filter(item => item.url !== searchResultItem.url);
    searches.unshift(searchResultItem);
    Storage.searchHistory = searches.slice(0, 5);
}
