<template>
    <UiSection>
        <UiSectionHeader :title="title" compact />
        <CompactCard
            :thumbnailUrl="imageUrl"
            :audioId="audioId"
            :audioType="audioType"
            :audioTitle="audioTitle"
            :url="episodeUrl"
            :startTime="startTime"
            :endTime="endTime"
            :hasAudio="true">
        </CompactCard>
    </UiSection>
</template>

<script>
    import CompactCard from '@/components/shared/CompactCard';
    import UiSectionHeader from '@/styleguide/elements/UiSectionHeader';
    import UiSection from '@/styleguide/elements/UiSection';

    export default {
        components: {
            CompactCard,
            UiSectionHeader,
            UiSection
        },
        data() {
            return {
                episode: {}
            };
        },
        props: {
            dataJson: String,
            title: String
        },
        computed: {
            audioId() {
                return this.episode.episodeId;
            },
            audioType() {
                return this.episode.audioType;
            },
            audioTitle() {
                return this.episode.title;
            },
            episodeUrl() {
                return this.episode.url;
            },
            startTime() {
                return this.episode.startTime;
            },
            endTime() {
                return this.episode.endTime;
            },
            imageUrl() {
                return this.episode.imageUrl;
            }
        },
        created() {
            this.episode = JSON.parse(this.dataJson);
        }
    };
</script>
