






    import { Component, Vue } from 'vue-property-decorator';

    @Component
    export default class ChangeChannelButton extends Vue {
        onClick(): void {
            this.$emit('onClick');
        }
    }
