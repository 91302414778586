











    import { Vue, Component, Prop } from 'vue-property-decorator';

    import api, { ScheduleItem, Track } from '@/api/schedule-api';
    import UiSectionHeader from '@/styleguide/elements/UiSectionHeader.vue';
    import UiSection from '@/styleguide/elements/UiSection.vue';
    import ChannelItem from '@/components/all-channels/ChannelItem.vue';
    import UiLiveLabel from '@/styleguide/elements/UiLiveLabel.vue';

    let UPDATE_INTERVAL: number;
    const WITH_MUSIC_INTERVAL_SECONDS = 10;
    const WITHOUT_MUSIC_INTERVAL_SECONDS = 60;

    @Component({
        components: {
            UiSection,
            UiSectionHeader,
            ChannelItem,
            UiLiveLabel
        }
    })
    export default class SingleChannelModule extends Vue {
        @Prop(String) dataChannelId!: string;
        @Prop(String) dataTitle!: string;
        @Prop(String) dataShowMusic!: string;

        schedule: ScheduleItem[] = [];
        track: Track | null = null;

        get channel(): any {
            return this.$store.getters['channels/channel'](this.channelId);
        }
        get isReady(): boolean {
            return this.channelsReady;
        }
        get showMusic(): boolean {
            return this.dataShowMusic === 'true';
        }
        get channelsReady(): boolean {
            return this.$store.getters['channels/channelsReady'];
        }
        get channelId(): number {
            return Number(this.dataChannelId);
        }
        get updateInterval(): number {
            return this.showMusic ? WITH_MUSIC_INTERVAL_SECONDS : WITHOUT_MUSIC_INTERVAL_SECONDS;
        }

        loadSchedule(): void {
            if (this.showMusic) {
                this.loadWithTracks();
            } else {
                this.loadWithoutTracks();
            }
        }

        loadWithTracks(): void {
            api.getWithTracks({
                id: this.channelId
            }).then(response => {
                this.schedule = response.data.scheduleItems;
                this.track = response.data.track || null;
            });
        }

        loadWithoutTracks(): void {
            api.get({
                ids: [this.channelId]
            }).then(response => {
                this.schedule = response.data.schedules[0].scheduleItems;
            });
        }

        created(): void {
            this.loadSchedule();

            UPDATE_INTERVAL = window.setInterval(() => {
                this.loadSchedule();
            }, this.updateInterval * 1000);
        }

        destroyed(): void {
            clearInterval(UPDATE_INTERVAL);
            this.$store.dispatch('channels/unmount');
        }
    }
