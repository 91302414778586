










    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component({
        components: {
            UiHeading
        }
    })
    export default class TabNavigation extends Vue {
        @Prop() items!: any[];

        tag(active: boolean): string {
            return active ? 'h1' : 'h2';
        }
    }
