


































    import { Component, Mixins } from 'vue-property-decorator';
    import { CuratedApi } from '@/api/curated-api';
    import Carousel from '@/components/shared/Carousel.vue';
    import NewsPlaylistCard from '@/components/shared/cards/NewsPlaylistCard.vue';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import { NewsPlaylistModel } from '@/api/news-playlist-api';
    import { SwipedToLastEvent } from '@/common/custom-events/swiped-to-last-event';
    import { SwipedEvent } from '@/common/custom-events/swiped-event';
    import { ModuleStartedEvent } from '@/common/custom-events/module-started-event';

    @Component({
        components: {
            NewsPlaylistCard,
            Carousel
        }
    })
    export default class CuratedCarousel extends Mixins(PlaybackMixin) {
        curatedCollectionList: NewsPlaylistModel[] = [];
        moduleName: string = 'fördjupande nyhetslistor';

        private get showComponent(): boolean {
            return this.curatedCollectionList.length >= 1;
        }

        protected get useCarousel(): boolean {
            return this.curatedCollectionList.length >= 2;
        }

        async onImageClick(collectionName: string): Promise<void> {
            window.dispatchEvent(new ModuleStartedEvent('image', this.moduleName, collectionName));
        }

        async onHeaderClick(collectionName: string): Promise<void> {
            window.dispatchEvent(new ModuleStartedEvent('header', this.moduleName, collectionName));
        }

        async onPlayButtonClick(collectionName: string): Promise<void> {
            window.dispatchEvent(new ModuleStartedEvent('play-button', this.moduleName, collectionName));
        }

        onSwipeStart(): void {
            window.dispatchEvent(new SwipedEvent(this.moduleName));
        }

        onSwipeEnd(): void {
            window.dispatchEvent(new SwipedToLastEvent(this.moduleName));
        }

        async created(): Promise<void> {
            try {
                this.curatedCollectionList = await CuratedApi.getCuratedPlaylists();
            } catch {
                this.curatedCollectionList = [];
            }
        }
    }
