






    import { Vue, Component, Prop } from 'vue-property-decorator';
    @Component({})
    export default class Grid extends Vue {
        @Prop(Number) small: number | undefined;
        @Prop(Number) medium: number | undefined;
        @Prop(Number) large: number | undefined;
        @Prop(Boolean) readonly collapse: boolean | undefined;
        @Prop({ default: 'div' }) readonly tag!: string;

        get collapseCss(): string | undefined {
            return this.collapse ? 'grid--collapse' : undefined;
        }

        get smallColumnCss(): string | undefined {
            return this.small ? `grid--col-small-${this.small}` : undefined;
        }

        get mediumColumnCss(): string | undefined {
            return this.medium ? `grid--col-medium-${this.medium}` : undefined;
        }

        get largeColumnCss(): string | undefined {
            return this.large ? `grid--col-large-${this.large}` : undefined;
        }
    }
