<template>
    <div class="local-traffic-item">
        <span class="local-traffic-item__time">{{ item.time }}</span>
        <a class="local-traffic-item__link" :href="item.url">
            <span>{{ item.title }}</span>
        </a>
    </div>
</template>

<script>
    export default {
        props: {
            item: {
                type: Object,
                required: true
            }
        }
    };
</script>

<style lang="scss" scoped>
    .local-traffic-item {
        @include flex-center;
    }

    .local-traffic-item__time {
        @include divider-right-base;
        @include cell-padding-vertical;
        font-weight: normal;
    }

    .local-traffic-item__link {
        @include cell-padding-vertical;
        @include body-font-bold;
        display: block;
        flex: 0 1 auto;
    }
</style>
