








    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import SvgImage from '@/components/shared/SvgImage.vue';
    import AriaLabelsMixin from '@/components/mixins/AriaLabelsMixin';
    import PlayerStateMixin from '@/components/mixins/PlayerStateMixin';

    @Component({
        components: {
            SvgImage
        }
    })
    export default class PlayerCarouselNextButton extends Mixins(PlaybackMixin, MetadataMixin, AriaLabelsMixin, PlayerStateMixin) {
        @Prop(Number) tabIndex: number | undefined;

        get isDisabled(): boolean {
            if (this.isOndemandMode) {
                return !this.hasNextAudios;
            }
            if (this.isLiveMode) {
                if (!this.currentAudio || !this.currentAudio?.startTime || !this.currentAudio?.endTime) {
                    return true;
                }
                const currentTime = new Date();
                return this.currentAudio.startTime < currentTime && currentTime < this.currentAudio.endTime;
            }
            return true;
        }

        onClick(): void {
            this.playNext();
        }
    }
