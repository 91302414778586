




















    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { ShowCookieConsentEvent } from '@/common/custom-events/show-cookie-consent-event';

    @Component
    export default class FooterLinks extends Vue {
        @Prop(String) dataJson!: string;

        get model(): FooterModel {
            return JSON.parse(this.dataJson);
        }

        get cookiesUrl(): string {
            return `${this.model.cookiesUrl}#cookieconsent`;
        }

        private onCookieSettingsLinkClick(): void {
            window.dispatchEvent(new ShowCookieConsentEvent());
        }
    }

    interface FooterModel {
        aboutCompanyUrl: string;
        pressUrl: string;
        jobsUrl: string;
        blogUrl: string;
        contactUrl: string;
        frequenciesUrl: string;
        cookiesUrl: string;
        gdprUrl: string;
        supportUrl: string;
        faqUrl: string;
        aboutSiteUrl: string;
    }
