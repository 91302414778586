












    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class UiCloseModal extends Vue {
        @Prop({ default: '100%' }) readonly width!: string;
        @Prop({ default: '100%' }) readonly height!: string;
        @Prop(Boolean) readonly inverted: boolean | undefined;

        get css() {
            return {
                width: this.width,
                height: this.height
            };
        }

        get classes() {
            return {
                inverted: this.inverted
            };
        }
    }
