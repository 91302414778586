















    import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
    import timeHelper from '@/common/time-helper';
    import { secondsToTime } from '@/common/player-time-helper';

    @Component({})
    export default class ShareFromForm extends Vue {
        @Prop(String) shareFromPhrase: string | undefined;
        inputText: string = '';
        useStartTime: boolean = false;

        get startTime(): number {
            if (!this.useStartTime || !this.inputText || !timeHelper.validateTimeString(this.inputText)) {
                return 0;
            }

            const secondsInDay = 86400;
            const startTime = timeHelper.parseSeconds(this.inputText);

            return startTime < secondsInDay ? startTime : secondsInDay;
        }

        get isInputValid(): boolean {
            return !this.inputText || !!this.startTime;
        }

        onBlur(): void {
            if (this.startTime) {
                this.inputText = secondsToTime(this.startTime);
            }
        }

        @Watch('startTime')
        onStartTimeChange(newVal: number): void {
            this.$emit('timeChanged', newVal);
        }

        @Watch('useStartTime')
        onUseStartTimeChange(newVal: boolean): void {
            this.$emit('useStartTimeChanged', newVal);
        }
    }
