








    import Vue, { PropType } from 'vue';
    import '@sverigesradio/stil/components/stil-nav-top-bar.js';
    import '@sverigesradio/stil/components/stil-nav-item.js';

    interface NavItem {
        url: string;
        title: string;
    }

    export default Vue.extend({
        props: {
            navItems: {
                type: Array as PropType<NavItem[]>,
                required: true
            }
        },
        methods: {
            isActive(url: string): boolean {
                return location.pathname === url;
            }
        }
    });
