import ajaxloader from '@/modules/ajaxloader';
import clickableContent from '@/modules/clickable-content';
import collapsibleMenu from '@/modules/collapsible-menu';
import downloadButtonTracking from '@/modules/download-button-tracking';
import datePicker from '@/modules/date-picker';
import directModule from '@/modules/direct-module';
import focusTextSelector from '@/modules/focus-text-selector.js';
import localContent from '@/modules/local-content.js';
import localHeader from '@/modules/local-header';
import mergeDays from '@/modules/merge-days';
import modalHelper from '@/modules/modal-helper';
import openInPlay from '@/modules/open-in-play';
import p4Redirect from '@/modules/p4-redirect';
import playOnClick from '@/modules/play-on-click';
import removeElement from '@/modules/remove-element';
import scrollToTop from '@/modules/scroll-to-top';
import scrollTracking from '@/modules/scroll-tracking';
import shareButton from '@/modules/share-button';
import showMoreFlow from '@/modules/show-more-flow';
import showMoreEpisodesGrid from '@/modules/show-more-episodes-grid';
import showOnClick from '@/modules/show-on-click';
import sifoTracker from '@/modules/sifo-tracker';
import tablaUtskrift from '@/modules/tabla-utskrift';
import toggler from '@/modules/toggler';
import traffic from '@/modules/traffic';
import vma from '@/modules/vma';
import systemMessage from '@/modules/system-message';
import setStarttime from '@/modules/set-starttime';
import showIfStarttime from '@/modules/show-if-starttime';
import pageViewTracker from '@/modules/page-view-tracker';
import CategoryTagClickTracking from '@/modules/category-tag-click-tracking';
import moduleClickTracking from '@/modules/module-click-tracking';
import ShowAllTracking from '@/modules/show-all-tracking';

const modules: { [key: string]: any } = {
    'remove-element': removeElement,
    'local-content': localContent,
    'p4-redirect': p4Redirect,
    'play-on-click': playOnClick,
    'clickable-content': clickableContent,
    sifo: sifoTracker,
    'local-header': localHeader,
    'share-button': shareButton,
    'scroll-tracking': scrollTracking,
    'scroll-to-top': scrollToTop,
    'show-more-flow': showMoreFlow,
    toggler: toggler,
    ajaxloader: ajaxloader,
    'date-picker': datePicker,
    'collapsible-menu': collapsibleMenu,
    'tabla-utskrift': tablaUtskrift,
    'show-on-click': showOnClick,
    'direct-module': directModule,
    'open-in-play': openInPlay,
    'focus-text-selector': focusTextSelector,
    traffic: traffic,
    'merge-days': mergeDays,
    'download-button-tracking': downloadButtonTracking,
    'module-click-tracking': moduleClickTracking,
    'category-tag-click-tracking': CategoryTagClickTracking,
    vma: vma,
    'system-message': systemMessage,
    'modal-helper': modalHelper,
    'set-starttime': setStarttime,
    'show-if-starttime': showIfStarttime,
    'show-more-episodes-grid': showMoreEpisodesGrid,
    'page-view-tracker': pageViewTracker,
    'show-all-tracking': ShowAllTracking
};

export default {
    get(name: string) {
        const moduleName = name.replace('modules/', '');
        return modules[moduleName];
    }
};
