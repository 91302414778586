import IDomScript from '@/modules/i-dom-script';
import { ModuleClickedEvent } from '@/common/custom-events/module-clicked-event';

export default class ModuleClickTracking implements IDomScript {
    private el: HTMLElement | null;
    private readonly category: string;
    private readonly label: string;
    private readonly programName: string;
    private readonly programId: string;

    public constructor(el: HTMLElement) {
        this.el = el;
        this.category = this.el.getAttribute('data-stat-category') ?? '';
        this.label = this.el.getAttribute('data-stat-label') ?? '';
        this.programName = this.el.getAttribute('data-stat-program-name') ?? '';
        this.programId = this.el.getAttribute('data-stat-program-name') ?? '';
    }

    private clickHandler(): void {
        window.dispatchEvent(new ModuleClickedEvent(this.category, this.label, this.programName, this.programId, '', ''));
    }

    public execute(): void {
        if (!this.el) {
            return;
        }

        this.el.addEventListener('click', this.clickHandler.bind(this), true);
    }

    public dispose(): void {
        if (this.el) {
            this.el.removeEventListener('click', this.clickHandler.bind(this), true);
        }

        this.el = null;
    }
}
