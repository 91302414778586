






    import { Mixins, Component, Prop } from 'vue-property-decorator';

    import UiPlayPauseIcon, { PreferredMode } from '@/styleguide/elements/UiPlayPauseIcon.vue';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import PlayerStateMixin from '@/components/mixins/PlayerStateMixin';
    import { MetadataGetters } from '@/store';

    @Component({
        components: {
            UiPlayPauseIcon
        }
    })
    export default class AudioButton extends Mixins(PlaybackMixin, PlayerStateMixin) {
        @Prop(Number) id!: number;
        @Prop(String) playlistId!: string;
        @Prop(String) title!: string;
        @Prop(String) theme!: string;
        @Prop(String) preferredMode?: PreferredMode;
        @Prop(Boolean) small?: boolean;

        get isCurrentAudio(): boolean {
            if (this.id) {
                return this.$store.getters[MetadataGetters.CurrentAudio]?.id === this.id;
            }

            if (this.playlistId) {
                return this.$store.getters[MetadataGetters.PlaylistId] === this.playlistId;
            }

            return false;
        }

        get ariaLabel(): string {
            return 'Lyssna på ' + this.title;
        }

        get isCurrentAudioPlaying(): boolean {
            return this.isPlaying && this.isCurrentAudio;
        }

        onClick(): void {
            if (this.isCurrentAudioPlaying) {
                this.pause();
            } else {
                this.$emit('click');
            }
        }
    }
