






















    import { Mixins, Component, Prop } from 'vue-property-decorator';
    import Flex from '@/components/shared/Flex.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import Icon from '@/components/shared/Icon.vue';

    import PlayerVolumeMixin from '@/components/mixins/PlayerVolumeMixin';
    import AriaLabelsMixin from '@/components/mixins/AriaLabelsMixin';

    @Component({
        components: {
            Flex,
            UiCaption,
            Icon
        }
    })
    export default class PlayerAudioVolume extends Mixins(PlayerVolumeMixin, AriaLabelsMixin) {
        @Prop(Boolean) wide: boolean | undefined;
        @Prop() private tabIndex?: number;

        onFocus(event: KeyboardEvent) {
            event.stopPropagation();
            event.preventDefault();
        }
    }
