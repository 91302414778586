export default {
    tab: 'Tab',
    enterKey: 'Enter',
    escape: 'Escape',
    leftArrow: 'ArrowLeft',
    upArrow: 'ArrowUp',
    rightArrow: 'ArrowRight',
    downArrow: 'ArrowDown',
    k: 'k',
    numPadPlus: 'Add',
    plus: '+',
    iosOptionPlus: '±',
    numPadMinus: 'Subtract',
    minus: '-',
    iosOptionMinus: '–',
    ctrl: 'Control',
    iosOptionK: 'ª'
};
