






























    import { Vue, Component, Prop } from 'vue-property-decorator';
    import AudioButton from '@/components/shared/AudioButton.vue';
    import UiOverline from '@/styleguide/elements/UiOverline.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import HyphenationPoint from '@/components/shared/ascii/HyphenationPoint.vue';
    import BrandedHeading from '@/components/shared/branding/BrandedHeading.vue';
    import LazyImage from '@/components/shared/LazyImage.vue';
    import { EmitEvents } from '@/enums/EmitEvents';
    import { CardModel } from '@/api/models';

    @Component({
        components: {
            AudioButton,
            UiOverline,
            UiHeading,
            UiCaption,
            HyphenationPoint,
            BrandedHeading,
            LazyImage
        }
    })
    export default class EpisodeCard extends Vue {
        @Prop()
        item!: CardModel;

        get hasAudio(): boolean {
            return !!this.item.audioType;
        }

        onPlayAudio(): void {
            this.$emit(EmitEvents.PlayAudio);
        }

        onLinkClick(): void {
            this.$emit(EmitEvents.LinkClick);
        }
    }
