import IDomScript from '@/modules/i-dom-script';
import { PageChangedEvent } from '@/common/custom-events/page-changed-event';

export default class Vma implements IDomScript {
    private readonly el: HTMLElement;
    private readonly list: HTMLElement | null;
    private readonly showButton: HTMLElement | null;
    private readonly showIcon: HTMLElement | null;
    private clickHandler: EventListener;
    private navigationHandler: EventListener;

    public constructor(el: HTMLElement) {
        this.el = el;
        this.list = this.el.querySelector('#vma-list');
        this.showButton = this.el.querySelector('#vma-show');
        this.showIcon = this.el.querySelector('#vma-icon');

        this.clickHandler = () => {
            if (!this.list) {
                return;
            }

            this.list.classList.toggle('hidden');
            if (this.list.getAttribute('aria-expanded') === 'false') {
                this.list.setAttribute('aria-expanded', 'true');
            } else {
                this.list.setAttribute('aria-expanded', 'false');
            }

            this.showIcon?.classList.toggle('vma__header-icon--active');
        };

        this.navigationHandler = () => {
            this.close();
        };
    }

    public execute(): void {
        this.showButton?.addEventListener('click', this.clickHandler);
        window.addEventListener(PageChangedEvent.EventName, this.navigationHandler);
    }

    private close() {
        this.list?.classList.add('hidden');
        this.showIcon?.classList.remove('vma__header-icon--active');
    }

    public dispose(): void {
        this.showButton?.removeEventListener('click', this.clickHandler);
        window.removeEventListener(PageChangedEvent.EventName, this.navigationHandler);
    }
}
