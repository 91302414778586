







    import { ShareButtonClickedEvent } from '@/common/custom-events/share-button-clicked-event';
    import SvgImage from '@/components/shared/SvgImage.vue';
    import { TrackingMetadata } from '@/tracking/track-types';
    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component({
        components: {
            SvgImage
        }
    })
    export default class ShareButton extends Vue {
        @Prop(String) shareUrl!: string;
        @Prop(Number) broadcastId!: string;
        @Prop(String) broadcastName!: string;

        openShareModal(): void {
            const trackingMetadata: TrackingMetadata = { broadcastId: this.broadcastId, broadcastName: this.broadcastName };
            window.dispatchEvent(new ShareButtonClickedEvent(this.shareUrl, trackingMetadata));
        }
    }
