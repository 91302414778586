






    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class UiPageSection extends Vue {
        @Prop(Boolean) readonly gutter: boolean | undefined;

        get classes(): object {
            return { 'page-section-gutter': this.gutter };
        }
    }
