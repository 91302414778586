








    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import SvgImage from '@/components/shared/SvgImage.vue';
    import AriaLabelsMixin from '@/components/mixins/AriaLabelsMixin';
    import PlayerStateMixin from '@/components/mixins/PlayerStateMixin';

    @Component({
        components: {
            SvgImage
        }
    })
    export default class PlayerCarouselPrevButton extends Mixins(PlaybackMixin, MetadataMixin, AriaLabelsMixin, PlayerStateMixin) {
        @Prop(Number) tabIndex: number | undefined;

        onClick(): void {
            this.playPrevious();
        }
    }
