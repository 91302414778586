






    import { Vue, Component, Prop } from 'vue-property-decorator';
    @Component
    export default class UiProgressbar extends Vue {
        @Prop({ default: 'default' }) readonly theme!: string;
        @Prop(Number) readonly progressPercentage: number | undefined;
        @Prop(Boolean) readonly square: boolean | undefined;

        get progressPercentageWidth(): string {
            return `width:${String(this.progressPercentage)}%;`;
        }
    }
