import { googleAdsTag } from '@/common/google-ads-tag';
import { googleTagManager } from '@/tracking/gtm/google-tag-manager';

function initGoogleAdsTag(): void {
    googleAdsTag.install();
}

function initGoogleTagManager(): void {
    googleTagManager.install();
}

export function initStatisticService(): void {
    initGoogleAdsTag();
    initGoogleTagManager();
}
