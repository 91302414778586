
















    import { Vue, Component, Prop } from 'vue-property-decorator';

    import SearchChannels from '@/components/search/SearchChannels.vue';
    import SearchEpisodes from '@/components/search/SearchEpisodes.vue';
    import SearchPrograms from '@/components/search/SearchPrograms.vue';
    import SearchContent from '@/components/search/SearchContent.vue';
    import Icon from '@/components/shared/Icon.vue';
    import UiContainer from '@/styleguide/elements/UiContainer.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';

    @Component({
        components: {
            SearchChannels,
            SearchEpisodes,
            SearchPrograms,
            SearchContent,
            Icon,
            UiContainer,
            UiHeading
        }
    })
    export default class SearchPage extends Vue {
        @Prop(String) readonly dataQuery!: string;

        showEmptyMessage: boolean = false;

        get query(): string {
            return this.dataQuery;
        }
    }
