import deviceDetection from '@/common/device-detection';
import IDomScript from '@/modules/i-dom-script';
import { getMetadata } from '@/common/tracking-metadata';
import { TrackingMetadata } from '@/tracking/track-types';
import { OpenInAppClickedEvent } from '@/common/custom-events/open-in-app-clicked-event';

export function androidUrl(episodeId: string, parameters?: string): string {
    const url = `play/episode/${episodeId}${parameters ? `?${parameters}` : ''}`;

    const fallbackUrl = encodeURIComponent(`sesrplay://${url}`);
    return `intent://${url}#Intent;package=se.sr.android;scheme=sesrplay;S.browser_fallback_url=${fallbackUrl};end`;
}

export function iosUrl(episodeId: string, parameters?: string): string {
    return `sesrplay://play?json=%7B%22type%22:%22showEpisode%22,%22id%22:${episodeId}%7D${parameters ? `&${parameters}` : ''}`;
}

export default class OpenInPlay implements IDomScript {
    private el: HTMLElement | null;
    private episodeId!: string;
    private readonly metadata: TrackingMetadata = {};

    private readonly clickEventListener: EventListener | undefined;

    private readonly installAppleAppStore: string = 'https://apps.apple.com/se/app/sveriges-radio-play/id300548244';
    private readonly installAndroidPlayStore: string =
        'https://play.google.com/store/apps/details?id=se.sr.android#?t=w251bgwsmswxldixmiwic2uuc3iuyw5kcm9pzcjd';

    public constructor(el: HTMLElement) {
        this.el = el;

        if (!this.el.dataset.episodeId) {
            return;
        }

        this.metadata = getMetadata(this.el);
        this.episodeId = this.el.dataset.episodeId;

        this.clickEventListener = (event: Event): void => {
            event.preventDefault();

            window.dispatchEvent(new OpenInAppClickedEvent(this.metadata));

            if (deviceDetection.isAndroid) {
                window.location.replace(androidUrl(this.episodeId));
            } else if (deviceDetection.isIos) {
                let didBlur = false;

                setTimeout(() => {
                    if (!didBlur) {
                        window.location.replace(this.installAppleAppStore);
                    }
                }, 2000);

                window.location.replace(iosUrl(this.episodeId));

                window.onblur = function (): void {
                    didBlur = true;
                };

                window.onfocus = function (): void {
                    didBlur = false;
                };
            } else {
                window.location.replace(this.installAndroidPlayStore);
            }
        };
    }

    private show(): void {
        if (this.el) {
            this.el.classList.remove('hidden');
        }
    }

    private tryAutoRedirectToApp(): void {
        const isUserComingFromInstagramReferral = document.referrer.includes('instagram');

        if (!this.episodeId || !isUserComingFromInstagramReferral) {
            return;
        }

        const params = new URL(document.location.toString()).searchParams;
        params.has('utm_source') || params.append('utm_source', 'instagram');
        params.has('utm_medium') || params.append('utm_medium', 'social');

        if (deviceDetection.isAndroid) {
            window.location.replace(androidUrl(this.episodeId, params.toString()));
            this.showDownloadAppPopup(this.installAndroidPlayStore);
        } else if (deviceDetection.isIos) {
            window.location.replace(iosUrl(this.episodeId, params.toString()));
            this.showDownloadAppPopup(this.installAppleAppStore);
        }
    }

    private showDownloadAppPopup(storeUrl: string): void {
        const wrapper = document.createElement('div');
        wrapper.setAttribute('id', 'sharing-instagram-wrapper');
        wrapper.style.cssText =
            'box-sizing: border-box;z-index:10000;padding:2rem;top:0;position:absolute;width:100%;height:100vh;background-color:#000;overflow:hidden;display:flex;flex-direction:column;align-items:center;justify-content:center;color:#fff;text-align:center;';

        const topLogoBarContainer = document.createElement('div');
        topLogoBarContainer.innerHTML =
            '<svg style="width:60%" aria-hidden="true" focusable="false"><use xlink:href="/dist/svg-sprite-f10ce60f61b3cc095f2e.svg#sprite-sverigesradio" class="inherit"></use></svg>';
        topLogoBarContainer.style.cssText = 'max-height:5rem';
        wrapper.appendChild(topLogoBarContainer);

        const episodePictureSource = document.querySelector('.episode-details picture source');
        if (!episodePictureSource) {
            return;
        }

        const currentEpisodeImageUrl = episodePictureSource.getAttribute('srcset');
        if (!currentEpisodeImageUrl) {
            return;
        }

        const currentEpisodeImage = document.createElement('img');
        currentEpisodeImage.setAttribute('src', currentEpisodeImageUrl);
        currentEpisodeImage.style.cssText = 'width:100%;';
        const episodeImageContainer = document.createElement('div');
        episodeImageContainer.style.cssText = 'padding:0rem;';
        episodeImageContainer.appendChild(currentEpisodeImage);
        wrapper.appendChild(episodeImageContainer);

        const textContainer = document.createElement('div');

        textContainer.innerHTML = `<h1 class=\"heading\">Lyssna i appen</h1><p class=\"publication-text text-editor-content\">Följ dina favoriter, spara i din lista eller ladda ner till senare.</p></div><div><div><a id="popup-download-app" href="${storeUrl}" style=\"margin-top:2rem; border-radius: 2rem;display:inline-block; background-color: #fff;padding:1rem 4rem; color:#000;\">Ladda ner appen</a></div><a href=\"#\" id="popup-remove" class=\"sharing-instagram-button\" style=\"margin-top:1rem; border: 1px solid #fff; border-radius: 2rem;display:inline-block;padding:1rem 4rem; margin-top:1rem;color:#fff\">Fortsätt på webben</a></div>`;
        textContainer.style.cssText = 'margin:1rem 0 2rem 0;';

        textContainer.querySelector('.sharing-instagram-button')?.addEventListener('click', function (e) {
            e.preventDefault();
            wrapper.remove();
        });

        wrapper.appendChild(textContainer);

        document.body.appendChild(wrapper);

        setTimeout(() => {
            wrapper.remove();
        }, 20000);
    }

    public execute(): void {
        if (!this.el || !deviceDetection.isSmartphoneOrTablet || !this.clickEventListener) {
            return;
        }

        this.tryAutoRedirectToApp();
        this.show();
        this.el.addEventListener('click', this.clickEventListener);
    }

    public dispose(): void {
        if (!this.el) {
            return;
        }

        if (this.clickEventListener) {
            this.el.removeEventListener('click', this.clickEventListener);
        }
        this.el = null;
    }
}
