























    import Vue from 'vue';
    import '@sverigesradio/stil/components/stil-spacer.js';
    import '@sverigesradio/stil/components/stil-grid.js';
    import '@sverigesradio/stil/components/stil-text.js';
    import { FirstCharacterGrouping, programsApi } from '@/api/programs-api';
    import CharacterNavigation from './CharacterNavigation.vue';
    import ProgramListItem from './ProgramListItem.vue';
    import SectionHeader from '@/components/shared/SectionHeader.vue';

    export default Vue.extend({
        components: {
            CharacterNavigation,
            ProgramListItem,
            SectionHeader
        },

        created() {
            this.getAlphabeticalPrograms();
        },

        computed: {
            showCategoryPage(): boolean {
                return this.programGroups?.length > 0;
            },
            characterList(): string[] {
                return this.programGroups.map(group => group.firstCharacter);
            },
            sectionHeaderTitle(): string {
                return `${this.pageTitle} A-Ö`;
            }
        },

        methods: {
            async getAlphabeticalPrograms() {
                const programResponse = await programsApi.getProgramByCategory(this.$props.categoryId);
                this.pageTitle = programResponse.data.categoryName;
                this.programGroups = programResponse.data.firstCharacterGroupings;
            },
            createSectionId(firstCharacter: string) {
                return `character${firstCharacter}`;
            }
        },

        data() {
            return {
                pageTitle: '',
                programGroups: [] as FirstCharacterGrouping[]
            };
        },
        props: {
            categoryId: {
                type: Number,
                required: true
            }
        }
    });
