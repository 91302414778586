








    import '@sverigesradio/stil/components/stil-text.js';
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            title: {
                type: String,
                required: true
            }
        }
    });
