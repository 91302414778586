




    import { Vue, Component, Prop } from 'vue-property-decorator';
    import anime from 'animejs';

    @Component
    export default class Skeleton extends Vue {
        @Prop(Number) readonly width!: number;
        @Prop(Number) readonly height!: number;

        private delay = 1;
        private duration = 500;
        private loopDuration = 1000;
        private stagger = this.delay + this.duration;

        private get css(): string {
            return `width: ${this.width}px; height: ${this.height}px`;
        }

        mounted() {
            anime({
                targets: this.$el,
                opacity: [0, 1],
                duration: this.duration,
                delay: this.delay,
                easing: 'linear'
            });

            anime({
                targets: this.$el,
                backgroundColor: ['#f3f3f3', '#ebebeb'],
                duration: this.loopDuration,
                delay: this.stagger,
                easing: 'linear',
                direction: 'alternate',
                loop: true
            });
        }
    }
