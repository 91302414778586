
























    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { StoryModel } from '@/api/story-collection-api';
    import LazyImage from '@/components/shared/LazyImage.vue';
    import UiOverline from '@/styleguide/elements/UiOverline.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import UiPlayPauseIcon from '@/styleguide/elements/UiPlayPauseIcon.vue';
    import AudioButton from '@/components/shared/AudioButton.vue';
    import { EmitEvents } from '@/enums/EmitEvents';

    @Component({
        components: {
            LazyImage,
            UiOverline,
            UiHeading,
            UiCaption,
            UiPlayPauseIcon,
            AudioButton
        }
    })
    export default class StoryCollectionCard extends Vue {
        @Prop() private readonly item!: StoryModel;

        private get storyUrl(): string {
            return this.item.url;
        }

        private get imageUrl(): string {
            return this.item.image.url;
        }

        private get overline(): string {
            return this.item.header;
        }

        private get heading(): string {
            return this.item.title;
        }

        private get itemCount(): string {
            return this.item.itemCount.toString();
        }

        private get duration(): string {
            const minutes: number = Math.floor(this.item.totalSeconds / 60);
            const seconds: number = this.item.totalSeconds % 60;
            return `${minutes} min ${seconds} sek`;
        }

        private onClick(): void {
            this.$emit('onClick', this.item);
        }

        private onLinkClick(): void {
            this.$emit(EmitEvents.LinkClick, this.item);
        }
    }
