import { render, staticRenderFns } from "./StoryCollection.vue?vue&type=template&id=611e2455&scoped=true&"
import script from "./StoryCollection.vue?vue&type=script&lang=ts&"
export * from "./StoryCollection.vue?vue&type=script&lang=ts&"
import style0 from "./StoryCollection.vue?vue&type=style&index=0&id=611e2455&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "611e2455",
  null
  
)

export default component.exports