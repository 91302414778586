







    import { Vue, Component, Prop } from 'vue-property-decorator';
    import Icon from '@/components/shared/Icon.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';

    @Component({
        components: {
            Icon,
            UiCaption
        }
    })
    export default class MainMenuShowMore extends Vue {
        @Prop({ type: Boolean, required: true }) isMenuActive: boolean | undefined;
        @Prop({ type: Boolean, required: true }) isSearchActive: boolean | undefined;

        get isMenuOpen() {
            return this.isMenuActive && !this.isSearchActive;
        }

        onClick() {
            this.$emit('showMore');
        }
    }
