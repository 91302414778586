






















































    import { Vue, Component } from 'vue-property-decorator';
    import UiButton from '@/styleguide/elements/UiButton.vue';
    import UiHeading from '@/styleguide/elements/UiHeading.vue';
    import UiBodyText from '@/styleguide/elements/UiBodyText.vue';
    import UiLinkInternal from '@/styleguide/elements/UiLinkInternal.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import { consentService } from '@/common/consent-service';
    import { ShowCookieConsentEvent } from '@/common/custom-events/show-cookie-consent-event';
    import storage from '@/common/storage';
    import { initStatisticService } from '@/tracking/statistic-service';
    import { CookieConsentUpdatedEvent } from '@/common/custom-events/cookie-consent-updated-event';
    import { cookieConsentUpdatedTracker } from '@/common/cookie-consent-updated-tracker';

    @Component({
        components: {
            UiButton,
            UiHeading,
            UiBodyText,
            UiLinkInternal,
            UiCaption
        }
    })
    export default class CookiePopover extends Vue {
        private consentSaved: boolean = false;

        accept(): void {
            this.$store.dispatch('acceptCookie');
        }

        get isPopoverVisible(): boolean {
            if (this.hasConsentHash) {
                return false;
            }

            if (this.isCookiesDisabled) {
                return false;
            }

            return !this.consentSaved;
        }

        get isCookiesDisabled(): boolean {
            return !storage.isLocalStorageAvailable;
        }

        get hasConsentHash(): boolean {
            return location.hash === '#cookieconsent';
        }

        mounted(): void {
            this.consentSaved = consentService.isConsentSaved();
            window.addEventListener(CookieConsentUpdatedEvent.EventName, this.onConsentSavedUpdated);
        }

        destroyed(): void {
            window.removeEventListener(CookieConsentUpdatedEvent.EventName, this.onConsentSavedUpdated);
        }

        showConsentDialog(): void {
            window.dispatchEvent(new ShowCookieConsentEvent());
        }

        onConsentSavedUpdated(event: CookieConsentUpdatedEvent): void {
            this.consentSaved = event.detail.currentCookieSettings.consentSaved;
        }

        private acceptMandatory(): void {
            consentService.acceptMandatory();
        }

        private acceptAll(): void {
            consentService.acceptAll();
            initStatisticService();
            cookieConsentUpdatedTracker.sendPageTrack();
        }
    }
