var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
        { inverted: _vm.inverted },
        _vm.type,
        { 'full-width': _vm.fullWidth },
        { caps: _vm.caps },
        'button',
        { alignTextCenter: _vm.alignTextCenter }
    ],on:{"click":_vm.handleClick}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }