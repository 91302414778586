import IDomScript from '@/modules/i-dom-script';

export default class ScrollToTop implements IDomScript {
    private el: HTMLElement;

    constructor(el: HTMLElement) {
        this.el = el;
    }

    private clickHandler(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        window.scrollTo(0, 0);

        const focusTarget = document.getElementById('to-top-target');
        if (!focusTarget) {
            return;
        }

        focusTarget.focus();
    }

    public execute(): void {
        this.el.addEventListener('click', this.clickHandler.bind(this));
    }

    public dispose(): void {
        this.el.removeEventListener('click', this.clickHandler.bind(this));
    }
}
